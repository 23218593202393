import React from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import {LanguageCode, supportedLanguagesMap} from "../../lib/intl";


const LanguagesTabs = ({
  selectedLang,
  onLangChange,
}: {
  selectedLang: string;
  onLangChange: (lang: string) => void;
}) => {

  return (
    <Stack direction="row" spacing={1}>
      {Object.keys(supportedLanguagesMap).map((lang) => (
        <Button
          variant="text"
          key={lang}
          onClick={() => {
            onLangChange(lang);
          }}>
          <Box pr={1} py={1}>
            <Typography
              variant="body1"
              color={
                lang === selectedLang
                  ? 'primary'
                  : 'secondary'
              }>
              {supportedLanguagesMap[lang as LanguageCode]}
            </Typography>
          </Box>
        </Button>
      ))}
    </Stack>
  );
};

export default LanguagesTabs;
