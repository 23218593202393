import React from 'react';

import {BoxProps} from '@mui/material';

import BLANK_IMAGE_RECT from '../../../assets/images/blankimage_rectangular.png';
import BLANK_IMAGE_SQUARE from '../../../assets/images/blankimage_square.png';

import getFbImageDownloadUrl from '../helpers/get-fb-image-download-url';
import getFbImageRealRef from '../helpers/get-fb-image-real-ref';

const RegularImage = (props: React.ComponentProps<'img'>) => <img {...props} />;

const FirebaseStorageImage = ({
  reference,
  Component = RegularImage,
  ...props
}: Omit<React.ComponentProps<'img'>, 'src'> & {
  ratio?: AspectRatio;
  reference: string;
  Component?: any;
  sx?: BoxProps['sx'];
}) => {
  const [downloadURL, setDownloadURL] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    let realRef = getFbImageRealRef(reference);

    const findURL = async () => {
      try {
        const result = await getFbImageDownloadUrl(realRef, 5);

        setDownloadURL(result);
      } catch (err) {
        setError(true);
      }
    };

    findURL();
  }, [reference]);

  if (downloadURL) {
    return <Component {...props} src={downloadURL} />;
  }

  if (error) {
    const fallbackImage =
      props.ratio === '1:1' ? BLANK_IMAGE_SQUARE : BLANK_IMAGE_RECT;

    return <Component {...props} src={fallbackImage} />;
  }

  return null;
};

export default FirebaseStorageImage;
