import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import firebase from './firebase';

const AuthRoute = () => {
  const [authenticated, setAuthenticated] = React.useState<boolean>();
  const navigate = useNavigate();

  React.useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        navigate('/signin');
      } else {
        setAuthenticated(true);
      }
    });

    return () => unsub();
  }, []);

  if (authenticated) {
    return <Outlet />;
  }

  return null;
};

export default AuthRoute;
