import {Route, Routes} from 'react-router-dom';

import {Box, useTheme} from '@mui/material';

import AuthRoute from './lib/app/AuthRoute';
import PubRoute from './lib/app/PubRoute';

import PageActionContainer from './components/organisms/PageActionContainer';
import UserPageContainer from './components/organisms/UserPageContainer';

import AssignToHeadManager from './pages/AssignToHeadManager/AssignToHeadManager';
import AssignToManager from './pages/AssignToManager/AssignToManager';
import AssignToSales from './pages/AssignToSales/AssignToSales';
import CategoriesList from './pages/BusinessCategories/CategoriesList';
import CategoryForm from './pages/BusinessCategories/CategoryForm';
import BusinessDashboard from './pages/BusinessDashboard/BusinessDashboard';
import BusinessesList from './pages/BusinessesList/BusinessesList';
import BusinessReferralsList from './pages/BusinessReferralsList/BusinessReferralsList';
import CampaignForm from './pages/Campaigns/CampaignForm';
import CampaignsList from './pages/Campaigns/CampaignsList';
import CreateSuperUser from './pages/CreateSuperUser/CreateSuperUser';
import ExportContacts from './pages/Data/ExportContacts';
import EditPassword from './pages/EditPassword/EditPassword';
import EditProfile from './pages/EditProfile/EditProfile';
import GroupDetails from './pages/GroupDetails/GroupDetails';
import GroupsList from './pages/GroupsList/GroupsList';
import Logout from './pages/Logout/Logout';
import PartnershipRequests from './pages/PartnershipsRequests/PartnershipRequests';
import RunScript from './pages/RunScript/RunScript';
import Settings from './pages/Settings/Settings';
import Signin from './pages/Signin/Signin';
import SuperuserDashboard from './pages/SuperuserDashboard/SuperuserDashboard';
import SuperUsersList from './pages/SuperUsersList/SuperUsersList';
import UpdateSuperUser from './pages/UpdateSuperUser/UpdateSuperUser';
import UpdateSuperUserPassword from './pages/UpdateSuperuserPassword/UpdateSuperUserPassword';

import CreateBusiness from './modals/CreateBusiness/CreateBusiness';
import CreateBusinessB2BWeb from './modals/CreateBusiness/CreateBusinessB2BWeb';
import CreateBusinessIntro from './modals/CreateBusiness/CreateBusinessIntro';
import CreateBusinessPending from './modals/CreateBusiness/CreateBusinessPending';
import CreateBusinessReference from './modals/CreateBusiness/CreateDisplayBusiness';
import UpdateBusiness from './modals/UpdateBusiness/UpdateBusiness';
import UpdateBusinessImage from './modals/UpdateBusiness/UpdateBusinessImage';
import UpdateDisplayOrB2BWebBusiness from './modals/UpdateBusiness/UpdateDisplayOrB2BWebBusiness';
import UpdatePendingBusiness from './modals/UpdateBusiness/UpdatePendingBusiness';
import UpdateBusinessContact from './modals/UpdateBusinessContact/UpdateBusinessContact';
import TransferBusinessAccount from './pages/ConvertDisplayBusiness/TransferBusinessAccount';
import TransferDisplayOnlyBusiness from "./pages/ConvertDisplayBusiness/TransferDisplayOnlyBusiness";
import Preferences from './pages/Preferences/Preferences';

const AppRoutes = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingBottom: 10,
        position: 'relative',
      }}>
      <Routes>
        <Route path="signin" element={<PubRoute />}>
          <Route path="" element={<Signin />} />
        </Route>
        <Route path="" element={<AuthRoute />}>
          <Route path="actions" element={<PageActionContainer />}>
            <Route path="group-details/:id" element={<GroupDetails />} />
            <Route path="superusers/create" element={<CreateSuperUser />} />
            <Route path="run-script" element={<RunScript />} />
            <Route path="superusers/update/:id" element={<UpdateSuperUser />} />
            <Route
              path="superusers/update-password/:id"
              element={<UpdateSuperUserPassword />}
            />
            <Route
              path="businesses/create-intro"
              element={<CreateBusinessIntro />}
            />
            <Route path="businesses/create" element={<CreateBusiness />} />
            <Route
              path="businesses/create-reference"
              element={<CreateBusinessReference />}
            />
            <Route
              path="businesses/create-b2b-web"
              element={<CreateBusinessB2BWeb />}
            />
            <Route
              path="businesses/create-pending"
              element={<CreateBusinessPending />}
            />
            <Route
              path="businesses/update/:id/active"
              element={<UpdateBusiness />}
            />
            <Route
              path="businesses/update/:id/contact"
              element={<UpdateBusinessContact />}
            />
            <Route
              path="businesses/transfer-account/:id"
              element={<TransferBusinessAccount />}
            />
            <Route
              path="businesses/transfer-display-only-business/:id"
              element={<TransferDisplayOnlyBusiness />}
            />
            <Route
              path="businesses/update/:id/reference"
              element={<UpdateDisplayOrB2BWebBusiness />}
            />
            <Route
              path="businesses/update/:id/pending"
              element={<UpdatePendingBusiness />}
            />
            <Route
              path="businesses/update/:id/image"
              element={<UpdateBusinessImage />}
            />
            <Route path="profile/edit" element={<EditProfile />} />
            <Route path="password/edit" element={<EditPassword />} />
            <Route
              path="business/:id/assign-sales"
              element={<AssignToSales />}
            />
            <Route
              path="superusers/:id/assign-manager"
              element={<AssignToManager />}
            />
            <Route
              path="superusers/:id/assign-head-manager"
              element={<AssignToHeadManager />}
            />
            <Route
              path="business-categories/create"
              element={<CategoryForm />}
            />
            <Route
              path="business-categories/edit/:id"
              element={<CategoryForm />}
            />

            <Route path="campaigns/create" element={<CampaignForm />} />
            <Route path="campaigns/edit/:id" element={<CampaignForm />} />

            <Route path="preferences" element={<Preferences />} />
          </Route>
          <Route path="" element={<UserPageContainer />}>
            <Route path="superusers" element={<SuperUsersList />} />
            <Route
              path="business-referrals"
              element={<BusinessReferralsList />}
            />
            <Route path="businesses" element={<BusinessesList />} />
            <Route path="groups" element={<GroupsList />} />
            <Route path="settings" element={<Settings />} />
            <Route
              path="business/:id/dashboard"
              element={<BusinessDashboard />}
            />
            <Route
              path="superusers/:id/dashboard"
              element={<SuperuserDashboard />}
            />
            <Route
              path="business/:id/partnership-requests"
              element={<PartnershipRequests />}
            />
            <Route path="business-categories" element={<CategoriesList />} />
            <Route path="export-contacts" element={<ExportContacts />} />

            <Route path="campaigns" element={<CampaignsList />} />

            <Route index element={<BusinessesList />} />
          </Route>
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default AppRoutes;
