import {Close} from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import {Formik} from 'formik';
import FormikRadio from '../../components/atoms/formik/FormikRadio';
import FormikSelect from '../../components/atoms/formik/FormikSelect';
import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import OwnersPicker from '../../components/molecules/OwnersPicker';
import {Filter} from './types';

const BusinessFilterDialog = ({
  search,
  formState,
  onSubmit,
  onClose,
  visible,
}: {
  search: string;
  formState: Filter;
  onSubmit: (filter: Filter) => void;
  onClose: () => void;
  visible: boolean;
}) => {
  return (
    <Dialog fullScreen open={visible} onClose={() => onClose()}>
      <Stack py={1}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
          <Typography variant="h5">Filtres</Typography>
        </Stack>
        <Stack direction="column" spacing={4} p={2}>
          <Formik initialValues={formState} onSubmit={onSubmit}>
            {({handleSubmit}) => (
              <>
                <FormikSelect name="filter" label="Filtre">
                  <MenuItem value="ALL">Tout</MenuItem>
                  <MenuItem value="USER_ONLY">Mes commerces seulement</MenuItem>
                </FormikSelect>
                <Stack spacing={1}>
                  <Typography
                    defaultValue={search}
                    sx={{pr: 2}}
                    variant="body1">
                    Tri (Ordre / Champ)
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <FormGroup row>
                      <FormControlLabel
                        control={<FormikRadio name="sortOrder" value={1} />}
                        label="Croissant"
                      />
                      <FormControlLabel
                        control={<FormikRadio name="sortOrder" value={-1} />}
                        label="Décroissant"
                      />
                    </FormGroup>
                  </Stack>
                  <FormikSelect name="sortField">
                    <MenuItem value="name">Nom</MenuItem>
                    <MenuItem value="address">Adresse</MenuItem>
                    <MenuItem value="createdAt">Date de creation</MenuItem>
                  </FormikSelect>
                </Stack>
                <Stack spacing={1}>
                  <Typography
                    defaultValue={search}
                    sx={{pr: 2}}
                    variant="body1">
                    Status
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={<FormikRadio name="status" value="ALL" />}
                      label="Tout"
                    />
                    <FormControlLabel
                      control={<FormikRadio name="status" value="ACTIVE" />}
                      label="Actif"
                    />
                    <FormControlLabel
                      control={<FormikRadio name="status" value="PENDING" />}
                      label="Inactif"
                    />
                    <FormControlLabel
                      control={<FormikRadio name="status" value="REFERENCE" />}
                      label="Afficheur"
                    />
                    <FormControlLabel
                      control={<FormikRadio name="status" value="B2B_WEB" />}
                      label="B2B Web"
                    />
                  </FormGroup>
                </Stack>
                <FormControlLabel
                  control={<FormikSingleChoice name="withSections" />}
                  label="Avec sections ?"
                />
                <FormControlLabel
                  control={<FormikSingleChoice name="siteTemplatesOnly" />}
                  label="Modèles de site seulement ?"
                />
                <OwnersPicker name="owner" label="Filtre par propriétaire" />
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  size="medium">
                  Sauvegarder
                </Button>
              </>
            )}
          </Formik>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default BusinessFilterDialog;
