import React from 'react';

import {useDebounce} from 'use-debounce';

import EmptyList from '../../components/molecules/EmptyList';
import PageList from '../../components/organisms/PageList';

import {useGetBusinessCategoriesQuery} from '../../redux/businessCategoriesApi';

import CategoriesCard from './CategoriesCard';
import CategoriesListHeader from './CategoriesListHeader';

const CategoriesList = () => {
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = React.useState({field: 'createdAt', order: -1});
  const [debouncedSearch] = useDebounce(search, 500);

  const [queryParams, setQueryParams] = React.useState({});

  return (
    <PageList
      addButtonVisible
      addLink="/actions/business-categories/create"
      pageTitle="Categories de commerce"
      useQuery={useGetBusinessCategoriesQuery}
      emptyList={
        <EmptyList message="Aucune categorie ne correspond à votre recherche" />
      }
      header={
        <CategoriesListHeader
          search={search}
          onSearchChange={setSearch}
          onQueryParamsChange={setQueryParams}
          sort={sort}
          onSortChange={setSort}
        />
      }
      extraQueryParams={{
        ...queryParams,
        search: debouncedSearch,
      }}
      renderItem={(category: any) => (
        <CategoriesCard key={category._id} category={category} />
      )}
    />
  );
};

export default CategoriesList;
