import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import SaveButton from '../../components/atoms/SaveButton';
import SalesPicker from '../../components/molecules/SalesPicker';
import PageAction from '../../components/organisms/PageAction';

import {
  useGetBusinessByIdQuery,
  useUpdateBusinessAssigneeMutation,
} from '../../redux/businessesApi';

const validationSchema = yup.object({
  assignee: yup.string().required('Champs requis'),
});

const AssignToSales = () => {
  const params = useParams<{id: string}>();
  const businessQuery = useGetBusinessByIdQuery(params.id as string, {
    skip: !params.id,
  });
  const [updateBusiness, mutation] = useUpdateBusinessAssigneeMutation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      navigate(-1);
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.isSuccess, mutation.isError, mutation.error, navigate]);

  return (
    <PageAction title="Affectation à un agent commercial">
      <Formik<{
        assignee: string;
      }>
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          assignee: (businessQuery.data?.assignee as SuperUser)?._id || '',
        }}
        onSubmit={({assignee}) =>
          updateBusiness({
            assignee,
            id: params.id as string,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <SalesPicker
              name="assignee"
              label="Choisissez l'agent commercial"
            />
            <SaveButton
              loading={businessQuery.isLoading || mutation.isLoading}
              onClick={() => handleSubmit()}
            />
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default AssignToSales;
