import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authBaseQuery,
  tagTypes: ['AuthSuperuser'],
  endpoints(build) {
    return {
      getAuthUser: build.query<SuperUser, void>({
        query: () => ({
          url: '/getAuthSuperuser',
          method: 'get',
        }),
        providesTags: result =>
          result
            ? [
                {type: 'AuthSuperuser', id: result._id},
                {type: 'AuthSuperuser', id: 'LIST'},
              ]
            : [{type: 'AuthSuperuser', id: 'LIST'}],
      }),
      updateProfile: build.mutation<
        SuperUser,
        Pick<SuperUser, 'firstName' | 'lastName'>
      >({
        query: ({firstName, lastName}) => ({
          url: '/updateAuthSuperuserProfile',
          method: 'put',
          data: {
            firstName,
            lastName,
          },
        }),
        invalidatesTags: result =>
          result
            ? [
                {type: 'AuthSuperuser', id: result._id},
                {type: 'AuthSuperuser', id: 'LIST'},
              ]
            : [{type: 'AuthSuperuser', id: 'LIST'}],
      }),
      updatePassword: build.mutation<SuperUser, {password: string}>({
        query: ({password}) => ({
          url: '/updateAuthSuperuserPassword',
          method: 'patch',
          data: {
            password,
          },
        }),
      }),
    };
  },
});

export const {useGetAuthUserQuery, useUpdateProfileMutation} = authApi;

export default authApi;
