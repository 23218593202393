import React from 'react';

import {Check, Close} from '@mui/icons-material';
import {Chip, Paper, Stack, Typography} from '@mui/material';

import renderAdminRole from '../../lib/helpers/getAdminRole';
import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import SuperuserActions from '../../modals/SuperuserActions/SuperuserActions';

const SuperuserCard: React.FC<{
  superuser: SuperUser;
}> = ({superuser}) => {
  const [open, setOpen] = React.useState(false);
  const userIsRoot = useUserIsRoot();

  const superuserParent = superuser.parent as SuperUser;
  return (
    <>
      <Paper square sx={{py: 4, px: 2}} onClick={() => setOpen(true)}>
        <Stack spacing={2} direction="column" alignItems="flex-start">
          <Typography variant="h6">
            {superuser.firstName} {superuser.lastName}
          </Typography>
          <Typography variant="body1">{superuser.email}</Typography>
          <Chip color="primary" label={renderAdminRole(superuser.role)} />
          <Typography variant="body1">
            Code: <b>{superuser.referralCode.toUpperCase()}</b>
          </Typography>
          {superuserParent &&
            (userIsRoot || superuserParent.role !== 'ROOT') && (
              <Typography variant="body1">
                Géré par: <b>{superuserParent.firstName}</b>{' '}
                <b>{superuserParent.lastName}</b>
              </Typography>
            )}
          {superuser.disabled && (
            <Stack alignItems="center" spacing={1} direction="row">
              <Typography>Désactivé</Typography>
              <Close color="error" />
            </Stack>
          )}
          {!superuser.disabled && (
            <Stack alignItems="center" spacing={1} direction="row">
              <Typography>Activé</Typography>
              <Check color="success" />
            </Stack>
          )}
        </Stack>
      </Paper>
      <SuperuserActions
        superuser={superuser}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default SuperuserCard;
