import React from 'react';

import {useDebounce} from 'use-debounce';

import EmptyList from '../../components/molecules/EmptyList';
import PageList from '../../components/organisms/PageList';

import {useGetBusinessReferralsQuery} from '../../redux/businessReferralsApi';

import BusinessReferralCard from './BusinessReferralCard';
import BusinessReferralsListHeader from './BusinessReferralsListHeader';

const BusinessReferralsList = () => {
  const [search, setSearch] = React.useState('');
  const [sort, setSort] = React.useState({field: 'createdAt', order: -1});
  const [debouncedSearch] = useDebounce(search, 500);
  const [queryParams, setQueryParams] = React.useState({});

  return (
    <PageList<BusinessReferral>
      useQuery={useGetBusinessReferralsQuery}
      renderItem={businessReferral => (
        <BusinessReferralCard businessReferral={businessReferral} />
      )}
      pageTitle="Commerces invités"
      emptyList={<EmptyList message="Aucun partenaire invité trouvé" />}
      header={
        <BusinessReferralsListHeader
          search={search}
          onSearchChange={setSearch}
          onQueryParamsChange={setQueryParams}
          sort={sort}
          onSortChange={setSort}
        />
      }
      extraQueryParams={{
        ...queryParams,
        search: debouncedSearch,
      }}
    />
  );
};

export default BusinessReferralsList;
