import {GroupListResponseObject} from '../../types/custom';
import AspectRatioImage from '../atoms/AspectRatioImage';

import {ButtonBase, Chip, Paper, Stack, Typography} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';

import format from 'date-fns/format';

const GroupCard = ({
  onClick,
  group,
}: {
  group: GroupListResponseObject;
  onClick?: () => void;
}) => {
  return (
    <Paper variant="outlined">
      <ButtonBase
        sx={{
          width: '100%',
        }}
        onClick={onClick}>
        <Stack
          sx={{
            position: 'relative',
            flex: 1,
            p: 1,
            mb: 1,
          }}
          direction="column"
          alignItems="flex-start"
          spacing={1}
          pb={1}>
          <FirebaseStorageImage
            Component={AspectRatioImage}
            reference={group.image.reference}
            ratio="1:1"
          />
          <Typography align="left" variant="h5">
            {group.name}
          </Typography>
          <Typography align="left" variant="body1" color="grey.700">
            {group.admin.name}
          </Typography>
          {group.status === 'ACTIVE' && (
            <>
              <Chip size="small" label="Activé" color="success" />
              <Typography variant="body2" color="grey.700">
                {group.membersCount} membres
              </Typography>
              <Typography variant="body2" color="grey.700" align="left">
                Activé par {group.activatedBy?.firstName || 'défault'}{' '}
                {group.activatedBy?.lastName} le{' '}
                {format(new Date(group.createdAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
            </>
          )}
          {group.status === 'PENDING' && (
            <Chip size="small" label="Non activé" color="warning" />
          )}
          {group.status === 'REFUSED' && (
            <Chip size="small" label="Refusé" color="error" />
          )}
        </Stack>
      </ButtonBase>
    </Paper>
  );
};

export default GroupCard;
