import React from 'react';
import {NotificationManager} from 'react-notifications';

import {internalError} from '../../lib/helpers/internalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useReactivateBusinessMutation} from '../../redux/businessesApi';

const ReactivateBusiness = ({
  business,
  open,
  onClose,
}: {
  business: Business;
  open: boolean;
  onClose: () => void;
}) => {
  const [reactivateBusiness, mutation] = useReactivateBusinessMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Commerce réactivé avec succès');
      onClose();
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.isError, mutation.error, mutation.isSuccess]);

  return (
    <Confirmation
      isLoading={mutation.isLoading}
      description="Êtes-vous sûr vouloir réactiver ce commerce et tous les comptes liées?"
      open={open}
      onClose={() => onClose()}
      onConfirm={() => reactivateBusiness(business._id)}
    />
  );
};

export default ReactivateBusiness;
