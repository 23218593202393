import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {apiBaseQuery} from './apiBaseQuery';

const preferencesApi = createApi({
  reducerPath: 'preferencesApi',
  baseQuery: apiBaseQuery,
  tagTypes: ['Preference'],
  endpoints(build) {
    return {
      getPreferences: build.query({
        query: ({
          $limit = 1,
          $skip = 0,
          sortField = 'createdAt',
          sortOrder = -1,
          params,
        }) => {
          let url = `/preferences?$limit=${$limit}&$skip=${$skip}&$sort[${sortField}]=${sortOrder}`;
          return {
            url,
            method: 'get',
            params: {...params}
          };
        },
        providesTags: ['Preference'],
      }),
      getPreferenceById: build.query({
        query: ({id, params}) => ({url: `/preferences/${id}`, method: 'get', params}),
        providesTags: ['Preference'],
      }),
      createPreference: build.mutation({
        query: data => ({
          url: `/preferences`,
          method: 'post',
          data,
        }),
        invalidatesTags: ['Preference'],
      }),
      updatePreference: build.mutation({
        query: ({id, data}) => ({
          url: `/preferences/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: ['Preference'],
      }),
      deletePreference: build.mutation({
        query: ({id}) => ({
          url: `/preferences/${id}`,
          method: 'delete',
        }),
        invalidatesTags: ['Preference'],
      }),
    };
  },
});

export const {
  useGetPreferenceByIdQuery,
  useGetPreferencesQuery,
  useCreatePreferenceMutation,
  useUpdatePreferenceMutation,
  useDeletePreferenceMutation,
} = preferencesApi;

export default preferencesApi;
