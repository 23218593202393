import {FormControlLabel, Radio, RadioGroup} from '@mui/material';

import FirebaseStorageImage from '../../../lib/firebase-image/components/FirebaseStorageImage';

import * as yup from 'yup';

import AspectRatioImage from '../../../components/atoms/AspectRatioImage';
import FormikFileInput from '../../../components/atoms/formik/FormikFileInput';
import FormikTextField from '../../../components/molecules/formik/FormikTextField';

export const stepThreeSchema = {
  adName: yup.string().required("Le nom de l'ad est obligatoire."),
  primaryText: yup.string(), // .required('Champ obligatoire.'),
  headline: yup.string(), // .required('Champ obligatoire.'),
  description: yup.string(), // .required('Champ obligatoire.'),
  websiteURL: yup
    .string()
    .matches(
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
      'Entrez la bonne URL!',
    ),
  media: yup.string(), // .test("media", "Champ obligatoire.", (_value: any, context) => {return _value ? true : !!context.parent.id;}),
  imageAspectRatio: yup.string(),
};

const StepThree = ({setFieldValue, values, campaign}: any) => {
  return (
    <>
      {campaign.media?.reference && (
        <FirebaseStorageImage
          Component={AspectRatioImage}
          reference={campaign.media?.reference as string}
          ratio={values.imageAspectRatio}
        />
      )}
      <FormikFileInput name="media" maxSize={5} />
      <RadioGroup
        row
        name="imageAspectRatio"
        value={values.imageAspectRatio}
        onChange={e => setFieldValue('imageAspectRatio', e.target.value)}>
        <FormControlLabel value="16:9" control={<Radio />} label="16:9" />
        <FormControlLabel value="1.91:1" control={<Radio />} label="1.91:1" />
        <FormControlLabel value="1:1" control={<Radio />} label="1:1" />
        <FormControlLabel value="9:16" control={<Radio />} label="9:16" />
        <FormControlLabel value="10:4" control={<Radio />} label="10:4" />
      </RadioGroup>
      <FormikTextField name="adName" label="Nom" />
      <FormikTextField name="primaryText" label="Texte primaire" />
      <FormikTextField name="headline" label="Titre" />
      <FormikTextField name="description" label="Description" />
      <FormikTextField name="websiteURL" label="Website URL" />
    </>
  );
};

export default StepThree;
