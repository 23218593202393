import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {useSaveBusinessAsSiteTemplateMutation} from '../../redux/businessesApi';

const SaveAsTemplateModal = ({
  business,
  open,
  onClose,
}: {
  business?: PopulatedBusiness;
  open: boolean;
  onClose: () => void;
}) => {
  const [saveAsTemplate, mutation] = useSaveBusinessAsSiteTemplateMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(`Le site a été enregistré comme modèle`);
      onClose();
    }
  }, [mutation.isSuccess, business?.isSiteTemplate]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enregistrer le site comme modèle</DialogTitle>
      <DialogContent>
        Voulez-vous enregistrer ce site comme modèle ?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          color="primary"
          onClick={() =>
            saveAsTemplate({
              id: business?._id as string,
            })
          }>
          Enregistrer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SaveAsTemplateModal;
