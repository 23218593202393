import {SuperUserRole} from '../../enums';
import DeleteSuperuser from '../DeactivateSuperuser/DeleteSuperuser';
import DeactivateSuperuser from '../DeleteSuperuser/DeactivateSuperuser';
import EnableSuperuser from '../EnableSuperuser/EnableSuperuser';

import {
  Close,
  Dashboard,
  Edit,
  LocalActivity,
  Lock,
  LockOpen,
  Person,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import Link from '../../components/atoms/Link';
import Dialogable from '../../components/molecules/Dialogable';

import {useCanViewSuperuserParentObjectsQuery} from '../../redux/superusersApi';

import UpdateSuperuserReferralCode from '../../pages/UpdateSuperuserReferralCode/UpdateSuperuserReferralCode';

import SuperuserCouponsActions from './SuperuserCouponsActions';

const SuperuserActions = ({
  superuser,
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  superuser: SuperUser;
}) => {
  const superuserParent = superuser.parent as SuperUser;

  const canViewQuery = useCanViewSuperuserParentObjectsQuery(superuser._id, {
    skip: !open,
  });

  const canView = !!canViewQuery.data;

  const isRoot = useUserIsRoot();

  const renderAssignListItem = () => {
    switch (superuser.role) {
      case SuperUserRole.MANAGER:
        return (
          <Link to={`/actions/superusers/${superuser._id}/assign-head-manager`}>
            <MenuItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText>Affecter à un head manager</ListItemText>
            </MenuItem>
          </Link>
        );
      case SuperUserRole.SALES:
        return (
          <Link to={`/actions/superusers/${superuser._id}/assign-manager`}>
            <MenuItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText>Affecter à un manager</ListItemText>
            </MenuItem>
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2}>
          {superuserParent && superuserParent.role !== 'ROOT' && (
            <>
              <Box px={2}>
                <Typography>
                  Cet utilisateur est géré par{' '}
                  <b>
                    {superuserParent?.firstName} {superuserParent?.lastName}
                  </b>
                </Typography>
              </Box>
              <Divider />
            </>
          )}
          <MenuList>
            {canView && (
              <Link to={`/superusers/${superuser._id}/dashboard`}>
                <MenuItem>
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText>Tableau de bord</ListItemText>
                </MenuItem>
              </Link>
            )}
            {canView && (
              <Link to={`/actions/superusers/update/${superuser._id}`}>
                <MenuItem>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText>Modifier les informations</ListItemText>
                </MenuItem>
              </Link>
            )}
            {canView && (
              <Link to={`/actions/superusers/update-password/${superuser._id}`}>
                <MenuItem>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  <ListItemText>Modifier les mot de passe</ListItemText>
                </MenuItem>
              </Link>
            )}
            {canView && (
              <Dialogable
                source={({onOpen}) => (
                  <MenuItem onClick={onOpen}>
                    <ListItemIcon>
                      <Lock />
                    </ListItemIcon>
                    <ListItemText>Regénerer le code de parrainage</ListItemText>
                  </MenuItem>
                )}
                dialog={({open, onClose}) => (
                  <UpdateSuperuserReferralCode
                    superuser={superuser}
                    open={open}
                    onClose={onClose}
                  />
                )}
              />
            )}
            {canView && renderAssignListItem()}
            {superuser.disabled && (
              <Dialogable
                source={({onOpen}) => (
                  <MenuItem onClick={onOpen}>
                    <ListItemIcon>
                      <LockOpen />
                    </ListItemIcon>
                    <ListItemText>Réactiver le compte</ListItemText>
                  </MenuItem>
                )}
                dialog={({open, onClose: onDialogClose}) => (
                  <EnableSuperuser
                    superuser={superuser}
                    open={open}
                    onClose={onDialogClose}
                    onSuccess={() => {
                      onDialogClose();
                      onClose();
                    }}
                  />
                )}
              />
            )}
            <Dialogable
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <LocalActivity />
                  </ListItemIcon>
                  <ListItemText>Ouvrir la page des coupons</ListItemText>
                </MenuItem>
              )}
              dialog={({open, onClose: onDialogClose}) => (
                <SuperuserCouponsActions
                  superuser={superuser}
                  open={open}
                  onClose={onDialogClose}
                />
              )}
            />
            {!superuser.disabled && (
              <Dialogable
                source={({onOpen}) => (
                  <MenuItem disabled={!canView} onClick={onOpen}>
                    <ListItemIcon>
                      <Lock />
                    </ListItemIcon>
                    <ListItemText>Désactiver le compte</ListItemText>
                  </MenuItem>
                )}
                dialog={({open, onClose: onDialogClose}) => (
                  <DeactivateSuperuser
                    superuser={superuser}
                    open={open}
                    onClose={onDialogClose}
                    onSuccess={() => {
                      onDialogClose();
                      onClose();
                    }}
                  />
                )}
              />
            )}
            {isRoot && (
              <Dialogable
                source={({onOpen}) => (
                  <MenuItem onClick={onOpen}>
                    <ListItemIcon>
                      <Close />
                    </ListItemIcon>
                    <ListItemText>Supprimer le compte</ListItemText>
                  </MenuItem>
                )}
                dialog={({open, onClose: onDialogClose}) => (
                  <DeleteSuperuser
                    superuser={superuser}
                    open={open}
                    onClose={onDialogClose}
                    onSuccess={() => {
                      onDialogClose();
                      onClose();
                    }}
                  />
                )}
              />
            )}
          </MenuList>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SuperuserActions;
