import React, {ComponentProps} from 'react';

import {Radio} from '@mui/material';
import {Field, FieldProps} from 'formik';

declare type Props = {name: string} & ComponentProps<typeof Radio>;

const FormikRadio: React.FC<Props> = ({name, value, ...props}) => {
  return (
    <Field name={name}>
      {({field, form}: FieldProps) => (
        <Radio
          name={name}
          value={value}
          onChange={() => {
            form.setFieldValue(name, value);
          }}
          checked={field.value === value}
          {...props}
        />
      )}
    </Field>
  );
};

export default FormikRadio;
