import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {LoadingButton} from '@mui/lab';
import {Button, Stack} from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import PageAction from '../../components/organisms/PageAction';

import {
  useCreateCampaignMutation,
  useGetCampaignByIdQuery,
  useUpdateCampaignMutation,
} from '../../redux/campaignsApi';

import StepOne, {stepOneSchema} from './FormSteps/StepOne';
import StepThree, {stepThreeSchema} from './FormSteps/StepThree';
import StepTwo, {stepTwoSchema} from './FormSteps/StepTwo';

const steps = [
  {
    title: 'Les paramètres de la campagne',
    component: StepOne,
    schema: stepOneSchema,
  },
  {
    title: "Créer un groupe d'annonces",
    component: StepTwo,
    schema: stepTwoSchema,
  },
  {
    title: 'Créer une annonce',
    component: StepThree,
    schema: stepThreeSchema,
  },
];

const CampaignForm = () => {
  const location = useLocation();
  const uploadFile = useUploadFile();
  const navigate = useNavigate();
  const {id} = useParams();

  const query = useGetCampaignByIdQuery({id}, {skip: !id});

  const [activeStep, setActiveStep] = React.useState(0);

  const [create, createMutation] = useCreateCampaignMutation();
  const [update, updateMutation] = useUpdateCampaignMutation();

  React.useEffect(() => {
    const responseError = createMutation.error || (updateMutation.error as any);
    if (responseError) {
      if (responseError?.data?.error?.code === '') {
        NotificationManager.error('Le nom déjà utilisé');
      } else {
        internalError(
          // mutation.error
          createMutation.error || updateMutation.error,
        );
      }
    }
    if (createMutation.isSuccess || updateMutation.isSuccess) {
      NotificationManager.success('La campagne a été sauvegardée avec succès');
    }
  }, [createMutation, updateMutation]);

  const nextStep = () => {
    if (!(activeStep === steps.length - 1)) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const backStep = () => {
    if (!(activeStep === 0)) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const onSubmit = React.useCallback(
    async ({
      name,
      startDate,
      endDate,
      budget,
      adSetName,
      address,
      locations,
      radius,
      placements,
      categories,
      adName,
      media,
      imageAspectRatio,
      primaryText,
      headline,
      description,
      websiteURL,
    }: any) => {
      try {
        const data: any = {
          campaign: {
            name,
            startDate,
            endDate,
            budget,
          },
          adSet: {
            name: adSetName,
            address: [address],
            locations: {
              type: 'MultiPoint',
              coordinates: [[locations.longitude, locations.latitude]],
            },
            radius,
            placements: placements.length ? placements : null,
            categories: categories.length ? categories : null,
          },
          ad: {
            name: adName,
            primaryText,
            headline,
            description,
            websiteURL,
            imageAspectRatio,
          },
          ids: {
            campaign: query?.data?._id,
            adSet: query?.data?.adSet?._id,
            ad: query?.data?.ad?._id,
          },
        };
        if (media) {
          const mediaFile = await uploadFile(media);
          data.ad.media = mediaFile._id;
        }
        query?.data?._id
          ? await update({id: query?.data?._id, data})
          : await create(data);
        navigate(-1);
      } catch (error) {
        console.error('Form campaign error: ', error);
      }
    },
    [query?.data, create, navigate, update, uploadFile],
  );

  return (
    <PageAction
      title={
        location?.state?.id
          ? `Modifier la campagne : ${location?.state?.name}`
          : 'Créer une nouvelle campagne'
      }>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(({title}) => (
          <Step key={title}>
            <StepLabel>{title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Formik
        validationSchema={yup.object().shape(steps[activeStep].schema)}
        onSubmit={async values =>
          activeStep === steps.length - 1 ? onSubmit(values) : nextStep()
        }
        initialValues={{
          id,
          name: query?.data?.name || '',
          budget: query?.data?.budget || 5,
          startDate: query?.data?.startDate
            ? new Date(query?.data?.startDate)
            : new Date(),
          endDate: query?.data?.endDate ? new Date(query?.data?.endDate) : null,
          adSetName: query?.data?.adSet?.name || '',
          address: query?.data?.adSet?.address[0] || '',
          locations: query?.data?.adSet?.locations
            ? {
                longitude: query?.data?.adSet?.locations?.coordinates[0][0],
                latitude: query?.data?.adSet?.locations?.coordinates[0][1],
              }
            : null,
          radius: query?.data?.adSet?.radius || 0,
          placements: query?.data?.adSet?.placements || [],
          categories: query?.data?.adSet?.categories || [],
          adName: query?.data?.ad?.name || '',
          media: '',
          imageAspectRatio: query?.data?.ad?.imageAspectRatio || '16:9',
          primaryText: query?.data?.ad?.primaryText || '',
          headline: query?.data?.ad?.headline || '',
          description: query?.data?.ad?.description || '',
          websiteURL: query?.data?.ad?.websiteURL || '',
        }}
        enableReinitialize={!!id}>
        {({handleSubmit, setFieldValue, values}) => (
          <Stack direction="column" spacing={2} mt={3}>
            {React.createElement(steps[activeStep].component, {
              setFieldValue,
              values,
              campaign: query?.data || {},
            })}
            <Stack direction="row" spacing={2}>
              <Button
                onClick={backStep}
                variant="outlined"
                disabled={activeStep === 0}
                fullWidth>
                &#60; Retour
              </Button>
              <LoadingButton
                onClick={() => handleSubmit()}
                loading={createMutation.isLoading || updateMutation.isLoading}
                disabled={createMutation.isLoading || updateMutation.isLoading}
                variant="contained"
                color="primary"
                fullWidth>
                {activeStep === steps.length - 1
                  ? 'Sauvegarder'
                  : 'Suivant \u003E'}
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CampaignForm;
