import React from 'react';

import {
  Box,
  CircularProgress,
  // Divider,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// import useGetAuthUserId from '../../lib/hooks/useGetAuthUserId';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {useLazyGetBusinessQRCodePDFQuery} from '../../redux/businessesApi';

// import T0 from '../../assets/images/pdf-template-0.png';
// import T1 from '../../assets/images/pdf-template-1.png';
// import T2 from '../../assets/images/pdf-template-2.png';

/* const getSelectedTemplateStyle = (selected: boolean) => ({
  cursor: 'pointer',
  borderRadius: 3,
  border: selected ? '3px solid #4CAF50' : 0,
}); */

const DownloadBusinessQRCode = ({
  business,
  open,
  onClose,
}: {
  business: Business;
  open: boolean;
  onClose: () => void;
}) => {
  const [getBusinessQRCodePDF, result] = useLazyGetBusinessQRCodePDFQuery();
  // const authUserId = useGetAuthUserId();
  // const [template, setTemplate] = React.useState('basic');

  useQueryInternalError(result);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{position: 'relative'}}>
        {result.isFetching && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              position: 'absolute',
              inset: 0,
              backgroundColor: 'rgba(0, 0, 0, .45)',
            }}>
            <CircularProgress />
          </Box>
        )}

        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flex: 1,
            width: '32vw',
            mb: 2,
          }}>
          <img
            src={T0}
            alt="template"
            onClick={() => setTemplate('basic')}
            width="20%"
            style={getSelectedTemplateStyle(template === 'basic')}
          />
          <img
            src={T1}
            alt="template"
            onClick={() => setTemplate('')}
            width="20%"
            style={getSelectedTemplateStyle(template === '')}
          />
          <img
            src={T2}
            alt="template"
            onClick={() => setTemplate('t2')}
            width="20%"
            style={getSelectedTemplateStyle(template === 't2')}
          />
        </Box> */}

        <MenuList>
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A4',
                template: 'basic',
              })
            }>
            <ListItemText>Cliquez pour télécharger</ListItemText>
          </MenuItem>
          {/* <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A4',
                template,
              })
            }>
            <ListItemText>Format A4 210mm × 297mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A5',
                template,
              })
            }>
            <ListItemText>Format A5 148mm × 210mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A6',
                template,
              })
            }>
            <ListItemText>Format A6 105mm × 148mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A7',
                template,
              })
            }>
            <ListItemText>Format A7: 74mm × 105mm</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              getBusinessQRCodePDF({
                id: business._id,
                format: 'A8',
                template,
              })
            }>
            <ListItemText>Format A8: 52mm × 74mm</ListItemText>
          </MenuItem> */}
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadBusinessQRCode;
