import Dialogable from '../molecules/Dialogable';

import React from 'react';

import {FilterList, Save} from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

export const THIS_MONTH = 'THIS_MONTH';
export const CUSTOM_INTERVAL = 'CUSTOM_INTERVAL';

const DashboardFilter = ({
  setQueryParams,
}: {
  setQueryParams: (data: {
    date1?: Date;
    date2?: Date;
    filterType: string;
  }) => void;
}) => {
  const [date1, setDate1] = React.useState<any>();
  const [date2, setDate2] = React.useState<any>(new Date());

  const [filterType, setFilterType] = React.useState('THIS_MONTH');

  const isValid = filterType === THIS_MONTH || (date1 && date2);

  return (
    <Dialogable
      source={({onOpen}) => (
        <IconButton size="medium" onClick={onOpen}>
          <FilterList fontSize="medium" />
        </IconButton>
      )}
      dialog={({open, onClose}) => (
        <Dialog fullWidth open={open} onClose={onClose}>
          <Stack direction="column" spacing={2} p={2}>
            <Typography variant="h5">Filtre</Typography>
            <FormControl>
              <InputLabel>Chercher</InputLabel>
              <Select
                label="Chercher"
                value={filterType}
                onChange={evt => setFilterType(evt.target.value)}>
                <MenuItem value={THIS_MONTH}>Ce mois</MenuItem>
                <MenuItem value={CUSTOM_INTERVAL}>
                  Dans un intervalle personnalisé
                </MenuItem>
              </Select>
            </FormControl>
            {filterType === CUSTOM_INTERVAL && (
              <>
                <DatePicker
                  mask="__/__/____"
                  label="Du"
                  value={date1 || null}
                  maxDate={date2}
                  onChange={val => setDate1(val)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
                <DatePicker
                  mask="__/__/____"
                  label="Jusqu'à"
                  value={date2 || null}
                  minDate={date1}
                  maxDate={new Date()}
                  onChange={val => setDate2(val)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </>
            )}
            <Button
              disabled={!isValid}
              onClick={() => {
                setQueryParams({
                  filterType,
                  date1,
                  date2,
                });
                onClose();
              }}
              sx={{alignSelf: 'flex-end'}}
              variant="contained"
              size="medium"
              endIcon={<Save />}>
              Sauvegarder
            </Button>
          </Stack>
        </Dialog>
      )}
    />
  );
};

export default DashboardFilter;
