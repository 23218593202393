import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Block, Check, MoreVert} from '@mui/icons-material';
import {
  Chip,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {useDeleteCampaignMutation} from '../../redux/campaignsApi';

const CampaignCard: React.FC<{
  campaign: any;
}> = ({campaign}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [deleteCampaign, mutation] = useDeleteCampaignMutation();

  React.useEffect(() => {
    if (mutation.isError) {
      internalError(mutation.error);
    }
    if (mutation.isSuccess) {
      NotificationManager.success('La Campagne a été supprimée avec succès');
      setAnchorEl(null);
    }
  }, [mutation.error, mutation.isError, mutation.isSuccess]);

  const onRemoveCampaign = React.useCallback(
    async (id: string) => {
      if (
        window.confirm('Êtes-vous sûr vouloir supprimer cette campagne?') ===
        true
      ) {
        await deleteCampaign({id});
      }
    },
    [deleteCampaign],
  );

  const openActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  return (
    <Paper square sx={{py: 2, px: 2, position: 'relative'}} variant="outlined">
      {mutation.isLoading && <LinearProgress />}
      <IconButton
        sx={{position: 'absolute', top: 10, right: 4, zIndex: 1}}
        onClick={openActions}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <MoreVert color="action" fontSize="medium" />
      </IconButton>
      <Menu
        id="campaign-actions"
        anchorEl={anchorEl}
        open={open}
        onClose={closeActions}>
        <MenuItem
          onClick={() =>
            navigate(`/actions/campaigns/edit/${campaign._id}`, {
              state: {id: campaign._id, name: campaign.name},
            })
          }>
          Modifier
        </MenuItem>
        <MenuItem onClick={() => onRemoveCampaign(campaign._id)}>
          Supprimer
        </MenuItem>
      </Menu>

      <Typography variant="h5">{campaign.name}</Typography>
      <Typography variant="body1">
        Budget journalier : {campaign.budget}€
      </Typography>
      <Typography variant="body1">
        Date de début :{' '}
        {new Date(campaign.startDate).toLocaleDateString('fr-FR')}
      </Typography>
      <Typography variant="body1">
        Date de fin :{' '}
        {campaign.endDate
          ? new Date(campaign.endtDate).toLocaleDateString('fr-FR')
          : '∞'}
      </Typography>
      <Typography variant="body1" mt={1}>
        <Chip
          icon={campaign.status === 1 ? <Check /> : <Block />}
          color={campaign.status === 1 ? 'success' : 'error'}
          label={campaign.status === 1 ? 'Active' : 'Inactive'}
          variant="outlined"
        />
      </Typography>
    </Paper>
  );
};

export default CampaignCard;
