import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import firebase from 'firebase/compat/app';
import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';

const validationSchema = yup.object({
  password: yup.string().required('Champs requis'),
});

const EditPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onUpdatePassword = React.useCallback(
    async (password: string) => {
      try {
        setLoading(true);
        await firebase.auth().currentUser?.updatePassword(password);
        NotificationManager.success('Mot de passe a été bien modifié');
        navigate('/settings');
      } catch (err) {
        internalError(err);
      } finally {
        setLoading(false);
      }
    },
    [navigate],
  );

  return (
    <PageAction title="Mot de passe">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          password: '',
        }}
        onSubmit={form => onUpdatePassword(form.password)}>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <FormikTextField name="password" label="Mot de passe" />
            <SaveButton loading={loading} onClick={() => handleSubmit()}>
              Sauvegarder
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default EditPassword;
