import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';

import {
  useGetAuthUserQuery,
  useUpdateProfileMutation,
} from '../../redux/authApi';

const validationSchema = yup.object({
  firstName: yup.string().required('Champs requis'),
  lastName: yup.string().required('Champs requis'),
  email: yup.string().required('Champs requis'),
});

const EditProfile = () => {
  const navigate = useNavigate();
  const query = useGetAuthUserQuery();
  const [updateProfile, mutation] = useUpdateProfileMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Profil a été bien modifié');
      navigate('/settings');
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.error, mutation.isError, mutation.isSuccess, navigate]);

  return (
    <PageAction title="Mon compte">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          firstName: query.data?.firstName || '',
          lastName: query.data?.lastName || '',
          email: query.data?.email || '',
          referralCode: query.data?.referralCode?.toUpperCase() || '',
        }}
        onSubmit={form =>
          updateProfile({
            firstName: form.firstName,
            lastName: form.lastName,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FormikTextField name="firstName" label="Nom" />
              <FormikTextField name="lastName" label="Prénom" />
              <FormikTextField name="email" label="Email" disabled />
              <FormikTextField
                name="referralCode"
                label="Code de parrainage"
                disabled
              />
            </Stack>
            <SaveButton
              loading={query.isLoading || mutation.isLoading}
              onClick={() => handleSubmit()}>
              Sauvegarder
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default EditProfile;
