import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';

import PageAction from '../../components/organisms/PageAction';

const CreateBusinessIntro = () => {
  const [businessType, setBusinessType] = React.useState('ACTIVE');
  const navigate = useNavigate();

  const handleContinue = React.useCallback(() => {
    if (businessType === 'ACTIVE') {
      navigate('/actions/businesses/create', {
        replace: true,
      });
    }

    if (businessType === 'PENDING') {
      navigate('/actions/businesses/create-pending', {
        replace: true,
      });
    }

    if (businessType === 'REFERENCE') {
      navigate('/actions/businesses/create-reference', {
        replace: true,
      });
    }

    if (businessType === 'B2BWeb') {
      navigate('/actions/businesses/create-b2b-web', {
        replace: true,
      });
    }
  }, [businessType, navigate]);

  return (
    <PageAction title="Type de commerce">
      <Stack direction="column" spacing={1}>
        <RadioGroup
          row
          name="sort"
          value={businessType}
          onChange={evt => setBusinessType(evt.target.value)}>
          <FormControlLabel value="ACTIVE" control={<Radio />} label="Active" />
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label="Non activé"
          />
          <FormControlLabel
            value="REFERENCE"
            control={<Radio />}
            label="Afficheur"
          />
          <FormControlLabel
            value="B2BWeb"
            control={<Radio />}
            label="B2B Web"
          />
        </RadioGroup>
        <Button onClick={handleContinue} variant="contained" color="primary">
          Continuer
        </Button>
      </Stack>
    </PageAction>
  );
};

export default CreateBusinessIntro;
