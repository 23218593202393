import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {useGetManagersQuery} from '../../redux/superusersApi';

import PeoplePicker from './PeoplePicker';

const ManagerPicker = ({name, label}: {name: string; label: string}) => {
  const query = useGetManagersQuery({
    $skip: 0,
    $limit: 1000,
  });

  useQueryInternalError(query);

  return (
    <PeoplePicker
      name={name}
      label={label}
      data={query.data?.data}
      loading={query.isLoading}
    />
  );
};

export default ManagerPicker;
