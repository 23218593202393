import authApi from '../authApi';
import businessCategoriesApi from '../businessCategoriesApi';
import {customBusinessApi} from '../businessesApi';
import businessReferralsApi, {
  customBusinessReferralsApi,
} from '../businessReferralsApi';
import filesApi from '../filesApi';
import {autocompleteApi, geocodingApi} from '../geocodingApi';
import groupsApi from '../groupsApi';
import partnersApi from '../partnersApi';
import runScriptApi from '../runScriptApi';
import superusersApi from '../superusersApi';

import firebase from '../../lib/app/firebase';

import {createAsyncThunk} from '@reduxjs/toolkit';

export type SigninPayload = {
  email: string;
  password: string;
};

export const signin = createAsyncThunk(
  'auth/signin',
  async ({email, password}: SigninPayload) => {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  },
);

export const logout = createAsyncThunk('logout', async (_, {dispatch}) => {
  firebase.auth().signOut();
  dispatch(superusersApi.util.resetApiState());
  dispatch(authApi.util.resetApiState());
  dispatch(customBusinessApi.util.resetApiState());
  dispatch(geocodingApi.util.resetApiState());
  dispatch(autocompleteApi.util.resetApiState());
  dispatch(runScriptApi.util.resetApiState());
  dispatch(businessReferralsApi.util.resetApiState());
  dispatch(customBusinessReferralsApi.util.resetApiState());
  dispatch(businessCategoriesApi.util.resetApiState());
  dispatch(groupsApi.util.resetApiState());
  dispatch(filesApi.util.resetApiState());
  dispatch(partnersApi.util.resetApiState());
});
