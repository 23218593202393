import * as yup from 'yup';

yup.addMethod(yup.object, 'locationRequired', function (message: string) {
  return this.test({
    name: 'locationRequired',
    message: '${path} is required',
    test: function (value: any) {
      const {path, createError, parent} = this;
      const form = parent;

      return value?.longitude && value?.latitude
        ? true
        : createError({path, message});
    },
  });
});

declare module 'yup' {
  // @ts-ignore
  interface ObjectSchema {
    locationRequired(message: string): any;
  }
}
