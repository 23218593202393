const defaultIdGetter: (params: unknown) => string = (id: any) => id;

const invalidateTagsUsingParamId = <T>(
  type: T,
  getId: (params: unknown) => string = defaultIdGetter,
) => {
  return (result: any, error: any, params: any) => {
    const id = getId(params);

    return result
      ? [
          {type, _id: id},
          {type, _id: 'LIST '},
        ]
      : [{type, _id: 'LIST '}];
  };
};

export default invalidateTagsUsingParamId;
