import React from 'react';
import {NotificationManager} from 'react-notifications';

import LoadingButton from '@mui/lab/LoadingButton';
import {Stack} from '@mui/material';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';
import PageAction from '../../components/organisms/PageAction';

import { useCreatePreferenceMutation, useGetPreferencesQuery, useUpdatePreferenceMutation } from '../../redux/preferencesApi';

const validationSchema = yup.object({
  dailyBudget: yup.number().required('Champ obligatoire'),
});

const Preferences = () => {
  const [create, mutation] = useCreatePreferenceMutation();
  const [update, updateMutation] = useUpdatePreferenceMutation();

  const query = useGetPreferencesQuery({params: {name: "dailyBudget"}});
  const preferencesData = query.isSuccess ? query.data?.data[0] : undefined;

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Preference a été bien Sauvegardé');
    }

    if (updateMutation.isSuccess) {
      NotificationManager.success('Preference a été bien Sauvegardé');
    }
  });

  const save = React.useCallback(({dailyBudget}: {dailyBudget: number}) => {
    if (preferencesData) {
      update({id: preferencesData._id, data: { preferences: { value: dailyBudget } }});
    } else {
      create({name: "dailyBudget", preferences: { value: dailyBudget }});
    }
  }, [create, preferencesData, update]);

  return (
    <PageAction title="Préférences">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          dailyBudget: preferencesData?.preferences?.value || 5 ,
        }}
        enableReinitialize
        onSubmit={save}>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <FormikTextField type='number' name="dailyBudget" label="Budget journalier" />
            <LoadingButton
              loading={mutation.isLoading || updateMutation.isLoading}
              disabled={query.isLoading || mutation.isLoading || updateMutation.isLoading}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}>
              Sauvegarder
            </LoadingButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default Preferences;
