import {Search} from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

const SuperusersListHeader = ({
  search,
  onSearchChange,
  role,
  onRoleChanged,
}: {
  role?: string;
  onRoleChanged?: (val: string) => void;
  search: string;
  onSearchChange: (val: string) => void;
}) => {
  const userIsRoot = useUserIsRoot();

  return (
    <Stack sx={{mb: 2}} direction="row" spacing={1}>
      <TextField
        variant="outlined"
        label="Recherche"
        value={search}
        sx={{flex: 5}}
        InputProps={{
          endAdornment: <Search />,
        }}
        onChange={evt => onSearchChange(evt.target.value)}
      />
      {userIsRoot && (
        <FormControl sx={{flex: 4}}>
          <InputLabel>Filtre</InputLabel>
          <Select
            label="Filtre"
            value={role}
            onChange={evt => onRoleChanged?.(evt.target.value)}>
            <MenuItem value="">Tout</MenuItem>
            <MenuItem value="HEAD_MANAGER">Head manager</MenuItem>
            <MenuItem value="MANAGER">Responsables de pôle</MenuItem>
            <MenuItem value="SALES">Commerciales</MenuItem>
          </Select>
        </FormControl>
      )}
    </Stack>
  );
};

export default SuperusersListHeader;
