import React from 'react';

import {FileDownload} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {Formik} from 'formik';

import DashboardFilter, {
  THIS_MONTH,
} from '../../components/organisms/DashboardFilter';
import PageAction from '../../components/organisms/PageAction';

import {useExportContactsMutation} from '../../redux/superusersApi';

const ExportContacts = () => {
  const [exportContacts, mutation] = useExportContactsMutation();
  const [query, setQueryParams] = React.useState<any>({
    filterType: THIS_MONTH,
  });

  React.useEffect(() => {
    if (mutation.isSuccess) {
      downloadFile(mutation.data);
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.data, mutation.error, mutation.isError, mutation.isSuccess]);

  const downloadFile = (data: string) => {
    const blob = new Blob([data], {type: 'text/csv'});
    const a = document.createElement('a');
    a.download = 'contacts.csv';
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <PageAction title="Export Contacts">
      <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
        <DashboardFilter setQueryParams={setQueryParams} />
      </Stack>
      <Formik
        initialValues={{
          filterType: '',
          date1: undefined,
          date2: undefined,
          ...query,
        }}
        onSubmit={() =>
          exportContacts({
            ...query,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              onClick={() => handleSubmit()}
              startIcon={<FileDownload />}>
              Export
            </LoadingButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default ExportContacts;
