import FormikPhoneNumberInput from '../../atoms/formik/FormikPhoneNumberInput';
import FormikSingleChoice from '../../atoms/formik/FormikSingleChoice';
import FormikTextField from '../../molecules/formik/FormikTextField';
import OwnersPicker from '../../molecules/OwnersPicker';

import {FormControlLabel, Stack} from '@mui/material';

import {useFormikContext} from 'formik';

const FormikUserInfo = () => {
  const formik = useFormikContext<{
    newUser: boolean;
    phoneNumber: string;
    lastName: string;
    firstName: string;
    owner: string;
  }>();
  const {values} = formik;

  return (
    <>
      <FormControlLabel
        control={<FormikSingleChoice name="newUser" />}
        label="Nouveau utilisateur"
      />
      {values.newUser ? (
        <>
          <FormikPhoneNumberInput
            name="phoneNumber"
            countryName="country"
            extName="ext"
            label="Numéro de téléphone"
          />
          <Stack direction="row" spacing={1}>
            <FormikTextField sx={{flex: 1}} name="lastName" label="Nom" />
            <FormikTextField sx={{flex: 1}} name="firstName" label="Prénom" />
          </Stack>
          <FormikTextField name="email" label="Email" />
        </>
      ) : (
        <OwnersPicker name="owner" label="Choisissez un utilisateur" />
      )}
    </>
  );
};

export const getUserInfoInitialValues = () => ({
  newUser: false,
  phoneNumber: '',
  country: 'FR',
  ext: '',
  lastName: '',
  firstName: '',
  owner: '',
  email: '',
});

export default FormikUserInfo;
