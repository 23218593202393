import {Chip, Stack} from '@mui/material';
import {Filter} from './types';

const BusinessFilterPage = ({
  filter: {filter, sortField, sortOrder, withSections, siteTemplatesOnly, status},
}: {
  filter: Filter;
}) => (
  <Stack sx={{overflowX: 'auto'}} pt={1} px={1} spacing={1} direction="row">
    {filter === 'USER_ONLY' && <Chip label="Mes commerces uniquement" />}
    <Chip
      label={
        <>
          {sortField === 'createdAt' && 'Date de création'}{' '}
          {sortField === 'name' && 'Nom'} {sortField === 'address' && 'Adresse'}{' '}
          {sortOrder === 1 && '(croissant)'}
          {sortOrder === -1 && '(décroissant)'}
        </>
      }
    />
    {withSections && <Chip label="Avec sections" />}
    {siteTemplatesOnly && <Chip label="Modèles de site seulement" />}
    {status === 'ACTIVE' && <Chip label="Actifs" />}
    {status === 'PENDING' && <Chip label="Inactifs" />}
    {status === 'REFERENCE' && <Chip label="Afficheurs" />}
    {status === 'B2B_WEB' && <Chip label="B2B Web" />}
  </Stack>
);
export default BusinessFilterPage;
