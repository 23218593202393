import {SuperUserRole} from '../../enums';

const renderAdminRole = (role?: string) => {
  switch (role) {
    case SuperUserRole.ROOT:
      return 'Root';
    case SuperUserRole.HEAD_MANAGER:
      return 'Head manager';
    case SuperUserRole.MANAGER:
      return 'Responsable de pôle';
    case SuperUserRole.SALES:
      return 'Agent commercial';
    default:
      return '';
  }
};

export default renderAdminRole;
