import {GoogleMap, MarkerF} from '@react-google-maps/api';
import React, {ComponentProps} from 'react';
import {NotificationManager} from 'react-notifications';

import {Close} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {useDebouncedCallback} from 'use-debounce';

import {
  useGetPlaceByIdQuery,
  useLazyAutoCompletePlacesQuery,
} from '../../../../redux/geocodingApi';

import {LoadingButton} from '@mui/lab';
import LoadingOverlay from '../../../../components/molecules/LoadingOverlay';
import useQueryInternalError from '../../../hooks/useQueryInternalError';

const initialRegion = {
  latitude: 48.8566,
  longitude: 2.3522,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GeoAddressPicker = React.forwardRef(
  (
    {
      location,
      address = '',
      onSave,
      onClose,
      fullAddress = true,
    }: {
      location: LatLng;
      address: string;
      onSave: (location: LatLng, address: string) => void;
      onClose: () => void;
      fullAddress?: boolean;
    },
    ref: any,
  ) => {
    const [touched, setTouched] = React.useState(false);
    const [error, setError] = React.useState<any>({});
    const [coords, setCoords] = React.useState<LatLng>(location);
    const [search, setSearch] = React.useState(address);
    const [selectedAddress, setSelectedAddress] = React.useState('');
    const [region, setRegion] = React.useState<LatLng>(
      location ?? initialRegion,
    );
    const [placeId, setPlaceId] = React.useState<string>();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [forceMenuClose, setForceMenuClose] = React.useState(true);

    const [triggerACPlaces, placesQuery] = useLazyAutoCompletePlacesQuery();

    const debouncedTriggerACPlaces = useDebouncedCallback(triggerACPlaces, 250);

    const placeQuery = useGetPlaceByIdQuery(placeId as string, {
      skip: !placeId,
    });

    useQueryInternalError(placeQuery);
    useQueryInternalError(placesQuery);

    React.useEffect(() => {
      if (search?.length >= 3) {
        debouncedTriggerACPlaces(search);
      }
    }, [debouncedTriggerACPlaces, search]);

    React.useEffect(() => {
      if (
        !forceMenuClose &&
        (placesQuery.isSuccess || placesQuery.isFetching)
      ) {
        setMenuOpen(true);
      }
    }, [forceMenuClose, placesQuery.isFetching, placesQuery.isSuccess]);

    React.useEffect(() => {
      if (placeQuery.isSuccess && placeQuery.data.result.geometry) {
        setRegion({
          longitude: placeQuery.data?.result.geometry.location.lng,
          latitude: placeQuery.data?.result.geometry.location.lat,
        });

        setCoords({
          longitude: placeQuery.data?.result.geometry.location.lng,
          latitude: placeQuery.data?.result.geometry.location.lat,
        });

        if (fullAddress) {
          // const hasStreetName = placeQuery.data.result.address_components?.some(
          //   (component: any) => component.types.includes('route'),
          // );

          const hasCity = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes('locality'),
          );

          const hasCountry = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes('country'),
          );

          const hasPostalCode = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes('postal_code'),
          );

          setError({
            // streetName: !hasStreetName,
            city: !hasCity,
            country: !hasCountry,
            postalCode: !hasPostalCode,
          });
        }
      }
    }, [fullAddress, placeQuery.data?.result, placeQuery.isSuccess]);

    const errorMessage = React.useMemo(() => {
      // if (error.streetName) {
      //   return 'Nom de rue manquant';
      // }

      if (error.city) {
        return 'Ville manquante';
      }

      if (error.country) {
        return 'Pays manquant';
      }

      if (error.postalCode) {
        return 'Code postal manquant';
      }

      if (!placeId) {
        return 'Adresse manquante';
      }

      return null;
    }, [
      error.city,
      error.country,
      error.postalCode,
      // error.streetName,
      placeId,
    ]);

    React.useEffect(() => {
      if (!region) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setRegion({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          () => {
            NotificationManager.error('Impossible de récupérer votre position');
          },
        );
      }
    }, []);

    return (
      <Paper ref={ref} sx={{width: '100%', height: '100%', tabIndex: -1}}>
        <Stack
          sx={{
            width: '100%',
            height: '100%',
          }}
          direction="column"
          spacing={2}
          p={2}>
          <TextField
            variant="outlined"
            value={search}
            onChange={event => setSearch(event.target.value)}
            onFocus={() => setForceMenuClose(false)}
            onBlur={() => setTouched(true)}
            label="Choisissez une addresse"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setSearch('');
                    setPlaceId(undefined);
                  }}>
                  <Close />
                </IconButton>
              ),
            }}
            helperText={
              (!!errorMessage || !placeId) && touched ? errorMessage : undefined
            }
            error={touched && (!!errorMessage || !placeId)}
          />
          <Stack
            sx={{
              flex: 1,
            }}
            direction="column"
            spacing={2}>
            <Box
              sx={{
                position: 'relative',
                flex: 1,
              }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={
                  region
                    ? {
                        lat: region.latitude,
                        lng: region.longitude,
                      }
                    : undefined
                }
                zoom={10}>
                {coords && (
                  <MarkerF
                    position={{
                      lng: coords.longitude,
                      lat: coords.latitude,
                    }}
                  />
                )}
              </GoogleMap>
              {menuOpen && !forceMenuClose && (
                <Box
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    backgroundColor: theme => theme.palette.common.white,
                  }}>
                  {placesQuery.isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        position: 'absolute',
                        inset: 0,
                        backgroundColor: theme => theme.palette.common.white,
                      }}>
                      <CircularProgress />
                    </Box>
                  )}
                  {placesQuery.isSuccess &&
                    placesQuery.data.predictions?.length > 0 && (
                      <List>
                        {placesQuery.data.predictions.map(feature => (
                          <ListItem
                            sx={{
                              pl: 1,
                            }}
                            key={feature.place_id}
                            onClick={event => {
                              if (feature.place_id) {
                                setPlaceId(feature.place_id);
                                setSelectedAddress(feature.description);
                                setSearch(feature.description);
                                setForceMenuClose(true);
                                setMenuOpen(false);
                              }
                              event.stopPropagation();
                            }}>
                            <ListItemText>{feature.description}</ListItemText>
                          </ListItem>
                        ))}
                        <Divider sx={{m: 2}} />
                        <Box p={2}>
                          <Typography variant="body2" align="center">
                            Fin
                          </Typography>
                        </Box>
                      </List>
                    )}
                  {placesQuery.isSuccess &&
                    placesQuery.data.predictions?.length === 0 && (
                      <Box
                        display="flex"
                        flex={1}
                        alignItems="center"
                        justifyContent="center">
                        <Typography variant="body1">
                          Aucune adresse n'a été trouvée
                        </Typography>
                      </Box>
                    )}
                </Box>
              )}
              {placeQuery.isLoading && <LoadingOverlay transparent />}
            </Box>
          </Stack>
          <Stack px={2} direction="row" justifyContent="center" spacing={1}>
            <ActionButton
              loading={placeQuery.isLoading}
              variant="contained"
              onClick={() => {
                if (placeId && !errorMessage) {
                  onSave(coords, selectedAddress);
                } else {
                  setTouched(true);
                }
              }}>
              Valider
            </ActionButton>
            <ActionButton
              variant="outlined"
              onClick={() => {
                onClose();
              }}>
              Annuler
            </ActionButton>
          </Stack>
        </Stack>
      </Paper>
    );
  },
);

const ActionButton = (props: ComponentProps<typeof LoadingButton>) => (
  <LoadingButton sx={{flex: 1, transform: 'none '}} {...props} />
);

export default GeoAddressPicker;
