import React from 'react';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import {NotificationManager} from 'react-notifications';

import {internalError} from '../../lib/helpers/internalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useDeleteBusinessMutation} from '../../redux/businessesApi';

const DeleteBusiness = ({
  business,
  open,
  onClose,
}: {
  business: Business;
  open: boolean;
  onClose: () => void;
}) => {
  const [deleteBusiness, mutation] = useDeleteBusinessMutation();
  const [deleteBy, setDeleteBy] = React.useState<string>("");

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Commerce supprimé avec succès');
      onClose();
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.isError, mutation.error, mutation.isSuccess, onClose]);

  return (
    <>
      <Options deleteBy={deleteBy} setDeleteBy={setDeleteBy} isParentOpen={open}/>
      <Confirmation
        isLoading={mutation.isLoading}
        description="Êtes-vous sûr vouloir supprimer ce commerce et tous les comptes liées définitivement?"
        open={!!deleteBy}
        onClose={() => onClose()}
        onConfirm={() => deleteBusiness({ id: business._id, deleteBy, status: business.status })}
      />
    </>
  );
};

export default DeleteBusiness;

const Options = ({deleteBy, setDeleteBy, isParentOpen}: {deleteBy: string; setDeleteBy: (val: string) => void; isParentOpen: boolean}) => {
  return (
    <Dialog open={!deleteBy && isParentOpen}>
      <DialogContent>
        Vous voulez supprimer par:
        <RadioGroup
          aria-labelledby="deleteBy"
          name="deleteBy"
          value={deleteBy}
          onChange={(e) => setDeleteBy((e.target as HTMLInputElement).value)}
        >
          <FormControlLabel value="owner" control={<Radio />} label="Propriétaire" />
          <FormControlLabel value="business" control={<Radio />} label="Établissement" />
        </RadioGroup>
      </DialogContent>
    </Dialog>
  );
}