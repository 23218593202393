import {createApi} from '@reduxjs/toolkit/dist/query/react';

import invalidatesTagsPostCreation from '../lib/app/invalidatesTagsPostCreation';
import invalidateTagsUsingParamId from '../lib/app/invalidateTagsUsingParamId';

import FileDownload from 'js-file-download';
import {authBaseQuery} from './apiBaseQuery';
import {IntlContent} from '../lib/intl/intl';
import invalidatesTagsUsingParamId from '../lib/helpers/invalidatesTagsUsingParamId';
import providesTagsArray from '../lib/helpers/providesTagsArray';

export const customBusinessApi = createApi({
  reducerPath: 'customBusinessApi',
  baseQuery: authBaseQuery,
  tagTypes: ['Business'],
  endpoints(build) {
    return {
      updateBusiness: build.mutation<
        Business,
        Partial<Business> & {
          id: string;
          billableAccount?: boolean;
          location?: LatLng;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/superUserUpdateBusiness/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'Business'},
                {id: 'LIST', type: 'Business'},
              ]
            : ['Business'],
      }),
      updateDisplayOrB2BWebBusiness: build.mutation<
        Business,
        Partial<Business> & {
          id: string;
          location?: LatLng;
          billableAccount?: boolean;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/updateDisplayOrB2BWebBusiness/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'Business'},
                {id: 'LIST', type: 'Business'},
              ]
            : ['Business'],
      }),
      updateBusinessImage: build.mutation<
        Business,
        {
          id: string;
          image: string;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/superUserUpdateBusinessImage/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'Business'},
                {id: 'LIST', type: 'Business'},
              ]
            : ['Business'],
      }),
      updatePendingBusiness: build.mutation<
        Business,
        Partial<Business> & {
          id: string;
          location?: LatLng;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/superUserUpdatePendingBusiness/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'Business'},
                {id: 'LIST', type: 'Business'},
              ]
            : ['Business'],
      }),
      updateBusinessAssignee: build.mutation<
        Business,
        {
          id: string;
          assignee: string;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/updateBusinessAssignee/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'Business'},
                {id: 'LIST', type: 'Business'},
              ]
            : [{id: 'LIST', type: 'Business'}],
        async onQueryStarted({id}, api) {
          try {
            await api.queryFulfilled;
          } catch (err) {}
        },
      }),
      getCanViewBusinessAssigneeObjects: build.query<boolean, string>({
        query: id => ({
          url: `/canViewBusinessAssigneeObjects/${id}`,
          method: 'get',
        }),
      }),
      getAllBusinessCategories: build.query<BusinessCategory[], void>({
        query: () => ({
          url: '/getBusinessCategories',
          method: 'get',
          keepUnusedDataFor: 1000000,
        }),
      }),
      getBusinesses: build.query<
        PaginateResult<Business>,
        PaginateParams & {
          userOnly?: boolean;
          search?: string;
          sortField: string;
          sortOrder: number;
          withSections?: boolean;
          siteTemplatesOnly?: boolean;
          status: string;
          owner: string;
        }
      >({
        query: ({
          $skip,
          $limit,
          userOnly,
          search,
          sortField,
          sortOrder,
          withSections,
          siteTemplatesOnly,
          owner,
          status,
        }) => ({
          url: `/getSuperuserBusinesses`,
          method: 'get',
          params: {
            $skip,
            $limit,
            userOnly,
            search: search || undefined,
            sortField,
            sortOrder,
            withSections: withSections ? 'true' : 'false',
            siteTemplatesOnly: siteTemplatesOnly ? 'true' : 'false',
            status,
            owner,
          },
        }),
        providesTags: result =>
          result
            ? [
                ...result.data.map(b => ({
                  type: 'Business' as const,
                  id: b._id,
                })),
                {type: 'Business', id: 'LIST'},
              ]
            : ['Business'],
      }),
      getBusinessById: build.query<PopulatedBusiness, string>({
        query: id => ({
          url: `/getBusinessById/${id}`,
          method: 'get',
        }),
        providesTags: result =>
          result
            ? [
                {type: 'Business' as const, id: result._id},
                {type: 'Business', id: 'LIST'},
              ]
            : ['Business'],
      }),
      getBusinessQRCodePDF: build.query<
        any,
        {id: string; format: string; template?: string}
      >({
        query: ({id, format = 'A6', template = ''}) => ({
          url: `/getSuperuserBusinessQRCodePDF/${id}`,
          method: 'get',
          params: {
            format,
            template,
          },
          responseType: 'blob',
        }),
        transformResponse(response: any): any {
          FileDownload(response, `business-qr-code-${Date.now()}.png`);
          return {data: ''};
        },
        providesTags: [],
      }),
      getBusinessStats: build.query<
        BusinessStats,
        {
          id: string;
          filterType: string;
          date1: number;
          date2: number;
          owner?: string;
        }
      >({
        query: data => ({
          url: `/getBusinessStats/${data.id}`,
          method: 'get',
          params: {
            filterType: data.filterType,
            date1: data.date1,
            date2: data.date2,
            owner: data.owner,
          },
        }),
      }),
      createBusiness: build.mutation<
        Business,
        {
          image: string;
          phoneNumber: string;
          name: string;
          email: string;
          lastName: string;
          firstName: string;
          descriptionIntl: IntlContent;
          category: string;
          assignee?: string;
          address: string;
          billableAccount: boolean;
          displayB2B: boolean;
          displayB2C: boolean;
          withSections: boolean;
          location: LatLng;
          qrCodeQuantity?: number;
        }
      >({
        query: data => ({
          url: `/superUserCreateBusiness`,
          method: 'post',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {
                  type: 'Business' as const,
                  id: result._id,
                },
                {type: 'Business', id: 'LIST'},
              ]
            : ['Business'],
      }),
      createBusinessPending: build.mutation<
        Business,
        {
          image: string;
          name: string;
          email: string;
          ext: string;
          country: string;
          phoneNumber: string;
          descriptionIntl: IntlContent;
          category: string;
          assignee?: string;
          address: string;
          location: LatLng;
          displayB2B: boolean;
          displayB2C: boolean;
          qrCodeQuantity?: number;
          withSections: boolean;
        }
      >({
        query: data => ({
          url: `/createBusinessPending`,
          method: 'post',
          data,
        }),
        invalidatesTags: invalidatesTagsPostCreation('Business'),
      }),
      createBusinessReference: build.mutation<
        Business,
        {
          image: string;
          email: string;
          name: string;
          ext: string;
          country: string;
          phoneNumber: string;
          descriptionIntl: IntlContent;
          category: string;
          assignee?: string;
          address: string;
          location: LatLng;
          radius: number;
          displayB2B: boolean;
          displayB2C: boolean;
          qrCodeQuantity?: number;
          withSections: boolean;
        }
      >({
        query: data => ({
          url: `/createBusinessReference`,
          method: 'post',
          data,
        }),
        invalidatesTags: invalidatesTagsPostCreation('Business'),
      }),
      createBusinessB2BWeb: build.mutation<
        Business,
        {
          image: string;
          email: string;
          name: string;
          phoneNumber: string;
          lastName: string;
          firstName: string;
          owner: string;
          contactExt: string;
          contactCountry: string;
          contactPhoneNumber: string;
          descriptionIntl: IntlContent;
          category: string;
          assignee?: string;
          address: string;
          location: LatLng;
          radius: number;
          displayB2B: boolean;
          displayB2C: boolean;
          withSections: boolean;
          billableAccount: boolean;
        }
      >({
        query: data => ({
          url: `/createBusinessB2BWeb`,
          method: 'post',
          data,
        }),
        invalidatesTags: invalidatesTagsPostCreation('Business'),
      }),
      deleteBusiness: build.mutation({
        query: ({id, deleteBy, status}) => ({
          url: `/deleteBusiness/${id}`,
          method: 'delete',
          data: {deleteBy, status},
        }),
        invalidatesTags: invalidateTagsUsingParamId('Business'),
      }),
      deactivateBusiness: build.mutation<Business, string>({
        query: id => ({
          url: `/deactivateBusiness/${id}`,
          method: 'delete',
        }),
        invalidatesTags: invalidateTagsUsingParamId('Business'),
      }),
      reactivateBusiness: build.mutation<Business, string>({
        query: id => ({
          url: `/reactivateBusiness/${id}`,
          method: 'patch',
        }),
        invalidatesTags: invalidateTagsUsingParamId('Business'),
      }),
      updateBusinessContact: build.mutation<
        any,
        {
          id: string;
          email: string;
          phoneNumber: string;
          country: string;
          ext: string;
        }
      >({
        query: ({id, ...data}) => ({
          url: `/updateBusinessContact/${id}`,
          method: 'put',
          data,
        }),
        invalidatesTags: invalidateTagsUsingParamId('Business'),
      }),
      getBusinessContact: build.query<
        {email: string; phoneNumber: string; country: string; ext: string},
        string
      >({
        query: id => ({
          url: `/getBusinessContact/${id}`,
          method: 'get',
        }),
        keepUnusedDataFor: 0,
      }),
      transferBusinessOwnership: build.mutation<
        'OK',
        {
          business: string;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string;
          owner: string;
        }
      >({
        query: ({
          business,
          firstName,
          lastName,
          email,
          phoneNumber,
          owner,
        }) => ({
          url: `/transferBusinessOwnership/${business}`,
          method: 'post',
          data: {
            firstName,
            lastName,
            email,
            phoneNumber,
            owner,
          },
        }),
        invalidatesTags: invalidateTagsUsingParamId('Business'),
      }),
      transferDisplayOnlyBusiness: build.mutation<
        'OK',
        {
          business: string;
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string;
          owner: string;
        }
      >({
        query: ({
          business,
          firstName,
          lastName,
          email,
          phoneNumber,
          owner,
        }) => ({
          url: `/transferDisplayOnlyBusiness/${business}`,
          method: 'post',
          data: {
            firstName,
            lastName,
            email,
            phoneNumber,
            owner,
          },
        }),
        invalidatesTags: invalidateTagsUsingParamId('Business'),
      }),
      getSiteTemplatesBusinesses: build.query<PopulatedBusiness[], string>({
        query: (id: string) => ({
          url: `/getSiteTemplatesBusinesses/${id}`,
          method: 'get',
        }),
        providesTags: providesTagsArray('Business'),
      }),
      saveBusinessAsSiteTemplate: build.mutation<
        PopulatedBusiness,
        {id: string;}
      >({
        query: ({id}) => ({
          url: `/saveAsSiteTemplatesBusiness/${id}`,
          method: 'patch',
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          ({id}: any) => id,
        ),
      }),
      removeBusinessAsSiteTemplate: build.mutation<PopulatedBusiness, string>({
        query: id => ({
          url: `/removeBusinessAsSiteTemplate/${id}`,
          method: 'delete',
        }),
        invalidatesTags: invalidatesTagsUsingParamId(
          'Business',
          (id: any) => id,
        ),
      }),
    };
  },
});

export const {
  useGetBusinessesQuery,
  useGetAllBusinessCategoriesQuery,
  useGetCanViewBusinessAssigneeObjectsQuery,
  useGetBusinessStatsQuery,
  useCreateBusinessMutation,
  useDeleteBusinessMutation,
  useDeactivateBusinessMutation,
  useReactivateBusinessMutation,
  useUpdateBusinessMutation,
  useUpdateBusinessAssigneeMutation,
  useGetBusinessByIdQuery,
  useLazyGetBusinessQRCodePDFQuery,
  useCreateBusinessPendingMutation,
  useCreateBusinessReferenceMutation,
  useCreateBusinessB2BWebMutation,
  useUpdatePendingBusinessMutation,
  useUpdateDisplayOrB2BWebBusinessMutation,
  useUpdateBusinessImageMutation,
  useUpdateBusinessContactMutation,
  useGetBusinessContactQuery,
  useTransferBusinessOwnershipMutation,
  useTransferDisplayOnlyBusinessMutation,
  useSaveBusinessAsSiteTemplateMutation,
  useGetSiteTemplatesBusinessesQuery,
  useRemoveBusinessAsSiteTemplateMutation,
} = customBusinessApi;
