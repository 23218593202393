import {Box, CircularProgress} from '@mui/material';

const LoadingOverlay = ({
  transparent = false,
  size = undefined,
}: {
  transparent?: boolean;
  size?: number;
}) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      inset: 0,
      backgroundColor: transparent ? 'rgba(0, 0, 0, .15)' : 'white',
    }}>
    <CircularProgress size={size} />
  </Box>
);

export default LoadingOverlay;
