import React from 'react';
import {Link as RouterLink, Outlet} from 'react-router-dom';

import {Group, People, Settings, Store} from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';

import {useGetAuthUserQuery} from '../../redux/authApi';

const MenuIcon: React.FC<{
  Icon: any;
  text: string;
}> = ({Icon, text}) => (
  <IconButton color="inherit" sx={{flexDirection: 'column'}}>
    <Icon fontSize="medium" />
    <Typography variant="body2">{text}</Typography>
  </IconButton>
);

const MenuLink = ({to, children}: {to: string; children: React.ReactNode}) => (
  <Link
    sx={{
      color: '#fff',
      '&, &:active': {
        textDecoration: 'none',
      },
    }}
    component={RouterLink}
    to={to}>
    {children}
  </Link>
);

const UserPageContainer = () => {
  const query = useGetAuthUserQuery();

  const isNotSales = query.isSuccess && query.data?.role !== 'SALES';
  return (
    <>
      <Box>
        <Outlet />
      </Box>
      <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0}}>
        <Toolbar sx={{justifyContent: 'space-around', flex: 1}}>
          {isNotSales && (
            <MenuLink to="/superusers">
              <MenuIcon Icon={People} text="Supusers" />
            </MenuLink>
          )}
          <MenuLink to="/groups">
            <MenuIcon Icon={Group} text="Groupes" />
          </MenuLink>
          <MenuLink to="/businesses">
            <MenuIcon Icon={Store} text="Commerces" />
          </MenuLink>
          <MenuLink to="/settings">
            <MenuIcon Icon={Settings} text="Paramètres" />
          </MenuLink>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default UserPageContainer;
