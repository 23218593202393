import React from 'react';
import {useNavigate} from 'react-router-dom';

import {FormControl, FormControlLabel} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import EmptyList from '../../components/molecules/EmptyList';
import GroupCard from '../../components/molecules/GroupCard';
import PageList from '../../components/organisms/PageList';

import {useGetAllGroupsQuery} from '../../redux/groupsApi';

const GroupsList = () => {
  const navigate = useNavigate();

  const [activeOnly, setActiveOnly]: any = React.useState('');
  return (
    <PageList
      columns={2}
      pageTitle="Groupes"
      useQuery={useGetAllGroupsQuery}
      renderItem={(group: any) => (
        <GroupCard
          key={group._id}
          group={group}
          onClick={() => navigate(`/actions/group-details/${group._id}`)}
        />
      )}
      paginationParams={{
        limit: '$limit',
        skip: '$skip',
      }}
      extraQueryParams={{
        activeOnly,
      }}
      emptyList={<EmptyList message="Aucun groupe a été crée" />}
      header={
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="filter-radio-buttons-group-label"
            name="filter"
            value={activeOnly}
            onChange={e => setActiveOnly(e.target.value)}>
            <FormControlLabel value="" control={<Radio />} label="Tous" />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="A valider"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Validés"
            />
          </RadioGroup>
        </FormControl>
      }
    />
  );
};

export default GroupsList;
