import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useParams} from 'react-router-dom';

import {Grid, Stack, Typography} from '@mui/material';

import DashboardFilter, {
  THIS_MONTH,
} from '../../components/organisms/DashboardFilter';
import UserPage from '../../components/organisms/UserPage';

import {
  useGetBusinessByIdQuery,
  useGetBusinessStatsQuery,
} from '../../redux/businessesApi';

const Card = ({title, value}: {title: string; value: string | number}) => (
  <Stack
    spacing={2}
    pt={2}
    pb={2}
    sx={{
      backgroundColor: theme => theme.palette.common.white,
    }}>
    <Typography variant="h6" align="center">
      {title}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        fontSize: 48,
        fontWeight: 'bold',
      }}
      align="center">
      {value}
    </Typography>
  </Stack>
);

const BusinessDashboard = () => {
  const {id} = useParams<{
    id: string;
  }>();
  const [query, setQueryParams] = React.useState<any>({
    filterType: THIS_MONTH,
  });
  const businessQuery = useGetBusinessByIdQuery(id as string);
  const business = businessQuery.data;
  const owner = business?.owner as User;

  const businessStatsQuery = useGetBusinessStatsQuery(
    {
      id: id as string,
      ...query,
      date1: query.date1 ? query.date1.getTime() : undefined,
      date2: query.date2 ? query.date2.getTime() : undefined,
      owner: owner?._id,
    },
    {skip: businessQuery.isFetching},
  );
  const businessStats = businessStatsQuery.data;

  return (
    <HelmetProvider>
      <UserPage>
        <Helmet>
          <title>Commerce - {business?.name || ''} Tableau de bord</title>
        </Helmet>
        <Stack direction="column" spacing={3}>
          <Typography variant="h4">Tableau de bord</Typography>
          <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
            <DashboardFilter setQueryParams={setQueryParams} />
          </Stack>
          <Stack
            p={2}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              color: theme =>
                theme.palette.getContrastText(theme.palette.primary.main),
              position: 'sticky',
              top: 0,
            }}>
            <Typography variant="h6">{business?.name}</Typography>
            <Typography variant="body1">
              Propriétaire {owner?.firstName} {owner?.lastName}
            </Typography>
          </Stack>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  title="Chiffre d'affaires minimal estimé"
                  value={`${(businessStats?.estimatedTurnover || 0).toFixed(
                    2,
                  )} €`}
                />
              </Grid>
              <Grid item xs={12}>
                <Card title="Utilisateurs" value={businessStats?.users || 0} />
              </Grid>
              <Grid item xs={12}>
                <Card
                  title="Type de coupons créés"
                  value={businessStats?.couponsTypes || 0}
                />
              </Grid>
              <Grid item xs={12}>
                <Card
                  title="Coupons validés"
                  value={businessStats?.usedCoupons || 0}
                />
              </Grid>
              <Grid item xs={12}>
                <Card
                  title="Coupons distribués"
                  value={businessStats?.coupons || 0}
                />
              </Grid>
              <Grid item xs={12}>
                <Card
                  title="Coupons non utilisés"
                  value={
                    businessStats
                      ? businessStats?.coupons - businessStats?.usedCoupons
                      : 0
                  }
                />
              </Grid>
              <Grid item xs={12} marginBottom={10}>
                <Card
                  title="Coût d'utilisation"
                  value={`${(businessStats?.spent || 0).toFixed(2)} €`}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </UserPage>
    </HelmetProvider>
  );
};

export default BusinessDashboard;
