export const supportedLanguagesMap = {
  FR: 'Français',
  EN: 'English',
  ES: 'Spanish',
  DE: 'Deutsh',
} as const;

export type LanguageCode = keyof typeof supportedLanguagesMap;

export const supportedLanguages = Object.keys(supportedLanguagesMap) as Array<keyof typeof supportedLanguagesMap>;
