import EmptyList from '../../components/molecules/EmptyList';
import PageList from '../../components/organisms/PageList';

import {useGetCampaignsQuery} from '../../redux/campaignsApi';

import CampaignCard from './CampaignsCard';

const CampaignsList = () => {
  return (
    <PageList
      addButtonVisible
      addLink="/actions/campaigns/create"
      pageTitle="Campagnes"
      useQuery={useGetCampaignsQuery}
      emptyList={
        <EmptyList message="Aucune campagne ne correspond à votre recherche" />
      }
      extraQueryParams={{}}
      renderItem={(campaign: any) => (
        <CampaignCard key={campaign._id} campaign={campaign} />
      )}
    />
  );
};

export default CampaignsList;
