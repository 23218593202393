import React from 'react';

import {useDebounce} from 'use-debounce';

import EmptyList from '../../components/molecules/EmptyList';

import {useGetBusinessesQuery} from '../../redux/businessesApi';

import DataList from '../../components/organisms/DataList';
import SearchBarWithFilterButton from '../../components/organisms/SearchBarWithFilterButton';
import BusinessCard from './BusinessCard';
import BusinessFilterDialog from './BusinessFilterDialog';
import BusinessFilterPage from './BusinessFilterPage';
import {Filter} from './types';

const BusinessesList = () => {
  const [search, setSearch] = React.useState('');
  const [formState, setFormState] = React.useState<Filter>({
    filter: 'ALL',
    sortField: 'createdAt' as const,
    sortOrder: -1,
    withSections: false,
    siteTemplatesOnly: false,
    status: 'ALL' as const,
    owner: '',
  });
  const [debouncedSearch] = useDebounce(search, 500);
  const [visible, setVisible] = React.useState(false);

  const [queryParams, setQueryParams] = React.useState({
    sortField: 'createdAt',
    sortOrder: -1,
    userOnly: false,
    withSections: false,
    siteTemplatesOnly: false,
    status: 'ALL',
    owner: '',
  });

  const handleSubmit = ({
    filter,
    sortField,
    sortOrder,
    withSections,
    siteTemplatesOnly,
    status,
    owner,
  }: Filter) => {
    setQueryParams({
      userOnly: filter === 'USER_ONLY',
      sortField,
      sortOrder,
      withSections,
      siteTemplatesOnly,
      status,
      owner,
    });
    setFormState({
      filter,
      sortField,
      sortOrder,
      withSections,
      siteTemplatesOnly,
      status,
      owner,
    });
    setVisible(false);
  };

  return (
    <>
      <SearchBarWithFilterButton
        onSearchChange={setSearch}
        onOpenFilter={() => setVisible(true)}
      />
      <BusinessFilterPage filter={formState} />
      <BusinessFilterDialog
        search={search}
        formState={formState}
        onSubmit={handleSubmit}
        onClose={() => setVisible(false)}
        visible={visible}
      />
      <DataList
        addButtonVisible
        addLink="/actions/businesses/create-intro"
        useQuery={useGetBusinessesQuery}
        emptyList={
          <EmptyList message="Aucun commerce ne correspond à votre recherche" />
        }
        extraQueryParams={{
          ...queryParams,
          search: debouncedSearch,
        }}
        renderItem={(business: any) => (
          <BusinessCard key={business._id} business={business} />
        )}
      />
    </>
  );
};

export default BusinessesList;
