import React from 'react';

import {Map} from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  Modal,
  Stack,
  Typography,
} from '@mui/material';

import {useField} from 'formik';

import GeoAddressPicker from './GeoAddressPicker';

const FormikGeoLocationPicker = ({
  locationName,
  addressName,
  fullAddress = true,
}: {
  locationName: string;
  addressName: string;
  fullAddress?: boolean;
}) => {
  const [locationField, locationMeta, locationHelpers] = useField(locationName);
  const [addressField, addressMeta, addressHelpers] = useField(addressName);
  const [open, setOpen] = React.useState(false);

  const addressInvalid = addressMeta.touched && addressMeta.error;
  const locationInvalid = locationMeta.touched && locationMeta.error;

  return (
    <Box>
      <Stack spacing={1} direction="column">
        {addressField.value && (
          <Stack pt={2} direction="column" spacing={2}>
            <Typography variant="body1">Votre addresse</Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#000',
              }}>
              {addressField.value}
            </Typography>
          </Stack>
        )}
        <Button variant="text" endIcon={<Map />} onClick={() => setOpen(true)}>
          {addressField.value ? "Modifier l'adresse" : "Choisir l'adresse"}
        </Button>
        {(addressInvalid || locationInvalid) && (
          <FormHelperText error>
            {(addressMeta.error || locationMeta.error) as string}
          </FormHelperText>
        )}
      </Stack>
      <Modal open={open}>
        <GeoAddressPicker
          onClose={() => setOpen(false)}
          location={locationField.value}
          address={addressField.value}
          onSave={(location, address) => {
            locationHelpers.setValue(location);
            addressHelpers.setValue(address);
            setOpen(false);
          }}
          fullAddress={fullAddress}
        />
      </Modal>
    </Box>
  );
};

export default FormikGeoLocationPicker;
