import React, {useState} from 'react';

import {Box, TextField, TextFieldProps} from '@mui/material';

import {useField} from 'formik';
import {supportedLanguages} from '../../../lib/intl';
import LanguagesTabs from "../LanguagesTabs";

const FormikTextField = ({
  name,
  nameIntl = name + 'Intl',
  enableIntl = false,
  ...props
}: {
  name: string;
  nameIntl?: string;
  enableIntl?: boolean;
} & TextFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const [fieldIntl, metaIntl, helpersIntl] = useField(nameIntl);
  const hasError = enableIntl
    ? !!(metaIntl.error && metaIntl.touched)
    : !!(meta.error && meta.touched);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    supportedLanguages[0],
  );

  if (!enableIntl) {
    return (
      <TextField
        {...props}
        value={field.value}
        onChange={(e) => {
          helpers.setValue(e.target.value);
        }}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        error={hasError}
        helperText={
          meta.touched && meta.error ? (meta.error as string) : undefined
        }
      />
    );
  }

  return (
    <Box>
      <LanguagesTabs
        onLangChange={setSelectedLanguage}
        selectedLang={selectedLanguage}
      />
      <TextField
        {...props}
        fullWidth
        value={'' + (fieldIntl.value?.[selectedLanguage] ?? '')}
        onChange={(event) => {
          const {value} = event.target;

          if (enableIntl) {
            helpersIntl.setValue({
              ...fieldIntl.value,
              [selectedLanguage]: value,
            });
          } else {
            helpers.setValue(value);
          }
        }}
        onBlur={() => {
          if (enableIntl) {
            helpersIntl.setTouched(true);
          } else {
            helpers.setTouched(true);
          }
        }}
        error={!!(metaIntl.touched && metaIntl.error)}
        helperText={
          metaIntl.touched && metaIntl.error
            ? (metaIntl.error as string)
            : undefined
        }
        {...props}
      />
    </Box>
  );
};

export default FormikTextField;
