import React from 'react';

import {FormLabel, Slider, SliderProps, Stack, Typography} from '@mui/material';

import {Field, FieldProps} from 'formik';

const FormikSlider: React.FC<
  {
    name: string;
    label: string;
    renderValue?: (val: string) => string;
  } & SliderProps
> = ({name, label, renderValue = val => val, ...props}) => {
  return (
    <Stack spacing={1}>
      <FormLabel>{label}</FormLabel>
      <Field name={name}>
        {({field}: FieldProps) => (
          <>
            <Slider {...field} {...props} />
            <Typography variant="body1">{renderValue(field.value)}</Typography>
          </>
        )}
      </Field>
    </Stack>
  );
};

export default FormikSlider;
