import store from '../../app/store';
import authApi from '../authApi';
import businessCategoriesApi from '../businessCategoriesApi';
import {customBusinessApi} from '../businessesApi';
import businessReferralsApi, {
  customBusinessReferralsApi,
} from '../businessReferralsApi';
import {autocompleteApi, geocodingApi} from '../geocodingApi';
import groupsApi from '../groupsApi';
import runScriptApi from '../runScriptApi';
import superusersApi from '../superusersApi';

export const clearRTKApiCache = () => {
  console.log('clear RTK API Cache');
  store.dispatch(superusersApi.util.resetApiState());
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(customBusinessApi.util.resetApiState());
  store.dispatch(geocodingApi.util.resetApiState());
  store.dispatch(autocompleteApi.util.resetApiState());
  store.dispatch(runScriptApi.util.resetApiState());
  store.dispatch(businessReferralsApi.util.resetApiState());
  store.dispatch(businessReferralsApi.util.resetApiState());
  store.dispatch(customBusinessReferralsApi.util.resetApiState());
  store.dispatch(businessCategoriesApi.util.resetApiState());
  store.dispatch(groupsApi.util.resetApiState());
};
