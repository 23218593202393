import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {FormControlLabel, Stack, Typography} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import FormikPhoneNumberInput from '../../components/atoms/formik/FormikPhoneNumberInput';
import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/FormikBusinessCategoryPicker';
import SalesPicker from '../../components/molecules/SalesPicker';
import PageAction from '../../components/organisms/PageAction';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import {useGetAuthUserQuery} from '../../redux/authApi';
import {useCreateBusinessPendingMutation} from '../../redux/businessesApi';

const objectSchema = {
  image: yup.string().required('Champs requis'),
  name: yup.string().required('Champs requis'),
  email: yup.string().required('Champs requis').email('Email invalide'),
  phoneNumber: yup.string().required('Champ obligatoire'),
  ext: yup.string().required('Champ obligatoire'),
  country: yup.string().required('Champ obligatoire'),
  category: yup.string().required('Champs requis'),
  address: yup.string().required('Champs requis'),
  location: yup
    .object()
    .locationRequired('Entrez ou réssayer une autre adresse'),
};

const validationSchema = yup.object(objectSchema);

const rootValidationSchema = yup.object({
  assignee: yup.string().required('Champs obligatoire'),
  ...objectSchema,
});

const CreateBusinessPending = () => {
  const navigate = useNavigate();
  const uploadFile = useUploadFile();
  const [createBusiness, mutation] = useCreateBusinessPendingMutation();
  const query = useGetAuthUserQuery();
  const user = query.data;
  const [loading, setLoading] = React.useState(false);

  const handleCreateBusiness = async ({
    image,
    email,
    name,
    ext,
    country,
    phoneNumber,
    category,
    address,
    assignee,
                                        descriptionIntl,
    location,
    displayB2B,
    displayB2C,
    qrCodeQuantity,
    withSections,
  }: any) => {
    try {
      setLoading(true);
      const file = await uploadFile(image);
      await createBusiness({
        email,
        name,
        category,
        ext,
        country,
        phoneNumber,
        address,
        assignee,
        descriptionIntl,
        location,
        image: file._id,
        displayB2B,
        displayB2C,
        qrCodeQuantity,
        withSections,
      }).unwrap();
      NotificationManager.success('Commerce créé avec succès');
      navigate(-1);
    } catch (err: any) {
      if (err?.data?.error?.code === 'EMAIL_ALREADY_TAKEN') {
        NotificationManager.error('Email déjà utilisé');
      } else if (err?.data?.error?.code === 'PHONE_NUMBER_ALREADY_TAKEN') {
        NotificationManager.error('Numéro de téléphone déjà utilisé');
      } else {
        internalError(mutation.error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title="Créer un nouveau commerce">
      <Formik
        validationSchema={
          user?.role === 'ROOT' ? rootValidationSchema : validationSchema
        }
        onSubmit={({
          email,
          ext,
          country,
          phoneNumber,
          image,
          name,
          assignee,
                     descriptionIntl,
          address,
          location,
          category,
          qrCodeQuantity,
          displayB2B,
          displayB2C,
          withSections,
        }: any) =>
          handleCreateBusiness({
            email,
            ext,
            country,
            phoneNumber,
            image,
            name,
            category,
            address,
            assignee,
            descriptionIntl,
            location,
            displayB2B,
            displayB2C,
            withSections,
            qrCodeQuantity,
          })
        }
        initialValues={{
          image: '',
          name: '',
          email: '',
          phoneNumber: '',
          ext: '',
          country: 'FR',
          descriptionIntl: {},
          category: '',
          assignee: '',
          address: '',
          location: '',
          displayB2B: false,
          displayB2C: false,
          withSections: false,
          qrCodeQuantity: 1,
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Informations générales</Typography>
              <FormikFileInput name="image" maxSize={5} />
              <FormikTextField name="name" label="Nom du commerce" />
              <FormikTextField name="email" label="Email de contact" />
              <FormikGeoLocationPicker
                locationName="location"
                addressName="address"
              />
              <FormikPhoneNumberInput
                name="phoneNumber"
                label="Tel"
                countryName="country"
                extName="ext"
              />
              <FormikTextField
                name="description"
                label="Description"
                enableIntl
                multiline
                rows={4}
              />
              <FormikBusinessCategoryPicker label="Catégorie" name="category" />
              <FormikTextField
                name="qrCodeQuantity"
                label="Nombre de QR code affichés"
                type="number"
              />
            </Stack>
            <Stack spacing={2} direction="column">
              <Typography variant="h6">Paramètres</Typography>
              {user?.role && user?.role !== 'SALES' && (
                <SalesPicker
                  name="assignee"
                  label={
                    user?.role === 'ROOT'
                      ? 'Agent commercial'
                      : 'Agent commercial (optionnel)'
                  }
                />
              )}
              <FormControlLabel
                control={<FormikSingleChoice name="displayB2B" />}
                label="Afficher sur B2B"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="displayB2C" />}
                label="Afficher sur B2C"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="withSections" />}
                label="Activer les rubriques"
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={loading ? undefined : () => handleSubmit()}
                loading={loading}
                variant="contained"
                color="primary">
                Terminer
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CreateBusinessPending;
