import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {useGetUsersOwnersAccountsQuery} from '../../redux/usersApi';

import PeoplePicker from './PeoplePicker';

const OwnersPicker = ({name, label}: {name: string; label: string}) => {
  const query = useGetUsersOwnersAccountsQuery();

  useQueryInternalError(query);

  return (
    <PeoplePicker
      name={name}
      label={label}
      data={query.data}
      loading={query.isLoading}
    />
  );
};

export default OwnersPicker;
