import React from 'react';

import {useDebounce} from 'use-debounce';

import EmptyList from '../../components/molecules/EmptyList';
import PageList from '../../components/organisms/PageList';

import {useGetSuperusersQuery} from '../../redux/superusersApi';

import SuperuserCard from './SuperuserCard';
import SuperusersListHeader from './SuperusersListHeader';

const SuperUsersList = () => {
  const [search, setSearch] = React.useState('');
  const [role, setRole] = React.useState('');
  const [debounceSearch] = useDebounce(search, 200);

  return (
    <PageList<SuperUser>
      pageTitle="Super utilisateurs"
      useQuery={useGetSuperusersQuery}
      renderItem={superuser => (
        <SuperuserCard key={superuser._id} superuser={superuser} />
      )}
      header={
        <SuperusersListHeader
          search={search}
          onSearchChange={setSearch}
          role={role}
          onRoleChanged={val => setRole(val)}
        />
      }
      emptyList={<EmptyList message="Aucun superutilisateur trouvé" />}
      addLink="/actions/superusers/create"
      addButtonVisible
      extraQueryParams={{
        search: debounceSearch,
        role,
      }}
    />
  );
};

export default SuperUsersList;
