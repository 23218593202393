import React from 'react';
import {NotificationManager} from 'react-notifications';

import {Box, Button, Stack, Typography} from '@mui/material';

const FileInput = ({
  maxSize,
  value,
  onFileSelect,
  onFileRemove,
}: {
  value?: File;
  onFileSelect: (file: File) => void;
  onFileRemove: () => void;
  maxSize?: number;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    if (file) {
      if (maxSize && file.size > maxSize * 1024 * 1024) {
        NotificationManager.error(
          `La taille du fichier ne doit pas dépasser ${maxSize} megabytes`,
        );
        return;
      }

      onFileSelect(file);
    }
  };

  return (
    <Stack spacing={1}>
      <Stack sx={{alignItems: 'center', width: '100%', position: 'relative'}}>
        <input
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          type="file"
          onChange={handleChange}
        />
        {!value && (
          <Box p={2} sx={{border: '1px solid #aaa', width: '100%'}}>
            <Typography align="center">Télécharger une image ...</Typography>
          </Box>
        )}
        {value && (
          <img
            src={URL.createObjectURL(value)}
            alt="preview"
            style={{width: 100, height: '100%'}}
          />
        )}
      </Stack>
      {value && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onFileRemove()}>
          Supprimer l'image
        </Button>
      )}
    </Stack>
  );
};

export default FileInput;
