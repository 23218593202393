import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';

import {
  Campaign,
  Dashboard,
  Email,
  FileDownload,
  Info,
  Lock,
  Logout,
  Store,
  Warning,
  Tune,
} from '@mui/icons-material';

import {
  Breadcrumbs,
  Button,
  ButtonProps,
  Chip,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import renderAdminRole from '../../lib/helpers/getAdminRole';
import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import UserPage from '../../components/organisms/UserPage';

import {useGetAuthUserQuery} from '../../redux/authApi';
import {useGetSuperuserByIdQuery} from '../../redux/superusersApi';

type SettingsButtonProps = {to: string} & ButtonProps;

const SettingsButton = ({to, ...props}: SettingsButtonProps) => (
  <Link
    sx={{
      '&, &:active': {
        textDecoration: 'none',
        color: 'inherit',
      },
    }}
    component={RouterLink}
    to={to}>
    <Button
      sx={{
        justifyContent: 'flex-start',
        color: 'inherit',
        textTransform: 'none',
        '.MuiButton-startIcon': {
          marginRight: theme => theme.spacing(2),
        },
      }}
      {...props}
    />
  </Link>
);

const Settings = () => {
  const {data: authUser} = useGetAuthUserQuery();

  const {data: supervisor} = useGetSuperuserByIdQuery(
    authUser?.parent as string,
    {
      skip: !authUser || !authUser?.parent,
    },
  );

  const userIsRoot = useUserIsRoot();

  return (
    <HelmetProvider>
      <UserPage
        breadcrumbs={
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">Paramètres</Typography>
          </Breadcrumbs>
        }>
        <Helmet>
          <title>Paramètres</title>
        </Helmet>
        <Stack py={2} spacing={2}>
          <Stack direction="column" spacing={2} alignItems="center" py={2}>
            <Typography align="center" variant="h6">
              Bienvenue {authUser?.firstName} {authUser?.lastName}
            </Typography>
            <Chip color="primary" label={renderAdminRole(authUser?.role)} />
            <Typography align="center" variant="body1">
              Code de parrainage: <b>{authUser?.referralCode.toUpperCase()}</b>
            </Typography>
            {supervisor && (
              <Stack p={2} spacing={1} sx={{border: '1px solid #aaa'}}>
                <Typography align="center" variant="body1">
                  Votre responsable est {supervisor.firstName}{' '}
                  {supervisor.lastName}
                </Typography>
                <Typography variant="body2" align="center">
                  {renderAdminRole(supervisor.role)}
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={1}
                  alignItems="center">
                  <Email />
                  <Typography align="center" variant="body2">
                    <b>{supervisor.email}</b>
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack spacing={1}>
            <Divider />
            <SettingsButton
              to={`/superusers/${authUser?._id}/dashboard`}
              startIcon={<Dashboard />}
              variant="text">
              Tableau de board
            </SettingsButton>
            <Divider />
            {userIsRoot && (
              <>
                <SettingsButton
                  to="/export-contacts"
                  startIcon={<FileDownload />}
                  variant="text">
                  Exporter les contacts
                </SettingsButton>
                <Divider />
                <SettingsButton
                  to="/actions/run-script"
                  startIcon={<Warning />}
                  variant="text">
                  Exécuter des scripts
                </SettingsButton>
                <Divider />
                <SettingsButton
                  to="/business-referrals"
                  startIcon={<Store />}
                  variant="text">
                  Partenaires invités
                </SettingsButton>
                <Divider />

                <SettingsButton
                  to="/campaigns"
                  startIcon={<Campaign />}
                  variant="text">
                  Annonces
                </SettingsButton>
                <Divider />

                {userIsRoot && (
                  <>
                    <SettingsButton
                      to="/business-categories"
                      startIcon={<Store />}
                      variant="text">
                      Catégories de commerce
                    </SettingsButton>
                    <Divider />
                    <SettingsButton
                      to="/actions/preferences"
                      startIcon={<Tune />}
                      variant="text">
                      Préférences
                    </SettingsButton>
                    <Divider />
                  </>
                )}
              </>
            )}
            <SettingsButton
              to="/actions/profile/edit"
              startIcon={<Info />}
              variant="text">
              Informations de mon compte
            </SettingsButton>
            <Divider />
            <SettingsButton
              to="/actions/password/edit"
              startIcon={<Lock />}
              variant="text">
              Mot de passe
            </SettingsButton>
            <Divider />
            <SettingsButton to="/logout" startIcon={<Logout />} variant="text">
              Déconnexion
            </SettingsButton>
          </Stack>
        </Stack>
      </UserPage>
    </HelmetProvider>
  );
};

export default Settings;
