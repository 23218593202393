import FormikSelect from '../atoms/formik/FormikSelect';

import {MenuItem} from '@mui/material';

import {useGetSuperusersQuery} from '../../redux/superusersApi';

const SuperuserPicker = ({name, label}: {name: string; label: string}) => {
  const query = useGetSuperusersQuery({
    $skip: 0,
    $limit: 1000,
  });

  return (
    <FormikSelect name={name} label={label}>
      {query.data?.data?.map(superuser => (
        <MenuItem key={superuser._id} value={superuser._id}>
          {superuser.firstName} {superuser.lastName}
        </MenuItem>
      ))}
    </FormikSelect>
  );
};

export default SuperuserPicker;
