import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useParams} from 'react-router-dom';

import {Grid, LinearProgress, Stack, Typography} from '@mui/material';

import renderAdminRole from '../../lib/helpers/getAdminRole';

import DashboardFilter, {
  THIS_MONTH,
} from '../../components/organisms/DashboardFilter';
import UserPage from '../../components/organisms/UserPage';

import {
  useGetSuperuserByIdQuery,
  useGetSuperuserDashboardQuery,
} from '../../redux/superusersApi';

const Card = ({title, value}: {title: string; value: string | number}) => (
  <Stack
    spacing={2}
    pt={2}
    pb={2}
    sx={{
      backgroundColor: theme => theme.palette.common.white,
    }}>
    <Typography variant="h6" align="center">
      {title}
    </Typography>
    <Typography
      variant="body1"
      sx={{
        fontSize: 48,
        fontWeight: 'bold',
      }}
      align="center">
      {value}
    </Typography>
  </Stack>
);

const SuperuserDashboard = () => {
  const {id} = useParams<{
    id: string;
  }>();
  const [query, setQueryParams] = React.useState<any>({
    filterType: THIS_MONTH,
  });
  const superuserQuery = useGetSuperuserByIdQuery(id as string);
  const superuserStatsQuery = useGetSuperuserDashboardQuery({
    id: id as string,
    ...query,
    date1: query.date1 ? query.date1.getTime() : undefined,
    date2: query.date2 ? query.date2.getTime() : undefined,
  });

  const stats = superuserStatsQuery.data;

  const user = superuserQuery.data;

  return (
    <HelmetProvider>
      <UserPage>
        <Helmet>
          <title>
            {renderAdminRole(user?.role as string)} - {user?.firstName || ''}{' '}
            {user?.lastName || ''}
          </title>
        </Helmet>
        <Stack direction="column" spacing={3}>
          <Typography variant="h4">Tableau de bord</Typography>
          <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
            <DashboardFilter setQueryParams={setQueryParams} />
          </Stack>
          <Stack
            p={2}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              color: theme =>
                theme.palette.getContrastText(theme.palette.primary.main),
              position: 'sticky',
              top: 0,
            }}>
            <Typography variant="h6">
              {user?.firstName || ''} {user?.lastName || ''}
            </Typography>
          </Stack>
          <div>
            {superuserStatsQuery.isLoading && <LinearProgress />}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  title="Chiffre d'affaires minimal estimé"
                  value={`${(stats?.estimatedTurnover || 0).toFixed(2)} €`}
                />
              </Grid>
              <Grid item xs={12}>
                <Card title="Utilisateurs" value={stats?.users || 0} />
              </Grid>
              <Grid item xs={12}>
                <Card
                  title="Type de Coupons créés"
                  value={stats?.couponsTypes || 0}
                />
              </Grid>
              <Grid item xs={12}>
                <Card title="Coupons validés" value={stats?.usedCoupons || 0} />
              </Grid>
              <Grid item xs={12}>
                <Card title="Coupons distribués" value={stats?.coupons || 0} />
              </Grid>
              <Grid item xs={12} marginBottom={10}>
                <Card
                  title="Coupons non utilisés"
                  value={stats ? stats.coupons - stats.usedCoupons : 0}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </UserPage>
    </HelmetProvider>
  );
};

export default SuperuserDashboard;
