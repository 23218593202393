import {Link as RouterLink, LinkProps} from 'react-router-dom';

import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';

const Link = (props: MuiLinkProps & LinkProps) => (
  <MuiLink
    sx={{
      color: 'inherit',
      display: 'flex',
      '&, &:active': {
        textDecoration: 'none',
      },
    }}
    component={RouterLink}
    {...props}
  />
);

export default Link;
