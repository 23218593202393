import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {FormControlLabel, Stack, Typography} from '@mui/material';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import FormikPhoneNumberInput from '../../components/atoms/formik/FormikPhoneNumberInput';
import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/FormikBusinessCategoryPicker';
import SalesPicker from '../../components/molecules/SalesPicker';
import PageAction from '../../components/organisms/PageAction';

import {useGetAuthUserQuery} from '../../redux/authApi';
import {useCreateBusinessMutation} from '../../redux/businessesApi';
import {BUSINESS_DESCRIPTION_MAX_LENGTH} from "../../app/constants";

const objectSchema = {
  name: yup.string().required('Champs requis'),
  category: yup.string().required('Champs requis'),
  address: yup.string().required('Champs requis'),
  location: yup
    .object()
    .locationRequired('Entrez ou réssayer une autre adresse'),
  firstName: yup.string().required('Champs requis'),
  lastName: yup.string().required('Champs requis'),
  email: yup.string().required('Champs requis').email('Email invalide'),
  phoneNumber: yup.string().required('Champs requis'),
};

const validationSchema = yup.object(objectSchema);

const rootValidationSchema = yup.object({
  ...objectSchema,
  assignee: yup.string().required('Champs requis'),
});

const CreateBusiness = () => {
  const navigate = useNavigate();
  const uploadFile = useUploadFile();
  const [createBusiness] = useCreateBusinessMutation();
  const query = useGetAuthUserQuery();
  const user = query.data;
  const [loading, setLoading] = React.useState(false);

  const isRootOrHeadManager =
    user?.role === 'ROOT' || user?.role === 'HEAD_MANAGER';

  const handleCreateBusiness = async ({
    image,
    name,
    phoneNumber,
    assignee,
    email,
    lastName,
    firstName,
                                        descriptionIntl,
    address,
    location,
    category,
    billableAccount,
    displayB2B,
    displayB2C,
    qrCodeQuantity,
    withSections,
  }: any) => {
    try {
      setLoading(true);
      const file = await uploadFile(image);
      await createBusiness({
        phoneNumber,
        name,
        email,
        lastName,
        firstName,
        category,
        address,
        assignee,
        descriptionIntl,
        location,
        billableAccount,
        image: file._id,
        displayB2B,
        displayB2C,
        qrCodeQuantity,
        withSections,
      }).unwrap();
      NotificationManager.success('Commerce créé avec succès');
      navigate(-1);
    } catch (err: any) {
      if (err?.data?.error?.code === 'EMAIL_ALREADY_TAKEN') {
        NotificationManager.error('Cet addresse email existe déjà');
      } else if (
        err?.data?.error?.code === 'auth/phone-number-already-exists'
      ) {
        NotificationManager.error('Le numéro de téléphone existe déjà');
      } else {
        internalError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title="Créer un nouveau commerce">
      <Formik
        validationSchema={
          user?.role === 'ROOT' ? rootValidationSchema : validationSchema
        }
        onSubmit={({
          image,
          name,
          phoneNumber,
          assignee,
          email,
          lastName,
          firstName,
                     descriptionIntl,
          address,
          location,
          category,
          billableAccount,
          displayB2B,
          displayB2C,
          withSections,
          qrCodeQuantity,
        }: any) =>
          handleCreateBusiness({
            phoneNumber,
            image,
            name,
            email,
            lastName,
            firstName,
            category,
            address,
            assignee,
            descriptionIntl,
            location,
            billableAccount,
            displayB2B,
            displayB2C,
            withSections,
            qrCodeQuantity,
          })
        }
        initialValues={{
          image: '',
          name: '',
          phoneNumber: '',
          country: 'FR',
          ext: '',
          email: '',
          lastName: '',
          firstName: '',
          descriptionIntl: {},
          category: '',
          assignee: '',
          address: '',
          location: '',
          billableAccount: true,
          displayB2B: true,
          displayB2C: true,
          withSections: false,
          qrCodeQuantity: 1,
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Informations générales</Typography>
              <FormikFileInput name="image" maxSize={5} />
              <FormikTextField name="name" label="Nom du commerce" />
              <FormikTextField name="email" label="Email de contact" />
              <FormikTextField
                name="description"
                label="Description"
                multiline
                enableIntl
                rows={4}
                InputProps={{
                  inputProps: {
                    maxlength: BUSINESS_DESCRIPTION_MAX_LENGTH,
                  }
                }}
              />
              <FormikBusinessCategoryPicker label="Catégorie" name="category" />
              <FormikGeoLocationPicker
                locationName="location"
                addressName="address"
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Informations de compte</Typography>
              <FormikPhoneNumberInput
                name="phoneNumber"
                countryName="country"
                extName="ext"
                label="Numéro de téléphone"
              />
              <Stack direction="row" spacing={1}>
                <FormikTextField sx={{flex: 1}} name="lastName" label="Nom" />
                <FormikTextField
                  sx={{flex: 1}}
                  name="firstName"
                  label="Prénom"
                />
              </Stack>
              {isRootOrHeadManager && (
                <FormControlLabel
                  control={<FormikSingleChoice name="billableAccount" />}
                  label="Facturable"
                />
              )}
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Paramètres</Typography>
              <FormControlLabel
                control={<FormikSingleChoice name="displayB2B" />}
                label="Afficher sur B2B"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="displayB2C" />}
                label="Afficher sur B2C"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="withSections" />}
                label="Activer les rubriques"
              />
              {user?.role && user?.role !== 'SALES' && (
                <SalesPicker
                  name="assignee"
                  label={
                    user?.role === 'ROOT'
                      ? 'Agent commercial'
                      : 'Agent commercial (optionnel)'
                  }
                />
              )}
              <FormikTextField
                name="qrCodeQuantity"
                label="Nombre de QR code affichés"
                type="number"
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={loading ? undefined : () => handleSubmit()}
                loading={loading}
                variant="contained"
                color="primary">
                Terminer
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CreateBusiness;
