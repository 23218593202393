import React from 'react';

type Source = (val: {onOpen: () => void}) => JSX.Element;
type Dialog = (val: {open: boolean; onClose: () => void}) => JSX.Element;

const Dialogable = ({source, dialog}: {source: Source; dialog: Dialog}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {source({
        onOpen: () => setOpen(true),
      })}
      {dialog({
        open,
        onClose: () => setOpen(false),
      })}
    </>
  );
};

export default Dialogable;
