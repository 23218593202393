import React from 'react';
import {NotificationManager} from 'react-notifications';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useDeleteGroupMutation} from '../../redux/groupsApi';

const DeleteGroup = ({
  group,
  open,
  onClose,
}: {
  group: Group;
  open: boolean;
  onClose: () => void;
}) => {
  const [deleteGroup, mutation] = useDeleteGroupMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Groupe activé avec succès');
      onClose();
    }
  }, [mutation.isSuccess]);

  useQueryInternalError(mutation);

  return (
    <Confirmation
      isLoading={mutation.isLoading}
      description="Êtes-vous sûr vouloir activé ce groupe?"
      open={open}
      onClose={() => onClose()}
      onConfirm={() => deleteGroup(group._id)}
    />
  );
};

export default DeleteGroup;
