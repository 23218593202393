import {useAppDispatch} from '../../app/hooks';

import React from 'react';
import {NotificationManager} from 'react-notifications';

import {LoadingButton} from '@mui/lab';
import {Box, Divider, Paper, Stack, Typography} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';

import {signin} from '../../redux/auth/thunks';

type FormProps = {
  email: string;
  password: string;
};

const validationSchema = yup.object({
  email: yup.string().required('Champs requis').email('Email incorrect'),
  password: yup
    .string()
    .required('Champs requis')
    .min(6, 'Mot de passe incorrect'),
});

const Signin = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSignin = React.useCallback(async ({email, password}: FormProps) => {
    try {
      setIsLoading(true);

      await dispatch(
        signin({
          email,
          password,
        }),
      ).unwrap();
    } catch (err: any) {
      if (err.code === 'auth/user-disabled') {
        NotificationManager.error('Votre compte a été désactivé');
      } else if (
        err.code === 'auth/wrong-password' ||
        err.code === 'auth/user-not-found'
      ) {
        NotificationManager.error('Email ou mot de passe incorrect');
      } else if (err.code === 'auth/too-many-requests') {
        NotificationManager.error(
          'Ce compte est temporairement inaccessible du aux nombre de tentatives, veuillez réessayer plus târd.',
        );
      } else {
        internalError(err);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}>
      <Paper sx={{width: '85%'}} square>
        <Formik<FormProps>
          validateOnBlur={false}
          validateOnChange
          validationSchema={validationSchema}
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={({email, password}) => onSignin({email, password})}>
          {({handleSubmit}) => (
            <Stack py={4} px={2} spacing={2} direction="column">
              <Stack spacing={1}>
                <Typography variant="h4">Backoffice</Typography>
                <Typography variant="h5">Connexion</Typography>
              </Stack>
              <Divider />
              <Stack spacing={1}>
                <FormikTextField name="email" label="Email" />
                <FormikTextField
                  name="password"
                  type="password"
                  label="Mot de passe"
                />
              </Stack>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="primary"
                onClick={isLoading ? undefined : () => handleSubmit()}>
                Connexion
              </LoadingButton>
            </Stack>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default Signin;
