import {useAppDispatch} from '../../app/hooks';

import React from 'react';

import {logout} from '../../redux/auth/thunks';

const Logout = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(logout());
  }, []);

  return null;
};

export default Logout;
