import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {apiBaseQuery} from './apiBaseQuery';

const campaignAdsApi = createApi({
  reducerPath: 'adsApi',
  baseQuery: apiBaseQuery,
  tagTypes: ['Ad'],
  endpoints(build) {
    return {
      getAds: build.query({
        query: ({
          $limit,
          $skip,
          sortField = 'createdAt',
          sortOrder = -1,
          search = '',
        }) => {
          let url = `/ads?$limit=${$limit}&$skip=${$skip}&$sort[${sortField}]=${sortOrder}`;
          if (search) {
            url = `${url}&search=${search}`;
          }
          return {
            url,
            method: 'get',
          };
        },
        providesTags: ['Ad'],
      }),
      createAd: build.mutation({
        query: data => ({
          url: `/ads`,
          method: 'post',
          data,
        }),
        invalidatesTags: ['Ad'],
      }),
      updateAd: build.mutation({
        query: ({id, data}) => ({
          url: `/ads/${id}`,
          method: 'patch',
          data,
        }),
        invalidatesTags: ['Ad'],
      }),
      deleteAd: build.mutation({
        query: ({id}) => ({
          url: `/ads/${id}`,
          method: 'delete',
        }),
        invalidatesTags: ['Ad'],
      }),
    };
  },
});

export const {
  useGetAdsQuery,
  useCreateAdMutation,
  useUpdateAdMutation,
  useDeleteAdMutation,
} = campaignAdsApi;

export default campaignAdsApi;
