import {Save, Sort} from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';

import Dialogable from '../../components/molecules/Dialogable';

const CategoriesListHeader = ({
  search,
  onSearchChange,
  onQueryParamsChange,
  sort,
  onSortChange,
}: {
  search: string;
  onSearchChange: (val: string) => void;
  onQueryParamsChange: (val: any) => void;
  sort: any;
  onSortChange: (val: any) => void;
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      mb={2}
      sx={{width: '100%'}}
      alignItems="center"
      alignSelf="flex-end">
      <TextField
        variant="outlined"
        label="Recherche"
        value={search}
        sx={{flex: 1}}
        onChange={evt => onSearchChange(evt.target.value)}
      />

      <Dialogable
        source={({onOpen}) => (
          <IconButton size="medium" onClick={onOpen}>
            <Sort fontSize="medium" />
          </IconButton>
        )}
        dialog={({open, onClose}) => (
          <Dialog fullWidth open={open} onClose={onClose}>
            <Stack direction="column" spacing={1} p={2}>
              <Stack
                direction="row"
                alignItems="center"
                width="100%"
                spacing={1}>
                <Typography defaultValue={search} sx={{pr: 2}} variant="body1">
                  Trier
                </Typography>
                <RadioGroup
                  row
                  name="sort"
                  value={sort.order}
                  onChange={evt =>
                    onSortChange({...sort, order: evt.target.value})
                  }>
                  <FormControlLabel value={1} control={<Radio />} label="ASC" />
                  <FormControlLabel
                    value={-1}
                    control={<Radio />}
                    label="DESC"
                  />
                </RadioGroup>
                <Select
                  value={sort.field}
                  sx={{flex: 1}}
                  onChange={evt =>
                    onSortChange({...sort, field: evt.target.value})
                  }>
                  <MenuItem value="lastName">Nom</MenuItem>
                  <MenuItem value="createdAt">Date de creation</MenuItem>
                </Select>
              </Stack>

              <Button
                onClick={() => {
                  onQueryParamsChange({
                    sortField: sort.field,
                    sortOrder: sort.order,
                  });
                  onClose();
                }}
                sx={{alignSelf: 'flex-end'}}
                variant="contained"
                size="medium"
                endIcon={<Save />}>
                Sauvegarder
              </Button>
            </Stack>
          </Dialog>
        )}
      />
    </Stack>
  );
};

export default CategoriesListHeader;
