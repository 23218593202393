import DeactivateBusiness from '../DeactivateBusiness/DeactivateBusiness';
import DeleteBusiness from '../DeleteBusiness/DeleteBusiness';
import DownloadBusinessQRCode from '../DownloadBusinessQRCode/DownloadBusinessQRCode';
import ReactivateBusiness from '../ReactivateBusiness/ReactivateBusiness';

import {
  ContactMail,
  Dashboard,
  Delete,
  Edit,
  Image,
  Lock,
  LockOpen,
  OpenInNew,
  PeopleAlt,
  Person,
  QrCode,
  Save,
} from '@mui/icons-material';
import {ListItemIcon, ListItemText, MenuItem, MenuList} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import Link from '../../components/atoms/Link';
import {useGetAuthUserQuery} from '../../redux/authApi';
import {useGetCanViewBusinessAssigneeObjectsQuery} from '../../redux/businessesApi';
import getBusinessSpaceLink from '../../lib/helpers/getBusinessSpaceLink';
import SaveAsTemplateModal from '../SaveAsTemplateModal/SaveAsTemplateModal';
import RemoveAsTemplateModal from '../RemoveAsTemplateModal/RemoveAsTemplateModal';
import MenuActionItem from '../../components/atoms/MenuActionItem';
import Dialogable from '../../components/molecules/Dialogable'; // import openBusinessInformationB2C from '../../lib/helpers/openBusinessInformationB2C';

const BusinessActions = ({
  business,
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  business: PopulatedBusiness;
}) => {
  const {data: authUser} = useGetAuthUserQuery();
  const userIsRoot = useUserIsRoot();

  const canViewBusinessAssigneeObjectsQuery =
    useGetCanViewBusinessAssigneeObjectsQuery(business._id as string, {
      skip: !open || userIsRoot,
    });

  const canViewBusinessObjects =
    canViewBusinessAssigneeObjectsQuery.data || userIsRoot;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <MenuList>
          {business.status === 'ACTIVE' && canViewBusinessObjects && (
            <Link to={`/business/${business._id}/dashboard`}>
              <MenuItem>
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText>Tableau de bord</ListItemText>
              </MenuItem>
            </Link>
          )}
          {canViewBusinessObjects && business.status === 'ACTIVE' && (
            <Link to={`/actions/businesses/update/${business._id}/active`}>
              <MenuItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Modifier le commerce</ListItemText>
              </MenuItem>
            </Link>
          )}
          <Link to={`/actions/businesses/update/${business._id}/contact`}>
            <MenuItem>
              <ListItemIcon>
                <ContactMail />
              </ListItemIcon>
              <ListItemText
                sx={{
                  textWrap: 'wrap',
                }}>
                Modifier les informations supplémentaires (contact)
              </ListItemText>
            </MenuItem>
          </Link>
          {canViewBusinessObjects && business.status === 'PENDING' && (
            <Link to={`/actions/businesses/update/${business._id}/pending`}>
              <MenuItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Modifier le commerce</ListItemText>
              </MenuItem>
            </Link>
          )}
          {canViewBusinessObjects && business.status === 'REFERENCE' && (
            <Link to={`/actions/businesses/update/${business._id}/reference`}>
              <MenuItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Modifier le commerce</ListItemText>
              </MenuItem>
            </Link>
          )}
          {canViewBusinessObjects && business.status === 'B2B_WEB' && (
            <Link to={`/actions/businesses/update/${business._id}/reference`}>
              <MenuItem>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Modifier le commerce</ListItemText>
              </MenuItem>
            </Link>
          )}
          <Link
            to={
              business.status === 'REFERENCE'
                ? `/actions/businesses/transfer-display-only-business/${business._id}`
                : `/actions/businesses/transfer-account/${business._id}`
            }>
            <MenuItem>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText>Transferer le commerce</ListItemText>
            </MenuItem>
          </Link>
          <Link to={`/actions/businesses/update/${business._id}/image`}>
            <MenuItem>
              <ListItemIcon>
                <Image />
              </ListItemIcon>
              <ListItemText>Modifier l'image du commerce</ListItemText>
            </MenuItem>
          </Link>
          {authUser?.role !== 'SALES' && (
            <Link to={`/actions/business/${business._id}/assign-sales`}>
              <MenuItem>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText>Affecter à un agent commercial</ListItemText>
              </MenuItem>
            </Link>
          )}
          <Link to={`/business/${business._id}/partnership-requests`}>
            <MenuItem>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText>Demandes de partenariats</ListItemText>
            </MenuItem>
          </Link>

          <Dialogable
            dialog={({open, onClose}) => (
              <DownloadBusinessQRCode
                business={business}
                open={open}
                onClose={onClose}
              />
            )}
            source={({onOpen}) => (
              <MenuItem onClick={onOpen}>
                <ListItemIcon>
                  <QrCode />
                </ListItemIcon>
                <ListItemText>Télécharger QR Code</ListItemText>
              </MenuItem>
            )}
          />

          {!business?.isSiteTemplate ? (
            <Dialogable
              source={({onOpen}) => (
                <MenuActionItem
                  icon={<Save />}
                  label="Sauvegarder en tant que modèle de site"
                  onClick={onOpen}
                />
              )}
              dialog={({open, onClose: propOnClose}) => (
                <SaveAsTemplateModal
                  business={business}
                  open={open}
                  onClose={() => {
                    propOnClose();
                    onClose();
                  }}
                />
              )}
            />
          ) : (
            <Dialogable
              source={({onOpen}) => (
                <MenuActionItem
                  icon={<Delete />}
                  label="Supprimer en tant que modèle de site"
                  onClick={onOpen}
                />
              )}
              dialog={({open, onClose: propOnClose}) => (
                <RemoveAsTemplateModal
                  business={business}
                  open={open}
                  onClose={() => {
                    propOnClose();
                    onClose();
                  }}
                />
              )}
            />
          )}
          <MenuItem onClick={() => getBusinessSpaceLink(business)}>
            <ListItemIcon>
              <OpenInNew />
            </ListItemIcon>
            <ListItemText>
              {business.withSections
                ? 'Ouvrir la page de rubrique'
                : 'Afficher le site clients'}
            </ListItemText>
          </MenuItem>

          {canViewBusinessObjects &&
            authUser?.role !== 'SALES' &&
            authUser?.role !== 'MANAGER' &&
            business.deactivated && (
              <Dialogable
                dialog={({open, onClose}) => (
                  <ReactivateBusiness
                    business={business}
                    open={open}
                    onClose={onClose}
                  />
                )}
                source={({onOpen}) => (
                  <MenuItem onClick={onOpen}>
                    <ListItemIcon>
                      <LockOpen />
                    </ListItemIcon>
                    <ListItemText>Réactiver l'accès au commerce</ListItemText>
                  </MenuItem>
                )}
              />
            )}
          {business.status === 'ACTIVE' &&
            canViewBusinessObjects &&
            authUser?.role !== 'SALES' &&
            authUser?.role !== 'MANAGER' &&
            !business.deactivated && (
              <Dialogable
                dialog={({open, onClose}) => (
                  <DeactivateBusiness
                    business={business}
                    open={open}
                    onClose={onClose}
                  />
                )}
                source={({onOpen}) => (
                  <MenuItem onClick={onOpen}>
                    <ListItemIcon>
                      <Lock />
                    </ListItemIcon>
                    <ListItemText>Désactiver l'accès au commerce</ListItemText>
                  </MenuItem>
                )}
              />
            )}
          {userIsRoot && (
            <Dialogable
              dialog={({open, onClose: onDialogClose}) => (
                <DeleteBusiness
                  business={business}
                  open={open}
                  onClose={() => {
                    onDialogClose();
                    onClose();
                  }}
                />
              )}
              source={({onOpen}) => (
                <MenuItem onClick={onOpen}>
                  <ListItemIcon>
                    <Delete sx={{color: 'error.main'}} />
                  </ListItemIcon>
                  <ListItemText sx={{color: 'error.main'}}>
                    Supprimer le commerce
                  </ListItemText>
                </MenuItem>
              )}
            />
          )}
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

export default BusinessActions;
