import React from 'react';
import {NotificationManager} from 'react-notifications';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useEnableSuperuserMutation} from '../../redux/superusersApi';

const EnableSuperuser = ({
  superuser,
  open,
  onSuccess,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  superuser: SuperUser;
}) => {
  const [enableSuperuser, mutation] = useEnableSuperuserMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Utilisateur réactivé avec succès');
      onSuccess();
    }
  }, [mutation.isSuccess]);

  useQueryInternalError(mutation);

  return (
    <Confirmation
      description="Êtes-vous sûr vouloir réactiver cet utilisateur?"
      open={open}
      isLoading={mutation.isLoading}
      onClose={() => onClose()}
      onConfirm={() =>
        enableSuperuser({
          id: superuser._id,
        })
      }
    />
  );
};

export default EnableSuperuser;
