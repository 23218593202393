import React, {useMemo} from 'react';

import {AccountTree, Check} from '@mui/icons-material';
import {Chip, Paper, Stack, Typography} from '@mui/material';
import {green, yellow} from '@mui/material/colors';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import AspectRatioImage from '../../components/atoms/AspectRatioImage';

import BusinessActions from '../../modals/BusinessActions/BusinessActions';

const BusinessCard: React.FC<{
  business: PopulatedBusiness & {
    emails?: Email[];
    phoneNumbers?: PhoneNumber[];
  };
}> = ({business}) => {
  const [open, setOpen] = React.useState(false);

  const userIsRoot = useUserIsRoot();

  const bgColor = useMemo(() => {
    if (business.status === 'PENDING') return yellow['50'];
    return green['50'];
  }, [business.status]);

  return (
    <>
      <Paper
        square
        sx={{py: 4, px: 2, backgroundColor: bgColor}}
        onClick={() => setOpen(true)}>
        <Stack spacing={1.5} direction="column" alignItems="flex-start">
          {business.image && (
            <Stack alignSelf="center" sx={{width: '50%'}} direction="column">
              <FirebaseStorageImage
                reference={business.image?.reference}
                Component={AspectRatioImage}
                ratio="1:1"
              />
            </Stack>
          )}
          <Typography variant="h5">{business.name}</Typography>
          <Typography variant="h6">{business.category.name}</Typography>
          {business.isSiteTemplate && (
            <Typography variant="body1">
              Ceci est un modèle de site
            </Typography>
          )}
          {business.owner && (
            <>
              <Typography variant="body1">
                Propriétaire{' '}
                <b>
                  {business.owner.firstName} {business.owner.lastName}
                </b>
              </Typography>
              {business.owner.email && (
                <Typography variant="body1">{business.owner.email}</Typography>
              )}
              {business.owner.phoneNumber && (
                <Typography variant="body1">
                  {business.owner.phoneNumber}
                </Typography>
              )}
            </>
          )}
          {(userIsRoot || business.assignee?.role !== 'ROOT') && (
            <Typography variant="body1">
              {business.assignee ? (
                <>
                  Géré(e) par{' '}
                  <b>
                    {business.assignee.firstName} {business.assignee.lastName}
                  </b>
                </>
              ) : (
                <>Ce commerce n'est pas affecté</>
              )}
            </Typography>
          )}
          <Typography variant="body1">
            {business.address ?? 'Aucune addresse donnée'} <br />
            {business.radius && `Diamètre environ (${business.radius} km)`}
          </Typography>
          <Typography variant="body1">
            Crée le{' '}
            <b>{new Date(business.createdAt).toLocaleDateString('fr-FR')}</b>
          </Typography>
          <Typography variant="body1">
            Nombre de QR code affichés <b>{business?.qrCodeQuantity || 1}</b>
          </Typography>
          {business.deactivated && (
            <Stack alignItems="center" spacing={1} direction="row">
              <Typography>Désactivé</Typography>
              <Check color="error" />
            </Stack>
          )}
          {business.emails?.[0]?.email && (
            <Typography variant="body1">
              {business.emails?.[0]?.email}
            </Typography>
          )}
          {business.phoneNumbers?.[0]?.phoneNumber && (
            <Typography variant="body1">
              {business.phoneNumbers?.[0]?.phoneNumber}
            </Typography>
          )}
          <Stack direction="row" spacing={1}>
            {business.status === 'PENDING' && (
              <Chip label="Commerce non activé" color="warning" />
            )}
            {business.status === 'ACTIVE' && (
              <Chip label="Commerce active" color="success" />
            )}
            {business.status === 'REFERENCE' && (
              <Chip label="Commerce afficheur" color="success" />
            )}
            {business.status === 'B2B_WEB' && (
              <Chip label="Commerce B2B Web" color="success" />
            )}
            {business.withSections && (
              <Chip
                icon={<AccountTree />}
                label="Avec rubriques"
                color="primary"
              />
            )}
          </Stack>
        </Stack>
      </Paper>
      <BusinessActions
        business={business}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default BusinessCard;
