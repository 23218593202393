import {configureStore} from '@reduxjs/toolkit';

import {authReducer} from '../redux/auth/authSlice';
import authApi from '../redux/authApi';
import businessCategoriesApi from '../redux/businessCategoriesApi';
import {customBusinessApi} from '../redux/businessesApi';
import businessReferralsApi, {
  customBusinessReferralsApi,
} from '../redux/businessReferralsApi';
import campaignAdsApi from '../redux/campaignAdsApi';
import campaignAdSetsApi from '../redux/campaignAdSetsApi';
import campaignsApi from '../redux/campaignsApi';
import filesApi from '../redux/filesApi';
import {autocompleteApi, geocodingApi} from '../redux/geocodingApi';
import groupsApi from '../redux/groupsApi';
import {loaderReducer} from '../redux/loaderSlice';
import partnersApi from '../redux/partnersApi';
import runScriptApi from '../redux/runScriptApi';
import superusersApi from '../redux/superusersApi';
import usersApi from '../redux/usersApi';
import preferencesApi from '../redux/preferencesApi';

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    files: filesApi.reducer,
    [superusersApi.reducerPath]: superusersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [customBusinessApi.reducerPath]: customBusinessApi.reducer,
    [geocodingApi.reducerPath]: geocodingApi.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [runScriptApi.reducerPath]: runScriptApi.reducer,
    [businessReferralsApi.reducerPath]: businessReferralsApi.reducer,
    [customBusinessReferralsApi.reducerPath]:
      customBusinessReferralsApi.reducer,
    [businessCategoriesApi.reducerPath]: businessCategoriesApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [partnersApi.reducerPath]: partnersApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [campaignAdSetsApi.reducerPath]: campaignAdSetsApi.reducer,
    [campaignAdsApi.reducerPath]: campaignAdsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [preferencesApi.reducerPath]: preferencesApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      superusersApi.middleware,
      authApi.middleware,
      customBusinessApi.middleware,
      geocodingApi.middleware,
      autocompleteApi.middleware,
      runScriptApi.middleware,
      businessReferralsApi.middleware,
      customBusinessReferralsApi.middleware,
      businessCategoriesApi.middleware,
      groupsApi.middleware,
      partnersApi.middleware,
      filesApi.middleware,
      campaignsApi.middleware,
      campaignAdSetsApi.middleware,
      campaignAdsApi.middleware,
      usersApi.middleware,
      preferencesApi.middleware,
    ]),
});

export default store;
