import store from '../../app/store';

import firebase from '../../lib/app/firebase';

import {createSlice} from '@reduxjs/toolkit';

import {clearRTKApiCache} from './clearRTKApiCache';

const initialState: {
  authenticated?: boolean;
} = {
  authenticated: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedOut(state) {
      state.authenticated = false;
    },
    loggedIn(state) {
      state.authenticated = true;
    },
  },
});

export const {loggedOut, loggedIn} = authSlice.actions;

firebase.auth().onAuthStateChanged(user => {
  if (!user) {
    store.dispatch(loggedOut());
    clearRTKApiCache();
  } else {
    store.dispatch(loggedIn());
  }
});

export const authReducer = authSlice.reducer;

export default authSlice;
