import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {Stack} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import {internalError} from '../../lib/helpers/internalError';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import AspectRatioImage from '../../components/atoms/AspectRatioImage';
import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import PageAction from '../../components/organisms/PageAction';

import {
  useGetBusinessByIdQuery,
  useUpdateBusinessImageMutation,
} from '../../redux/businessesApi';

const validationSchema = yup.object({
  image: yup.string().required('Champs requis'),
});

const UpdateBusinessImage = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [updateBusiness, mutation] = useUpdateBusinessImageMutation();
  const businessQuery = useGetBusinessByIdQuery(id as string);
  const uploadFile = useUploadFile();
  const [loading, setLoading] = React.useState(false);

  const business = businessQuery.data;

  useQueryInternalError(mutation);

  const handleUpdateImage = async ({image, id}: {id: string; image: any}) => {
    try {
      setLoading(true);
      const file = await uploadFile(image);
      await updateBusiness({
        id: id as string,
        image: file._id,
      });
      NotificationManager.success('Commerce modifié avec succès');
      navigate(-1);
    } catch (err) {
      internalError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title={`Modifier l'image du commerce ${business?.name || ''}`}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={({image}: any) =>
          handleUpdateImage({
            id: business?._id || '',
            image,
          })
        }
        initialValues={{
          image: '',
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={2}>
            {business?.image?.reference && (
              <FirebaseStorageImage
                Component={AspectRatioImage}
                reference={business?.image?.reference as string}
                ratio="1:1"
              />
            )}
            <FormikFileInput name="image" maxSize={5} />
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={loading ? undefined : () => handleSubmit()}
                loading={loading || businessQuery.isLoading}
                variant="contained"
                color="primary">
                Terminer
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default UpdateBusinessImage;
