import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import {Box, Stack} from '@mui/material';

import {PageActionContext} from './PageActionContainer';

const PageAction = ({
  title = '',
  children,
}: {
  title?: string;
  children: React.ReactNode;
}) => {
  const {setActionTitle} = React.useContext(PageActionContext);

  React.useEffect(() => {
    setActionTitle(title);

    return () => {
      setActionTitle('');
    };
  }, [setActionTitle, title]);

  return (
    <HelmetProvider>
      <Stack spacing={4}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Box>{children}</Box>
      </Stack>
    </HelmetProvider>
  );
};

export default PageAction;
