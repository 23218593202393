import {FilterList, Search} from '@mui/icons-material';
import {Box, IconButton, Input} from '@mui/material';
import {ComponentProps} from 'react';

const RoundInput = ({sx, ...props}: ComponentProps<typeof Input>) => (
  <Input
    sx={{
      flex: 1,
      border: '2px solid',
      borderColor: 'grey.100',
      p: 0.5,
      ...sx,
    }}
    disableUnderline
    {...props}
  />
);

const SearchBarWithFilterButton = ({
  onSearchChange,
  onOpenFilter,
}: {
  onSearchChange: (search: string) => void;
  onOpenFilter: () => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}>
      <RoundInput
        placeholder="Rechercher"
        onChange={evt => onSearchChange(evt.target.value)}
        startAdornment={<Search fontSize="medium" />}
        endAdornment={
          <IconButton onClick={onOpenFilter}>
            <FilterList />
          </IconButton>
        }
      />
    </Box>
  );
};

export default SearchBarWithFilterButton;
