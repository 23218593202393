import LoadingButton from '@mui/lab/LoadingButton';
import {Stack, Typography} from '@mui/material';
import {Formik} from 'formik';
import {useCallback, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';

import FormikUserInfo, {
  getUserInfoInitialValues,
} from '../../components/organisms/formik/FormikUserInfo';
import PageAction from '../../components/organisms/PageAction';
import {internalError} from '../../lib/helpers/internalError';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {
  useGetBusinessByIdQuery,
  useTransferBusinessOwnershipMutation,
} from '../../redux/businessesApi';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Email invalide')
    .requiredIf(form => form.newUser, 'Champs requis'),
  phoneNumber: yup.string().requiredIf(form => form.newUser, 'Champs requis'),
  lastName: yup.string().requiredIf(form => form.newUser, 'Champs requis'),
  firstName: yup.string().requiredIf(form => form.newUser, 'Champs requis'),
  owner: yup.string().requiredIf(form => !form.newUser, 'Champs requis'),
});

const TransferBusinessAccount = () => {
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();
  const query = useGetBusinessByIdQuery(id as string);
  const [transferBusinessOwnership] = useTransferBusinessOwnershipMutation();

  useQueryInternalError(query);

  const handelTransferBusiness = useCallback(
    async ({email, firstName, lastName, phoneNumber, owner}: any) => {
      try {
        setLoading(true);
        await transferBusinessOwnership({
          business: id as string,
          email,
          firstName,
          lastName,
          phoneNumber,
          owner,
        }).unwrap();
        NotificationManager.success('Le commerce a été converti avec succès');
        navigate(-1);
      } catch (err: any) {
        if (err?.data?.error?.code === 'auth/email-already-exists') {
          NotificationManager.error('Cet addresse email existe déjà');
        } else if (err?.data?.error?.code === 'auth/invalid-phone-number') {
          NotificationManager.error('Le Numéro téléphone que vous avez entré est invalide');
        } else if (
          err?.data?.error?.code === 'auth/phone-number-already-exists'
        ) {
          NotificationManager.error('Le numéro de téléphone existe déjà');
        } else {
          internalError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    [id, navigate, transferBusinessOwnership],
  );

  return (
    <PageAction title={`Transferer le commerce`}>
      <Typography
        variant="body1"
        sx={{
          color: theme => theme.palette.error.main,
          mb: 2,
        }}>
        Attention: Le transfer ne tient pas compte les informations d'abonnement.
      </Typography>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          ...getUserInfoInitialValues(),
        }}
        onSubmit={handelTransferBusiness}>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <FormikUserInfo />
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={loading ? undefined : () => handleSubmit()}>
              Terminer
            </LoadingButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default TransferBusinessAccount;
