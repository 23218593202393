import {SuperUserRole} from '../../enums';

import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {MenuItem, Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';
import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikSelect from '../../components/atoms/formik/FormikSelect';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import SuperuserPicker from '../../components/molecules/SuperuserPicker';
import PageAction from '../../components/organisms/PageAction';

import {useGetAuthUserQuery} from '../../redux/authApi';
import {
  SuperUserForm,
  useCreateSuperuserMutation,
} from '../../redux/superusersApi';

const validationSchema = yup.object({
  firstName: yup.string().required('Champs requis'),
  lastName: yup.string().required('Champs requis'),
  email: yup.string().email('Email invalide').required('Champs requis'),
  role: yup.string().required('Champs requis'),
  password: yup
    .string()
    .required('Champs requis')
    .min(6, 'Minimum de 6 caractères'),
});

const CreateSuperUser = () => {
  const [createSuperuser, mutation] = useCreateSuperuserMutation();
  const userIsRoot = useUserIsRoot();
  const {data: user} = useGetAuthUserQuery();
  const navigate = useNavigate();

  const getSuperuserAssigneeRoleName = (userRole: string) => {
    switch (userRole) {
      case 'SALES':
        return 'Manager';
      case 'MANAGER':
        return 'Chef de pôle';
      default:
        return '';
    }
  };

  React.useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/superusers');
    }

    if (mutation.isError && mutation.error) {
      const error = (mutation.error as any)?.data;
      if (error?.code === 'auth/email-already-exists') {
        NotificationManager.error('Cet email est déjà utilisé');
      } else {
        internalError(mutation.error);
      }
    }
  }, [mutation.isSuccess, navigate, mutation.isError, mutation.error]);

  return (
    <PageAction title="Création de superutilisateur">
      <Formik<SuperUserForm>
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          role: SuperUserRole.SALES,
          parent: '',
        }}
        onSubmit={form => createSuperuser(form)}>
        {({handleSubmit, values}) => (
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FormikTextField name="firstName" label="Nom" />
              <FormikTextField name="lastName" label="Prénom" />
              <FormikTextField name="email" label="Email" />
              <FormikTextField name="password" label="Mot de passe" />
              <FormikSelect name="role" label="Rôle">
                <MenuItem value="SALES">Agent commercial</MenuItem>
                {user && user?.role !== 'MANAGER' && user?.role !== 'SALES' && (
                  <MenuItem value="MANAGER">Responsable de pôle</MenuItem>
                )}
                {userIsRoot && (
                  <MenuItem value="HEAD_MANAGER">Head Manager</MenuItem>
                )}
              </FormikSelect>
              {userIsRoot && ['SALES', 'MANAGER'].includes(values.role) && (
                <SuperuserPicker
                  name="parent"
                  label={getSuperuserAssigneeRoleName(values.role)}
                />
              )}
            </Stack>
            <SaveButton
              loading={mutation.isLoading}
              onClick={() => handleSubmit()}>
              Sauvegarder
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CreateSuperUser;
