import {GroupByIdResponseObject} from '../types/custom';

import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';

const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: authBaseQuery,
  tagTypes: ['Group'],
  endpoints(build) {
    return {
      getAllGroups: build.query<
        PaginateResult<Group>,
        PaginateParams & {
          activeOnly: string;
        }
      >({
        query: ({$skip, $limit, activeOnly = ''}) => ({
          url: `/getAllGroups`,
          method: 'get',
          params: {
            $skip,
            $limit,
            activeOnly,
          },
        }),
        providesTags: result =>
          result
            ? [
                ...result.data.map(({_id}) => ({
                  type: 'Group' as const,
                  id: _id,
                })),
                {type: 'Group', id: 'LIST'},
              ]
            : ['Group'],
      }),
      activateGroup: build.mutation<Group, {id: string; status: string}>({
        query: ({id, status}) => ({
          url: `/activateGroup/${id}`,
          method: 'post',
          data: {
            status,
          },
        }),
        invalidatesTags: result =>
          result ? [{type: 'Group', id: result._id}] : [],
      }),
      deleteGroup: build.mutation<Group, string>({
        query: id => ({
          url: `/deleteGroup/${id}`,
          method: 'post',
        }),
        invalidatesTags: () => [{type: 'Group', id: 'LIST'}],
      }),
      getGroupById: build.query<GroupByIdResponseObject, string>({
        query: id => ({
          url: `/getGroupById/${id}`,
          method: 'get',
        }),
        providesTags: result => [
          {type: 'Group', id: result?._id},
          {type: 'Group', id: 'LIST'},
        ],
      }),
    };
  },
});

export const {
  useGetAllGroupsQuery,
  useGetGroupByIdQuery,
  useActivateGroupMutation,
  useDeleteGroupMutation,
} = groupsApi;

export default groupsApi;
