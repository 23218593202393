import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {FormControlLabel, Stack} from '@mui/material';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/FormikBusinessCategoryPicker';
import PageAction from '../../components/organisms/PageAction';

import {
  useGetBusinessByIdQuery,
  useUpdatePendingBusinessMutation,
} from '../../redux/businessesApi';
import {BUSINESS_DESCRIPTION_MAX_LENGTH} from "../../app/constants";

const validationSchema = yup.object({
  name: yup.string().required('Champs requis'),
  category: yup.string().required('Champs requis'),
  address: yup.string().required('Champs requis'),
  location: yup.object().test('location', 'Champs requis', value => {
    return value?.longitude && value?.latitude;
  }),
  enabled: yup.boolean(),
});

const UpdatePendingBusiness = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [updateBusiness, mutation] = useUpdatePendingBusinessMutation();
  const businessQuery = useGetBusinessByIdQuery(id as string);

  const business: Business | undefined = businessQuery.data;
  const owner: User = business?.owner as User;

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Commerce modifié avec succès');
      navigate(-1);
    }
  }, [mutation.isSuccess, navigate]);

  useQueryInternalError(mutation);

  return (
    <PageAction title={`Modifier le commerce ${business?.name || ''}`}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={({
          name,
          category,
          descriptionIntl,
          address,
          location,
          displayB2B,
          displayB2C,
          withSections,
          qrCodeQuantity,
          enabled,
        }: any) =>
          updateBusiness({
            id: business?._id || '',
            name,
            category,
            descriptionIntl,
            address,
            location,
            displayB2B,
            displayB2C,
            withSections,
            qrCodeQuantity,
            enabled,
          })
        }
        initialValues={{
          name: business?.name || '',
          category: (business?.category as BusinessCategory)?._id || '',
          billableAccount: owner?.billableAccount ?? false,
          address: business?.address || '',
          descriptionIntl: business?.descriptionIntl || {},
          displayB2B: business?.displayB2B ?? false,
          displayB2C: business?.displayB2C ?? false,
          withSections: business?.withSections ?? false,
          location: business?.location
            ? {
                longitude: business.location.coordinates[0],
                latitude: business.location.coordinates[1],
              }
            : null,
          qrCodeQuantity: business?.qrCodeQuantity || 1,
          enabled: business?.enabled !== false,
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={2}>
            <FormikTextField name="name" label="Nom du commerce" />
            <FormikTextField
              name="description"
              label="Description"
              multiline
              rows={4}
              enableIntl
              InputProps={{
                inputProps: {
                  maxlength: BUSINESS_DESCRIPTION_MAX_LENGTH,
                }
              }}
            />
            <FormikBusinessCategoryPicker label="Catégorie" name="category" />
            <FormikGeoLocationPicker
              addressName="address"
              locationName="location"
            />
            <FormControlLabel
              control={<FormikSingleChoice name="displayB2B" />}
              label="Afficher sur B2B"
            />
            <FormControlLabel
              control={<FormikSingleChoice name="displayB2C" />}
              label="Afficher sur B2C"
            />
            <FormControlLabel
              control={<FormikSingleChoice name="withSections" />}
              label="Activer les rubriques"
            />
            <FormControlLabel
              control={<FormikSingleChoice name="enabled" />}
              label="Activer"
            />
            <FormikTextField
              name="qrCodeQuantity"
              label="Nombre de QR code affichés"
              type="number"
            />
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={mutation.isLoading ? undefined : () => handleSubmit()}
                loading={mutation.isLoading || businessQuery.isLoading}
                variant="contained"
                color="primary">
                Terminer
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default UpdatePendingBusiness;
