import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';
import providesTagsPagination from '../lib/helpers/providesTagsPagination';
import invalidatesTagsUsingResult from '../lib/helpers/invalidatesTagsUsingResult';
import invalidateTagsUsingParamId from '../lib/app/invalidateTagsUsingParamId';

const businessCategoriesApi = createApi({
  reducerPath: 'businessCategoriesApi',
  baseQuery: authBaseQuery,
  tagTypes: ['BusinessCategory'],
  endpoints(build) {
    return {
      getBusinessCategoryById: build.query<BusinessCategory, string>({
        query: id => ({
          url: `/getBusinessCategoryById/${id}`,
          method: 'get',
        }),
        providesTags: result =>
          result ? [{type: 'BusinessCategory', id: result._id}] : [],
      }),
      getBusinessCategories: build.query<
        PaginateResult<BusinessCategory>,
        PaginateParams & {
          sortField: string;
          sortOrder: number;
          search: string;
        }
      >({
        query: ({
          $limit,
          $skip,
          sortField = 'createdAt',
          sortOrder = -1,
          search = '',
        }) => {
          return {
            url: '/getAdminBusinessCategories',
            method: 'get',
            params: {
              $limit,
              $skip,
              sortField,
              sortOrder,
              search,
            },
          };
        },
        transformResponse(response: any): any {
          return {
            data: response.data,
            total: response.count,
          };
        },
        providesTags: providesTagsPagination('BusinessCategory'),
      }),
      createBusinessCategory: build.mutation<
        BusinessCategory,
        {nameIntl: string; b2bPriceIdentifier: string; b2bTrialDays: number}
      >({
        query: data => ({
          url: `/createBusinessCategory`,
          method: 'post',
          data,
        }),
        invalidatesTags: invalidatesTagsUsingResult('BusinessCategory'),
      }),
      updateBusinessCategory: build.mutation<
        BusinessCategory,
        {
          id: string;
          nameIntl: any;
          b2bPriceIdentifier: string;
          b2bTrialDays: number;
        }
      >({
        query: ({id, nameIntl, b2bPriceIdentifier, b2bTrialDays}) => ({
          url: `/updateBusinessCategory/${id}`,
          method: 'put',
          data: {
            nameIntl,
            b2bPriceIdentifier,
            b2bTrialDays,
          },
        }),
        invalidatesTags: invalidatesTagsUsingResult('BusinessCategory'),
      }),
      deleteBusinessCategory: build.mutation<
        BusinessCategory,
        {
          id: string;
        }
      >({
        query: ({id}) => ({
          url: `/deleteBusinessCategory/${id}`,
          method: 'delete',
        }),
        invalidatesTags: invalidateTagsUsingParamId(
          'BusinessCategory',
          ({id}: any) => id,
        ),
      }),
    };
  },
});

export const {
  useGetBusinessCategoriesQuery,
  useCreateBusinessCategoryMutation,
  useUpdateBusinessCategoryMutation,
  useDeleteBusinessCategoryMutation,
  useGetBusinessCategoryByIdQuery,
} = businessCategoriesApi;

export default businessCategoriesApi;
