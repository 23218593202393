import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from '@mui/material';

import {Field, FieldProps} from 'formik';

const FormikSelect = ({
  name,
  label,
  ...props
}: SelectProps & {name: string}) => {
  return (
    <Field name={name}>
      {({field, form}: FieldProps) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} {...props} />
          {form.touched[name] && form.errors[name] && (
            <FormHelperText error>{form.errors[name] as string}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default FormikSelect;
