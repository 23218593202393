import {endOfDay, isAfter, isDate, isPast} from 'date-fns';
import * as yup from 'yup';

import FormikDateTimePicker from '../../../components/atoms/formik/FormikDateTimePicker';
import FormikTextField from '../../../components/molecules/formik/FormikTextField';

export const stepOneSchema = {
  name: yup.string().required('Le nom de la campagne est obligatoire.'),
  startDate: yup
    .date()
    .required('Champ obligatoire')
    .test(
      'testStartDate',
      'Date doit être dans le futur',
      function (value, context) {
        if (value && !context.parent.id && isDate(value)) {
          return !isPast(endOfDay(value));
        }
        return true;
      },
    ),
  endDate: yup
    .date()
    .nullable()
    // .required('Champ obligatoire')
    .test(
      'testEndDate',
      'Date doit être après la date début',
      function (value) {
        if (value && isDate(value)) {
          return isAfter(value, this.parent.startDate);
        }
        return true;
      },
    ),
  budget: yup.number().min(5).required('Champ obligatoire'),
};

const StepOne = ({values, setFieldValue}: any) => {
  return (
    <>
      <FormikTextField name="name" label="Nom" />
      <FormikDateTimePicker name="startDate" label="Date de début" />
      <FormikDateTimePicker name="endDate" label="Date de fin" />
      <FormikTextField type="number" name="budget" label="Budget (€)" />
    </>
  );
};

export default StepOne;
