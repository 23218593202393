import config from '../../config.json';

import React from 'react';
import {NotificationManager} from 'react-notifications';

import {Download, Link, OpenInNew} from '@mui/icons-material';
import {ListItemIcon, ListItemText, MenuItem, MenuList} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import copy from 'copy-to-clipboard';
import * as htmlToImage from 'html-to-image';
import qrcode from 'qrcode';

const SuperuserCouponsActions = ({
  superuser,
  open,
  onClose,
}: {
  superuser: SuperUser;
  open: boolean;
  onClose: () => void;
}) => {
  const link = `${config.b2cURL}/staff-coupons/${superuser._id}`;
  const [qrCode, setQrCode] = React.useState('');
  const imgRef = React.useRef<any>();

  const downloadQRCode = () => {
    if (imgRef.current) {
      htmlToImage
        .toPng(imgRef.current, {quality: 0.95})
        .then(function (dataUrl) {
          const link = document.createElement('a');
          link.download = `staff-coupons-${Date.now()}-${superuser._id}.png`;
          link.href = dataUrl;
          link.click();
        });
    }
  };

  React.useEffect(() => {
    qrcode.toDataURL(link, (err: any, url: string) => {
      setQrCode(url);
    });
  }, [link]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <img
          ref={imgRef}
          style={{width: '100%', height: 'auto'}}
          src={qrCode}
          alt="QR Code"
        />
        <MenuList>
          <MenuItem
            onClick={() => {
              downloadQRCode();
            }}>
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText>Télécharger le QR code</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              copy(link);
              NotificationManager.success('Lien copié avec succès');
            }}>
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText>Copier le lien</ListItemText>
          </MenuItem>
          <a
            style={{textDecoration: 'none', color: 'inherit'}}
            href={link}
            target="_blank">
            <MenuItem>
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText>Ouvrir le lien</ListItemText>
            </MenuItem>
          </a>
        </MenuList>
      </DialogContent>
    </Dialog>
  );
};

export default SuperuserCouponsActions;
