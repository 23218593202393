import config from '../../config.json';

const getBusinessSpaceLink = (business: Business) => {
  let link = `${config.b2cURL}/business-space/${business._id}/partners`;

  if (business.status === "REFERENCE") {
    link = `${config.b2cURL}/business-space/${business._id}/nearby`;
  }

  if (business.withSections) {
    link = `${config.b2cURL}/business-space/${business._id}/site`;
  }

  window.open(link, '_blank');
};

export default getBusinessSpaceLink;
