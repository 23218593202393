import {MenuItem} from '@mui/material';

import FormikGeoLocationPicker from '../../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';

import * as yup from 'yup';

// import FormikSlider from '../../../components/atoms/formik/FormikSlider';
import FormikSelect from '../../../components/atoms/formik/FormikSelect';
import FormikTextField from '../../../components/molecules/formik/FormikTextField';

import {useGetAllBusinessCategoriesQuery} from '../../../redux/businessesApi';

export const stepTwoSchema = {
  adSetName: yup.string().required("Le nom de l'adSet est obligatoire"),
  address: yup.string(),
  locations: yup
    .object()
    .test('location', 'Entrez ou réssayer une autre adresse', value => {
      return value?.longitude && value?.latitude;
    }),
  // radius: yup.number().required("Champs requis"),
  placements: yup.array().of(yup.string()),
  // .min(1, "Champ obligatoire"),
  // .required("Champ obligatoire"),
  categories: yup.array().of(yup.string()),
  // .min(1, "Champ obligatoire")
  // .required("Champ obligatoire")
};

const StepTwo = ({values, setFieldValue}: any) => {
  const businessCategories = useGetAllBusinessCategoriesQuery();

  return (
    <>
      <FormikTextField name="adSetName" label="Nom" />
      <FormikGeoLocationPicker
        locationName="locations"
        addressName="address"
        fullAddress={false}
      />
      {/*<FormikSlider
        name="radius"
        label="Diamètre"
        renderValue={(val: string) => `${val} Km`}
        step={1}
        min={0}
        max={100}
      />*/}
      <FormikSelect name="placements" label="Emplacements" multiple>
        <MenuItem value="partners">Partenaire</MenuItem>
        <MenuItem value="catalogs">Catalogue</MenuItem>
        <MenuItem value="coupons">Coupon</MenuItem>
        <MenuItem value="posts">Actualité</MenuItem>
        <MenuItem value="nearby">À proximité</MenuItem>
      </FormikSelect>
      <FormikSelect name="categories" label="Catégories" multiple>
        {businessCategories.data?.map(businessesCategory => (
          <MenuItem key={businessesCategory._id} value={businessesCategory._id}>
            {businessesCategory.name}
          </MenuItem>
        ))}
      </FormikSelect>
    </>
  );
};

export default StepTwo;
