import {useJsApiLoader} from '@react-google-maps/api';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import {ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import {AlertProvider} from './lib/alert';
import './lib/yup-extensions';
import './App.css';
import store from './app/store';
import AppRoutes from './AppRoutes';
import {theme} from './appTheme';
import config from './config.json';

function App() {
  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </AlertProvider>
          <NotificationContainer />
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
