import {useMemo} from 'react';

const useUsersPickerOptions = (
  data?: {
    _id: string;
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
  }[],
) =>
  useMemo(() => {
    if (data) {
      return data
        ?.map(o => ({
          id: o._id,
          label: `${o.firstName} ${o.lastName} (${
            o.email || o.phoneNumber || ''
          })`,
        }))
        .sort((a: any, b: any) => {
          let fa = a.label.toLowerCase(),
            fb = b.label.toLowerCase();
          return fa < fb ? -1 : fa > fb ? 1 : 0;
        });
    }
    return [];
  }, [data]);

export default useUsersPickerOptions;
