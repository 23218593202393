import React from 'react';
import {NotificationManager} from 'react-notifications';

import {Typography} from '@mui/material';

import {useAlertContext} from '../../lib/alert';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useRegenerateReferralCodeMutation} from '../../redux/superusersApi';

const DeleteBusiness = ({
  superuser,
  open,
  onClose,
}: {
  superuser: SuperUser;
  open: boolean;
  onClose: () => void;
}) => {
  const [regenerateReferralCode, mutation] =
    useRegenerateReferralCodeMutation();
  const {showAlert} = useAlertContext();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Code généré avec succès');
      onClose();
      showAlert({
        message: (
          <Typography variant="body1">
            Le nouveau code de parrainage est:{' '}
            <strong>{mutation.data.toUpperCase()}</strong>
          </Typography>
        ),
        title: 'Nouveau Code',
      });
    }
  }, [mutation.isSuccess]);

  useQueryInternalError(mutation);

  return (
    <Confirmation
      isLoading={mutation.isLoading}
      description="Êtes-vous sûr vouloir regénérer le code de cet superutilisateur?"
      open={open}
      onClose={() => onClose()}
      onConfirm={() => regenerateReferralCode(superuser._id)}
    />
  );
};

export default DeleteBusiness;
