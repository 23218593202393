import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {authBaseQuery} from './apiBaseQuery';

const partnersApi = createApi({
  reducerPath: 'partnersApi',
  baseQuery: authBaseQuery,
  tagTypes: ['Partnership', 'PartnershipRequest'],
  endpoints(build) {
    return {
      getReceivedPartnershipRequestsBusinesses: build.query<
        PopulatedBusiness[],
        {id: string}
      >({
        query: ({id}) => ({
          url: `/getReceivedPartnershipRequestsBusinesses/${id}`,
          method: 'get',
        }),
        providesTags: result =>
          result
            ? [
                ...result.map(({_id}) => ({
                  type: 'PartnershipRequest' as const,
                  id: _id,
                })),
                {type: 'PartnershipRequest', id: 'LIST'},
              ]
            : ['PartnershipRequest'],
      }),
    };
  },
});

export const {useGetReceivedPartnershipRequestsBusinessesQuery} = partnersApi;

export default partnersApi;
