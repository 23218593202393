import BusinessCard from '../BusinessesList/BusinessCard';

import {useParams} from 'react-router-dom';

import {Breadcrumbs, CircularProgress, Stack, Typography} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import chunk from 'lodash/chunk';

import UserPage from '../../components/organisms/UserPage';

import {useGetReceivedPartnershipRequestsBusinessesQuery} from '../../redux/partnersApi';

const PartnershipRequests = () => {
  const {id} = useParams();
  const query = useGetReceivedPartnershipRequestsBusinessesQuery({
    id: id as string,
  });

  useQueryInternalError(query);

  return (
    <UserPage
      px={1}
      flex={1}
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">
            Demande de partenariats reçues
          </Typography>
        </Breadcrumbs>
      }>
      {query.isLoading ? (
        <CircularProgress />
      ) : query.data && query.data?.length > 0 ? (
        <Stack direction="column" spacing={1}>
          {chunk(query.data, 2)?.map(businesses => (
            <Stack direction="row" spacing={1}>
              {businesses.map(business => (
                <BusinessCard business={business} />
              ))}
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack alignItems="center" justifyContent="center" width="100%" py={10}>
          <Typography variant="body1">
            Aucune demande de partenariat reçue
          </Typography>
        </Stack>
      )}
    </UserPage>
  );
};

export default PartnershipRequests;
