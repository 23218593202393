import {SuperUserRole} from '../../enums';

import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import {CircularProgress, MenuItem, Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';
import useUserIsRoot from '../../lib/hooks/useUserIsRoot';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikSelect from '../../components/atoms/formik/FormikSelect';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';

import {
  SuperUserForm,
  useGetSuperuserByIdQuery,
  useUpdateSuperuserMutation,
} from '../../redux/superusersApi';

const validationSchema = yup.object({
  firstName: yup.string().required('Champs requis'),
  lastName: yup.string().required('Champs requis'),
  email: yup.string().email('Email invalide').required('Champs requis'),
  role: yup.string().required('Champs requis'),
});

const UpdateSuperUser = () => {
  const {id} = useParams<{
    id: string;
  }>();
  const query = useGetSuperuserByIdQuery(id as string);
  const [updateSuperuser, mutation] = useUpdateSuperuserMutation();
  const userIsRoot = useUserIsRoot();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/superusers');
    }

    if (mutation.isError && mutation.error) {
      const error = (mutation.error as any)?.data;
      if (error?.code === 'auth/email-already-exists') {
        NotificationManager.error('Cet email est déjà utilisé');
      } else {
        internalError(mutation.error);
      }
    }
  }, [mutation.isSuccess, navigate, mutation.isError, mutation.error]);

  return (
    <PageAction title="Modification de superutilisateur">
      {query.isLoading ? (
        <Stack direction="row" sx={{justifyContent: 'center'}}>
          <CircularProgress />
        </Stack>
      ) : (
        <Formik<SuperUserForm>
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            firstName: query.data?.firstName || '',
            lastName: query.data?.lastName || '',
            email: query.data?.email || '',
            password: '',
            role: query.data?.role || SuperUserRole.SALES,
            parent: query.data?.parent || '',
          }}
          onSubmit={form =>
            updateSuperuser({
              id: id as string,
              ...form,
            })
          }>
          {({handleSubmit}) => (
            <Stack
              sx={{
                position: 'relative',
              }}
              spacing={2}>
              <Stack spacing={1}>
                <FormikTextField name="firstName" label="Nom" />
                <FormikTextField name="lastName" label="Prénom" />
                <FormikTextField name="email" label="Email" />
                {userIsRoot && (
                  <FormikSelect name="role" label="Rôle">
                    <MenuItem value="SALES">Agent commercial</MenuItem>
                    <MenuItem value="MANAGER">Responsable de pôle</MenuItem>
                    <MenuItem value="HEAD_MANAGER">Head Manager</MenuItem>
                  </FormikSelect>
                )}
              </Stack>
              <SaveButton
                loading={mutation.isLoading}
                onClick={() => handleSubmit()}>
                Sauvegarder
              </SaveButton>
            </Stack>
          )}
        </Formik>
      )}
    </PageAction>
  );
};

export default UpdateSuperUser;
