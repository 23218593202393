import * as yup from 'yup';

yup.addMethod(yup.number, 'radiusValid', function (message: string) {
  return this.required('Champs requis')
    .min(1, 'Val minimum est 1')
    .max(100, 'Val maximum est 100');
});

declare module 'yup' {
  interface NumberSchema {
    radiusValid(): NumberSchema;
  }
}
