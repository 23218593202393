import React from 'react';
import {NotificationManager} from 'react-notifications';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import Confirmation from '../../components/organisms/Confirmation';

import {useActivateGroupMutation} from '../../redux/groupsApi';

const RefuseGroup = ({
  group,
  open,
  onClose,
}: {
  group: Group;
  open: boolean;
  onClose: () => void;
}) => {
  const [activateGroup, mutation] = useActivateGroupMutation();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('Groupe refusé avec succès');
      onClose();
    }
  }, [mutation.isSuccess]);

  useQueryInternalError(mutation);

  return (
    <Confirmation
      isLoading={mutation.isLoading}
      description="Êtes-vous sûr vouloir refuser ce groupe?"
      open={open}
      onClose={() => onClose()}
      onConfirm={() =>
        activateGroup({
          id: group._id,
          status: 'REFUSED',
        })
      }
    />
  );
};

export default RefuseGroup;
