import {GroupListResponseObject} from '../../types/custom';
import ActivateGroup from '../GroupsList/ActivateGroup';
import DeleteGroup from '../GroupsList/DeleteGroup';
import RefuseGroup from '../GroupsList/RefuseGroup';

import {useParams} from 'react-router-dom';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import FirebaseStorageImage from '../../lib/firebase-image/components/FirebaseStorageImage';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import AspectRatioImage from '../../components/atoms/AspectRatioImage';
import Dialogable from '../../components/molecules/Dialogable';
import PageAction from '../../components/organisms/PageAction';

import {useGetGroupByIdQuery} from '../../redux/groupsApi';

const GroupDetails = () => {
  const {id} = useParams<{id: string}>();

  const query = useGetGroupByIdQuery(id as string);

  useQueryInternalError(query);

  const group = query.data as GroupListResponseObject;

  if (!group || query.isLoading) {
    return (
      <PageAction title="Détails du groupe">
        <CircularProgress />
      </PageAction>
    );
  }

  return (
    <Stack direction="column" spacing={4}>
      <Stack spacing={2} direction="column">
        <Typography variant="h5">Information du groupe</Typography>
        <Stack spacing={1} direction="column" alignSelf="stretch">
          <Box width="50%" alignSelf="center">
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={group.image.reference}
              ratio="1:1"
            />
          </Box>
          <Typography align="left" variant="h6">
            {group.name}
          </Typography>
          <Typography variant="body1">
            Crée le {new Date(group.createdAt).toLocaleDateString()}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={2} direction="column" alignItems="flex-start">
        <Typography variant="h5">Information du commerce</Typography>
        <Stack direction="column" spacing={1} alignSelf="stretch">
          <Box width="50%" alignSelf="center">
            <FirebaseStorageImage
              Component={AspectRatioImage}
              reference={group.admin.image.reference}
              ratio="1:1"
            />
          </Box>
          <Typography variant="h6">{group.admin.name}</Typography>
          <Typography variant="body1">{group.admin.category.name}</Typography>
          <Typography variant="body1">
            Propriétaire{' '}
            <b>
              {group.admin.owner.firstName} {group.admin.owner.lastName}
            </b>
          </Typography>
          {group.admin.owner.email && (
            <Typography variant="body1">{group.admin.owner.email}</Typography>
          )}
          {group.admin.owner.phoneNumber && (
            <Typography variant="body1">
              {group.admin.owner.phoneNumber}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="column" spacing={2}>
        {group.status === 'ACTIVE' && (
          <Stack direction="column" spacing={1}>
            <Typography variant="body1">
              Ce groupe a été activé par {group.activatedBy?.firstName || 'défaut'}{' '}
              {group.activatedBy?.lastName || ''}
            </Typography>
          </Stack>
        )}
        {group.status === 'PENDING' && (
          <Stack direction="row" spacing={1} sx={{'& > *': {flex: 1}}}>
            <Dialogable
              source={({onOpen}) => (
                <Button variant="contained" color="success" onClick={onOpen}>
                  Activer
                </Button>
              )}
              dialog={dialogProps => (
                <ActivateGroup group={group} {...dialogProps} />
              )}
            />
            <Dialogable
              source={({onOpen}) => (
                <Button variant="contained" onClick={onOpen}>
                  Refuser
                </Button>
              )}
              dialog={dialogProps => (
                <RefuseGroup group={group} {...dialogProps} />
              )}
            />
          </Stack>
        )}
        {group.status === 'REFUSED' && (
          <Stack direction="column" spacing={1}>
            <Typography variant="body1">Ce groupe a été refusé</Typography>
          </Stack>
        )}
        <Dialogable
          source={({onOpen}) => (
            <Button variant="contained" onClick={onOpen}>
              Supprimer
            </Button>
          )}
          dialog={dialogProps => <DeleteGroup group={group} {...dialogProps} />}
        />
      </Stack>
    </Stack>
  );
};

export default GroupDetails;
