import FormikAutoComplete from '../atoms/formik/FormikAutoComplete';

import {Box, TextField} from '@mui/material';

import useUsersPickerOptions from '../../lib/hooks/useUsersPickerOptions';

import LoadingOverlay from './LoadingOverlay';

const PeoplePicker = ({
  name,
  label,
  data,
  loading,
}: {
  data?: {
    _id: string;
    firstName: string;
    lastName: string;
  }[];
  loading: boolean;
  name: string;
  label: string;
}) => {
  const pickerOptions = useUsersPickerOptions(data);

  return (
    <Box position="relative">
      <FormikAutoComplete
        name={name}
        disablePortal
        options={pickerOptions}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          );
        }}
        renderInput={params => (
          <TextField {...params} label={label} placeholder={label} />
        )}
      />
      {loading && <LoadingOverlay size={24} />}
    </Box>
  );
};

export default PeoplePicker;
