import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {Stack, Typography} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikPhoneNumberInput from '../../components/atoms/formik/FormikPhoneNumberInput';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import PageAction from '../../components/organisms/PageAction';
import {useGetBusinessContactQuery, useUpdateBusinessContactMutation,} from '../../redux/businessesApi';
import {useLoadingEffect} from '../../redux/loaderSlice';

const validationSchema = yup.object({
  email: yup.string().required('Champs requis').email('Email invalide'),
  phoneNumber: yup.string().required('Champ obligatoire'),
  ext: yup.string().required('Champ obligatoire'),
  country: yup.string().required('Champ obligatoire'),
});

const UpdateBusinessContact = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [updateBusiness, mutation] = useUpdateBusinessContactMutation();
  const [loading, setLoading] = React.useState(false);

  const businessContactQuery = useGetBusinessContactQuery(id as string);

  useQueryInternalError(mutation);
  useQueryInternalError(businessContactQuery);
  useLoadingEffect(businessContactQuery.isLoading);
  useLoadingEffect(mutation.isLoading);

  const handleUpdateBusinessContact = async ({
    email,
    ext,
    country,
    phoneNumber,
  }: any) => {
    try {
      setLoading(true);
      await updateBusiness({
        id: id as string,
        email,
        ext,
        country,
        phoneNumber,
      });
      NotificationManager.success('Contact mis à jour avec succès');
      navigate(-1);
    } catch (err) {
      internalError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title="Modifier le contact du commerce">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={({email, ext, country, phoneNumber}: any) =>
          handleUpdateBusinessContact({
            email,
            ext,
            country,
            phoneNumber,
          })
        }
        initialValues={{
          email: businessContactQuery.data?.email || '',
          phoneNumber: businessContactQuery.data?.phoneNumber || '',
          ext: businessContactQuery.data?.ext || '',
          country: businessContactQuery.data?.country || 'FR',
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={2}>
            <Typography
              variant="body1"
              sx={{
                color: theme => theme.palette.success.main,
              }}>
              Note: Ces informations sont seulement pour contacter le commerce
            </Typography>
            <FormikTextField name="email" label="Email de contact" />
            <FormikPhoneNumberInput
              name="phoneNumber"
              label="Tel"
              countryName="country"
              extName="ext"
            />
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={
                  loading || businessContactQuery.isLoading
                    ? undefined
                    : () => handleSubmit()
                }
                loading={loading || businessContactQuery.isLoading}
                variant="contained"
                color="primary">
                Terminer
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default UpdateBusinessContact;
