import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {apiBaseQuery, authBaseQuery} from './apiBaseQuery';

export const customBusinessReferralsApi = createApi({
  reducerPath: 'customBusinessReferralsApi',
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      getBusinessReferrals: build.query<
        PaginateResult<BusinessReferral>,
        PaginateParams
      >({
        query: params => ({
          url: '/getBusinessReferrals',
          method: 'get',
          params,
        }),
      }),
    };
  },
});

const businessReferralsApi = createApi({
  reducerPath: 'businessReferralsApi',
  baseQuery: apiBaseQuery,
  endpoints(build) {
    return {
      resendBusinessReferralInvitation: build.mutation<
        Business,
        {
          id: string;
          updatedAt: any;
        }
      >({
        query: ({id, updatedAt}) => ({
          url: `/businessReferral/${id}`,
          method: 'patch',
          data: {updatedAt},
        }),
      }),
      deleteBusinessReferralInvitation: build.mutation<
        Business,
        {
          id: string;
        }
      >({
        query: ({id}) => ({
          url: `/businessReferral/${id}`,
          method: 'delete',
        }),
      }),
    };
  },
});

export const {useGetBusinessReferralsQuery} = customBusinessReferralsApi;
export const {
  useResendBusinessReferralInvitationMutation,
  useDeleteBusinessReferralInvitationMutation,
} = businessReferralsApi;

export default businessReferralsApi;
