import * as yup from 'yup';

yup.addMethod(
  yup.string,
  'requiredIf',
  function (condition: (form: any) => boolean, message: string) {
    return this.test({
      name: 'requiredIf',
      message: '${path} is required',
      test: function (value: any) {
        const {path, createError, parent} = this;
        const form = parent;

        if (condition(form)) {
          return !!value ? true : createError({path, message});
        } else {
          return true;
        }
      },
    });
  },
);

declare module 'yup' {
  interface StringSchema {
    requiredIf(
      condition: (form: any) => boolean,
      message: string,
    ): StringSchema;
  }
}
