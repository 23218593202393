import {createTheme} from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'sans-serif'].join(','),
    htmlFontSize: 18,
    fontSize: 18,
    button: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
    },
    body1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      fontSize: '0.825rem',
    },
    h6: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
    },
    h5: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    h4: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '2rem',
    },
    h1: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: '2.25rem',
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'elevation',
        elevation: 1,
      },
    },
    MuiToolbar: {
      defaultProps: {
        sx: {
          px: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '0.825rem',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5',
          borderRadius: 4,
          '&.MuiInputBase-adornedStart svg, &.MuiInputBase-adornedEnd svg': {
            marginRight: '8px',
            marginLeft: '8px',
          },
        },
      },
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'capitalize',
          '&.MuiButton-sizeLarge': {
            fontSize: '1rem',
          },
        },
      },
    },
  },
});
