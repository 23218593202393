import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Stack} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';
import SaveButton from '../../components/atoms/SaveButton';
import PageAction from '../../components/organisms/PageAction';

import {useUpdateSuperuserPasswordMutation} from '../../redux/superusersApi';

const validationSchema = yup.object({
  password: yup.string().required('Champs requis'),
});

const UpdateSuperUserPassword = () => {
  const {id} = useParams<{
    id: string;
  }>();
  const [updateSuperuserPassword, mutation] =
    useUpdateSuperuserPasswordMutation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/superusers');
    }
  }, [mutation.isSuccess, navigate]);

  useQueryInternalError(mutation);

  return (
    <PageAction title="Modification de superutilisateur">
      <Formik<{
        password: string;
      }>
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          password: '',
        }}
        onSubmit={form =>
          updateSuperuserPassword({
            id: id as string,
            ...form,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <FormikTextField name="password" label="Mot de passe" />
            <SaveButton
              loading={mutation.isLoading}
              onClick={() => handleSubmit()}>
              Sauvegarder
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default UpdateSuperUserPassword;
