import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import {useRemoveBusinessAsSiteTemplateMutation} from '../../redux/businessesApi';

const RemoveAsTemplateModal = ({
  business,
  open,
  onClose,
}: {
  business: PopulatedBusiness;
  open: boolean;
  onClose: () => void;
}) => {
  const [removeAsTemplate, mutation] =
    useRemoveBusinessAsSiteTemplateMutation();

  useQueryInternalError(mutation);

  useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success(`Le site a été supprimé des modèles`);
      onClose();
    }
  }, [mutation.isSuccess, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        Voulew vous vraiement supprimer le site {business.name} en
        tant que modèle?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <LoadingButton
          color="error"
          loading={mutation.isLoading}
          variant="contained"
          onClick={() => removeAsTemplate(business._id)}>
          Supprimer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveAsTemplateModal;
