import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link as RouterLink} from 'react-router-dom';

import {Add} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import chunk from 'lodash/chunk';

import RoundIconButton from '../../components/atoms/RoundIconButton';

import {useLoadingEffect} from '../../redux/loaderSlice';

const ActivityIndicator = () => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="center"
    py={2}
    width="100%">
    <CircularProgress size={24} />
  </Box>
);

const EndMessage = ({visible}: {visible: boolean}) =>
  visible ? (
    <Stack direction="row" py={2} alignItems="center" spacing={4}>
      <Divider sx={{flexGrow: 1}} />
      <Typography variant="body1">Fin</Typography>
      <Divider sx={{flexGrow: 1}} />
    </Stack>
  ) : null;

const DataList = React.forwardRef(
  <T,>(
    {
      useQuery,
      columns = 1,
      renderItem,
      emptyList,
      header,
      addButtonVisible,
      addLink,
      extraQueryParams = {},
      itemsPerPage = 10,
      paginationParams = {
        limit: '$limit',
        skip: '$skip',
      },
    }: {
      useQuery: any;
      header?: JSX.Element;
      emptyList?: JSX.Element;
      renderItem: any;
      addLink?: string;
      addButtonVisible?: boolean;
      extraQueryParams?: any;
      columns?: number;
      itemsPerPage?: number;
      paginationParams?: {
        limit: string;
        skip: string;
      };
    },
    ref: any,
  ) => {
    const [page, setPage] = React.useState(1);
    const query = useQuery({
      [paginationParams['limit']]: page * itemsPerPage,
      [paginationParams['skip']]: 0,
      ...extraQueryParams,
    });
    const theme = useTheme();

    useLoadingEffect(query.isLoading);
    useQueryInternalError(query);

    const hasMore =
      query.isSuccess && query.data?.total > query.data?.data.length;

    return (
      <Stack ref={ref} position="relative">
        <Box mb={2}>{header}</Box>
        {query.isLoading && <CircularProgress sx={{alignSelf: 'center'}} />}
        {query.isSuccess && query.data?.data.length === 0 && (
          <Box position="absolute" sx={{pointerEvents: 'none', inset: 0}}>
            {emptyList}
          </Box>
        )}
        <InfiniteScroll
          dataLength={query.data?.data.length || 0} //This is important field to render the next data
          next={() => setPage(old => old + 1)}
          hasMore={hasMore}
          loader={<ActivityIndicator />}
          endMessage={
            <EndMessage
              visible={query.isSuccess && !hasMore && query.data?.total > 0}
            />
          }
          // below props only if you need pull down functionality
          refreshFunction={() => query.refetch()}
          pullDownToRefresh
          pullDownToRefreshContent={<ActivityIndicator />}
          releaseToRefreshContent={<ActivityIndicator />}
          pullDownToRefreshThreshold={50}>
          <Stack spacing={1} direction="column">
            {chunk(query.data?.data, columns).map(chunk => (
              <Stack
                key={chunk.map((item: any) => item._id).join('-')}
                direction="row"
                spacing={1}
                alignSelf="stretch"
                sx={{
                  '& > *': {
                    flex: columns === 1 || chunk.length > 1 ? 1 : 0.5,
                  },
                }}>
                {chunk.map((item: any) => (
                  <React.Fragment key={item._id}>
                    {renderItem(item)}
                  </React.Fragment>
                ))}
              </Stack>
            ))}
          </Stack>
        </InfiniteScroll>
        {addButtonVisible && addLink && (
          <Link component={RouterLink} to={addLink}>
            <RoundIconButton
              sx={{position: 'fixed', bottom: 70, right: theme.spacing(1)}}>
              <Add />
            </RoundIconButton>
          </Link>
        )}
      </Stack>
    );
  },
);

export default DataList;
