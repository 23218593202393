import FormikSelect from '../atoms/formik/FormikSelect';

import React from 'react';

import {MenuItem} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {useGetAllBusinessCategoriesQuery} from '../../redux/businessesApi';

const FormikBusinessCategoryPicker = ({
  name,
  label = "Domaine d'activité",
}: {
  name: string;
  label?: string;
}) => {
  const businessCategoriesQuery = useGetAllBusinessCategoriesQuery();

  const pickerOptions = React.useMemo(() => {
    if (businessCategoriesQuery.data) {
      return businessCategoriesQuery.data.map(o => ({
        value: o._id,
        label: o.name,
      }));
    }

    return [];
  }, [businessCategoriesQuery.data]);

  useQueryInternalError(businessCategoriesQuery);

  if (businessCategoriesQuery.isSuccess) {
    return (
      <FormikSelect label={label} name={name}>
        {pickerOptions.map((option: any) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </FormikSelect>
    );
  }

  return null;
};

export default FormikBusinessCategoryPicker;
