import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate, useParams} from 'react-router-dom';

import {LoadingButton} from '@mui/lab';
import {Stack} from '@mui/material';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';
import PageAction from '../../components/organisms/PageAction';

import {
  useCreateBusinessCategoryMutation,
  useGetBusinessCategoryByIdQuery,
  useUpdateBusinessCategoryMutation,
} from '../../redux/businessCategoriesApi';
import useQueryInternalError from '../../lib/hooks/useQueryInternalError';
import LoadingOverlay from "../../components/molecules/LoadingOverlay";

const validationSchema = yup.object({
  nameIntl: yup.object().intlRequired('Champs requis'),
  b2bPriceIdentifier: yup.string(),
  b2bTrialDays: yup.number(),
});

const CategoryForm = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  const [addBusinessCategory, addBusinessCategoryMutation] =
    useCreateBusinessCategoryMutation();
  const [updateBusinessCategory, updateBusinessCategoryMutation] =
    useUpdateBusinessCategoryMutation();
  const {data: businessCategory, ...query} = useGetBusinessCategoryByIdQuery(
    id as string,
    {
      skip: !id,
    },
  );
  const isLoading =
    updateBusinessCategoryMutation.isLoading ||
    addBusinessCategoryMutation.isLoading ||
    query.isLoading;

  React.useEffect(() => {
    if (addBusinessCategoryMutation.isSuccess) {
      NotificationManager.success('La catégories créé avec succès');
      navigate(-1);
    }
  }, [addBusinessCategoryMutation.isSuccess, navigate]);

  React.useEffect(() => {
    if (updateBusinessCategoryMutation.isSuccess) {
      NotificationManager.success('La catégorie a été modifiée avec succès');
      navigate(-1);
    }
  }, [updateBusinessCategoryMutation.isSuccess, navigate]);

  useQueryInternalError(updateBusinessCategoryMutation);
  useQueryInternalError(addBusinessCategoryMutation);
  useQueryInternalError(query);

  if (query.isLoading) {
    return <LoadingOverlay />
  }

  return (
    <PageAction
      title={
        id
          ? `Modifier la catégorie : ${businessCategory?.name || ''}`
          : 'Créer une nouvelle catégorie'
      }>
      <Formik
        validationSchema={validationSchema}
        onSubmit={({nameIntl, b2bPriceIdentifier, b2bTrialDays}: any) =>
          id
            ? updateBusinessCategory({
                id,
                nameIntl,
                b2bPriceIdentifier,
                b2bTrialDays
              })
            : addBusinessCategory({ nameIntl, b2bPriceIdentifier, b2bTrialDays })
        }
        enableReinitialize
        initialValues={{
          nameIntl: businessCategory?.nameIntl || {},
          b2bPriceIdentifier: businessCategory?.b2bPriceIdentifier || '',
          b2bTrialDays: businessCategory?.b2bTrialDays || '',
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={2}>
            <FormikTextField name="name" label="Nom du catégorie" enableIntl />
            <FormikTextField name="b2bPriceIdentifier" label="Identifiant du tarif B2B" />
            <FormikTextField name="b2bTrialDays" label="Nombre de jours gratuit" type="number" />
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={
                  addBusinessCategoryMutation.isLoading ||
                  updateBusinessCategoryMutation.isLoading
                    ? undefined
                    : () => handleSubmit()
                }
                loading={isLoading}
                variant="contained"
                color="primary">
                Sauvegarder
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CategoryForm;
