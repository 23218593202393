import React from 'react';
import {NotificationManager} from 'react-notifications';

import LoadingButton from '@mui/lab/LoadingButton';
import {Stack} from '@mui/material';

import useQueryInternalError from '../../lib/hooks/useQueryInternalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikTextField from '../../components/molecules/formik/FormikTextField';
import PageAction from '../../components/organisms/PageAction';

import {useRunScriptMutation} from '../../redux/runScriptApi';

const validationSchema = yup.object({
  name: yup.string().required('Champ obligatoire'),
});

const RunScript = () => {
  const [runScript, mutation] = useRunScriptMutation();

  useQueryInternalError(mutation);

  React.useEffect(() => {
    if (mutation.isSuccess) {
      NotificationManager.success('script exécuté avec succès');
    }
  });

  return (
    <PageAction title="Exécuter des scripts">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
        }}
        onSubmit={({name}) => runScript(name)}>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <FormikTextField name="name" label="Nom" />
            <LoadingButton
              loading={mutation.isLoading}
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}>
              Exécuter
            </LoadingButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default RunScript;
