import {internalError} from '../helpers/internalError';

import React from 'react';

const useQueryInternalError = (query: any) => {
  return React.useEffect(() => {
    if (query.isError) {
      internalError(query.error);
    }
  }, [query.error, query.isError]);
};

export default useQueryInternalError;
