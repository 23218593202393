import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import {MoreVert} from '@mui/icons-material';
import {
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {useDeleteBusinessCategoryMutation} from '../../redux/businessCategoriesApi';

const CategoryCard: React.FC<{
  category: BusinessCategory;
}> = ({category}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [deleteBusinessCategory, deleteBusinessCategoryMutation] =
    useDeleteBusinessCategoryMutation();

  React.useEffect(() => {
    if (deleteBusinessCategoryMutation.isError) {
      internalError(deleteBusinessCategoryMutation.error);
    }
    if (deleteBusinessCategoryMutation.isSuccess) {
      NotificationManager.success("La catégorie a été supprimée avec succès");
      setAnchorEl(null);
    }
  }, [
    deleteBusinessCategoryMutation.error,
    deleteBusinessCategoryMutation.isError,
    deleteBusinessCategoryMutation.isSuccess,
  ]);

  const onRemoveCategory = React.useCallback(
    async (id: string) => {
      if (
        window.confirm('Êtes-vous sûr vouloir supprimer cette invitation?') ===
        true
      ) {
        await deleteBusinessCategory({id});
      }
    },
    [deleteBusinessCategory],
  );

  const openActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  return (
    <Paper square sx={{py: 2, px: 2, position: 'relative'}}>
      {deleteBusinessCategoryMutation.isLoading && <LinearProgress />}
      <IconButton
        sx={{position: 'absolute', top: 10, right: 4, zIndex: 1}}
        onClick={openActions}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <MoreVert color="action" fontSize="medium" />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeActions}>
        <MenuItem
          onClick={() =>
            navigate(`/actions/business-categories/edit/${category._id}`)
          }>
          Modifier
        </MenuItem>
        <MenuItem onClick={() => onRemoveCategory(category._id)}>
          Supprimer
        </MenuItem>
      </Menu>
      <Stack spacing={2} direction="column" alignItems="flex-start" pb={2}>
        <Typography variant="h5">{category.nameIntl['FR']}</Typography>
      </Stack>
    </Paper>
  );
};

export default CategoryCard;
