import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Stack} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import {Formik} from 'formik';
import * as yup from 'yup';

import SaveButton from '../../components/atoms/SaveButton';
import ManagerPicker from '../../components/molecules/ManagerPicker';
import PageAction from '../../components/organisms/PageAction';

import {
  useGetSuperuserByIdQuery,
  useUpdateSuperuserParentMutation,
} from '../../redux/superusersApi';

const validationSchema = yup.object({
  parent: yup.string().required('Champs requis'),
});

const AssignToManager = () => {
  const params = useParams<{id: string}>();
  const query = useGetSuperuserByIdQuery(params.id as string);
  const [updateSuperUserParent, mutation] = useUpdateSuperuserParentMutation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/superusers');
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }
  }, [mutation.isSuccess, navigate]);

  return (
    <PageAction title="Affectation à un responsable de pôle">
      <Formik<{
        parent: string;
      }>
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          parent: (query.data?.parent as string) || '',
        }}
        onSubmit={form =>
          updateSuperUserParent({
            ...form,
            id: params.id as string,
          })
        }>
        {({handleSubmit}) => (
          <Stack spacing={2}>
            <ManagerPicker
              name="parent"
              label="Choisissez le responsable de pôle"
            />
            <SaveButton
              loading={mutation.isLoading}
              disabled={query.isLoading}
              onClick={() => handleSubmit()}>
              Sauvegarder
            </SaveButton>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default AssignToManager;
