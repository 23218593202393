const providesTagsFromListResult =
  <T>(type: T) =>
  (result: any) =>
    result
      ? [
          ...result.data.map(({_id}: any) => ({
            type,
            id: _id,
          })),
        ]
      : [{type, id: 'LIST'}];

export default providesTagsFromListResult;
