import {createApi} from '@reduxjs/toolkit/dist/query/react';

import invalidateTagsUsingParamId from '../lib/app/invalidateTagsUsingParamId';
import providesTagsFromListResult from '../lib/app/providesTagsFromListResult';
import providesTagsFromOneResult from '../lib/app/providesTagsFromOneResult';

import {authBaseQuery} from './apiBaseQuery';
import {customBusinessApi} from './businessesApi';

export type SuperUserForm = Pick<
  SuperUser,
  'firstName' | 'lastName' | 'email' | 'role' | 'parent'
> & {password?: string};

export const superuserApi = createApi({
  reducerPath: 'superuserApi',
  baseQuery: authBaseQuery,
  tagTypes: ['SuperUser', 'SuperUserStats'],
  endpoints(build) {
    return {
      deleteSuperuser: build.mutation({
        query: args => ({
          url: `/deleteSuperuser/${args.id}`,
          method: 'delete',
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : ['SuperUser'],
        async onQueryStarted(args, api) {
          try {
            await api.queryFulfilled;
            api.dispatch({
              type: 'customBusinessApi/invalidateTags',
              payload: [{id: 'LIST', type: 'Business'}],
            });
            api.dispatch({
              type: 'businessApi/invalidateTags',
              payload: ['Business'],
            });
            api.dispatch(customBusinessApi.util.invalidateTags(['Business']));
          } catch (err) {
            console.error(err);
          }
        },
      }),
      deactivateSuperuser: build.mutation({
        query: args => ({
          url: `/deactivateSuperuser/${args.id}`,
          method: 'patch',
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      enableSuperuser: build.mutation({
        query: args => ({
          url: `/enableSuperuser/${args.id}`,
          method: 'patch',
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      updateSuperuser: build.mutation<SuperUser, SuperUserForm & {id: string}>({
        query: data => ({
          url: `/updateSuperuserProfile/${data.id}`,
          method: 'put',
          data,
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : [{id: 'LIST', type: 'SuperUser'}],
      }),
      updateSuperuserPassword: build.mutation<
        SuperUser,
        {id: string; password: string}
      >({
        query: data => ({
          url: `/updateSuperuserPassword/${data.id}`,
          method: 'patch',
          data,
        }),
      }),
      updateSuperuserParent: build.mutation<
        SuperUser,
        Pick<SuperUser, 'parent'> & {id: string}
      >({
        query: data => ({
          url: `/updateSuperuserParent/${data.id}`,
          method: 'patch',
          data: {
            parent: data.parent,
          },
        }),
        invalidatesTags: result =>
          result
            ? [
                {id: result._id, type: 'SuperUser'},
                {id: 'LIST', type: 'SuperUser'},
              ]
            : ['SuperUser'],
      }),
      createSuperuser: build.mutation<SuperUser, SuperUserForm>({
        query: data => ({
          url: `/createSuperuser`,
          method: 'post',
          data,
        }),
        invalidatesTags: ['SuperUser'],
      }),
      getSuperusers: build.query<
        PaginateResult<SuperUser>,
        PaginateParams & {search?: string; role?: string}
      >({
        query: params => ({
          url: '/getSuperusers',
          method: 'get',
          params: {
            search: params.search,
            role: params.role,
            ...params,
          },
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      getSuperuserById: build.query<SuperUser, string>({
        query: id => ({
          url: `/getSuperuserById/${id}`,
          method: 'get',
        }),
        providesTags: result =>
          result
            ? [
                {
                  type: 'SuperUser' as const,
                  id: result._id,
                },
                {type: 'SuperUser', id: 'LIST'},
              ]
            : ['SuperUser'],
      }),
      getSuperuserDashboard: build.query<
        BusinessStats,
        {
          filterType: string;
          id: string;
          date1?: Date;
          date2?: Date;
        }
      >({
        query: params => ({
          url: `/getSuperuserStats/${params.id}`,
          method: 'get',
          params: {
            filterType: params.filterType,
            date1: params.date1 ? params.date1 : undefined,
            date2: params.date2 ? params.date2 : undefined,
          },
        }),
        providesTags: providesTagsFromOneResult('SuperUserStats'),
      }),
      getSalesUsers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getSalesUsers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      getHeadManagers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getHeadManagers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      getManagers: build.query<PaginateResult<SuperUser>, PaginateParams>({
        query: params => ({
          url: '/getManagers',
          method: 'get',
          params,
        }),
        providesTags: providesTagsFromListResult('SuperUser'),
      }),
      canViewSuperuserParentObjects: build.query<boolean, string>({
        query: id => ({
          url: `/canViewSuperuserParentObjects/${id}`,
          method: 'get',
        }),
      }),
      regenerateReferralCode: build.mutation<string, string>({
        query: id => ({
          url: `/regenerateReferralCode/${id}`,
          method: 'patch',
        }),
        invalidatesTags: invalidateTagsUsingParamId('SuperUser'),
      }),
      exportContacts: build.mutation({
        query: ({filterType, date1, date2}) => ({
          url: '/exportContacts',
          method: 'post',
          data: {
            filterType,
            date1: date1 ? date1.getTime() : undefined,
            date2: date2 ? date2.getTime() : undefined,
          },
        }),
      }),
    };
  },
});

export const {
  useDeactivateSuperuserMutation,
  useDeleteSuperuserMutation,
  useEnableSuperuserMutation,
  useCreateSuperuserMutation,
  useGetSuperusersQuery,
  useGetSalesUsersQuery,
  useGetHeadManagersQuery,
  useGetManagersQuery,
  useUpdateSuperuserParentMutation,
  useGetSuperuserByIdQuery,
  useGetSuperuserDashboardQuery,
  useUpdateSuperuserMutation,
  useUpdateSuperuserPasswordMutation,
  useCanViewSuperuserParentObjectsQuery,
  useRegenerateReferralCodeMutation,
  useExportContactsMutation,
} = superuserApi;

export default superuserApi;
