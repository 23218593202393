import React from 'react';

import {TextField} from '@mui/material';
import {DateTimePicker, DateTimePickerProps} from '@mui/x-date-pickers';

import {Field, FieldProps} from 'formik';

const FormikDateTimePicker: React.FC<
  {
    name: string;
  } & Partial<Omit<DateTimePickerProps<any, any>, 'value' | 'renderInput'>>
> = ({name, ...props}) => {
  return (
    <Field name={name}>
      {({field, form}: FieldProps) => (
        <DateTimePicker
          {...props}
          value={field.value}
          onChange={(newValue: any) => {
            if (typeof props.onChange === 'function') {
              props.onChange(newValue);
            }
            form.setFieldValue(name, newValue);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={!!form.errors[name]}
              helperText={
                form.touched[name] && form.errors[name]
                  ? (form.errors[name] as string)
                  : undefined
              }
            />
          )}
        />
      )}
    </Field>
  );
};

export default FormikDateTimePicker;
