import React from 'react';
import {NotificationManager} from 'react-notifications';

import {MoreVert} from '@mui/icons-material';
import {
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import {internalError} from '../../lib/helpers/internalError';

import format from 'date-fns/format';

import {
  useDeleteBusinessReferralInvitationMutation,
  useResendBusinessReferralInvitationMutation,
} from '../../redux/businessReferralsApi';

const BusinessReferralCard = ({
  businessReferral,
}: {
  businessReferral: BusinessReferral;
}) => {
  const referringBusiness = businessReferral.referringBusiness as Business;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [resendInvitation, mutation] =
    useResendBusinessReferralInvitationMutation();
  const [deleteInvitation, deleteMutation] =
    useDeleteBusinessReferralInvitationMutation();

  React.useEffect(() => {
    if (mutation.isError) {
      internalError(mutation.error);
    }
    if (mutation.isSuccess) {
      NotificationManager.success("L'invitation a été renvoyée avec succès");
      setAnchorEl(null);
    }
  }, [mutation.error, mutation.isError, mutation.isSuccess]);

  React.useEffect(() => {
    if (deleteMutation.isError) {
      internalError(deleteMutation.error);
    }
    if (deleteMutation.isSuccess) {
      NotificationManager.success("L'invitation a été supprimée avec succès");
      setAnchorEl(null);
    }
  }, [deleteMutation.error, deleteMutation.isError, deleteMutation.isSuccess]);

  const onResendInvitation = React.useCallback(
    async (id: string) => {
      await resendInvitation({id, updatedAt: new Date()});
      setAnchorEl(null);
    },
    [resendInvitation],
  );

  const onRemoveInvitation = React.useCallback(
    async (id: string) => {
      if (
        window.confirm('Êtes-vous sûr vouloir supprimer cette invitation?') ===
        true
      ) {
        await deleteInvitation({id});
        window.location.reload();
      }
    },
    [deleteInvitation],
  );

  const openActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  return (
    <Paper sx={{position: 'relative'}}>
      {(mutation.isLoading || deleteMutation.isLoading) && <LinearProgress />}
      <IconButton
        sx={{position: 'absolute', top: 14, right: 10, zIndex: 2000}}
        onClick={openActions}
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <MoreVert color="action" fontSize="medium" />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeActions}>
        <MenuItem onClick={() => onResendInvitation(businessReferral._id)}>
          Renvoyer l'invitation
        </MenuItem>
        <MenuItem onClick={() => onRemoveInvitation(businessReferral._id)}>
          Supprimer
        </MenuItem>
      </Menu>

      <Stack py={4} px={2} spacing={1}>
        <Typography variant="h5">
          <b>Commerce invité: {businessReferral.name}</b>
        </Typography>
        <Stack px={2} spacing={1}>
          <Typography variant="body1">
            Prénom: {businessReferral.firstName}
          </Typography>
          <Typography variant="body1">
            Nom: {businessReferral.lastName}
          </Typography>
          <Typography variant="body1">
            Tel: {businessReferral.phoneNumber}
          </Typography>
          <Typography variant="body1">
            Mail: {businessReferral.email}
          </Typography>
          <Typography variant="body1">
            adresse: {businessReferral.address}
          </Typography>
          <Typography variant="body1">
            categorie: {(businessReferral.category as BusinessCategory)?.name}
          </Typography>
          <Typography variant="body1">
            Date:{' '}
            {format(
              new Date(businessReferral.createdAt),
              'EEE dd MMMM yyyy, HH:mm',
            )}
          </Typography>
        </Stack>

        <Typography variant="h5">
          <b>Invité par: {referringBusiness.name}</b>
        </Typography>
        <Stack px={2} spacing={1}>
          <Typography variant="body1">
            Prénom: {(referringBusiness.owner as User).firstName}
          </Typography>
          <Typography variant="body1">
            Nom: {(referringBusiness.owner as User).lastName}
          </Typography>
          <Typography variant="body1">
            Tel: {(referringBusiness.owner as User).phoneNumber}
          </Typography>
          <Typography variant="body1">
            Mail: {(referringBusiness.owner as User).email}
          </Typography>
          <Typography variant="body1">
            adresse: {referringBusiness.address}
          </Typography>
          <Typography variant="body1">
            categorie: {(referringBusiness.category as BusinessCategory)?.name}
          </Typography>
          <Typography variant="body1">
            Invité le{': '}
            {format(
              new Date(referringBusiness.createdAt),
              'EEE dd MMMM yyyy, HH:mm',
            )}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BusinessReferralCard;
