import {createApi} from '@reduxjs/toolkit/dist/query/react';

import providesTagsFromArrayResult from '../lib/app/providesTagsFromArrayResult';

import {authBaseQuery} from './apiBaseQuery';

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: authBaseQuery,
  tagTypes: ['User'],
  endpoints(build) {
    return {
      getUsersOwnersAccounts: build.query<User[], void>({
        query: () => ({
          url: '/getUsersOwnersAccounts',
          method: 'get',
        }),
        providesTags: providesTagsFromArrayResult('User'),
      }),
    };
  },
});

export const {useGetUsersOwnersAccountsQuery} = usersApi;

export default usersApi;
