import React from 'react';
import {NotificationManager} from 'react-notifications';
import {useNavigate} from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Divider,
  FormControlLabel,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';

import FormikGeoLocationPicker from '../../lib/googlemaps/components/GeoLocationPicker/FormikGeoLocationPicker';
import {internalError} from '../../lib/helpers/internalError';
import useUploadFile from '../../lib/hooks/useUploadFile';

import {Formik} from 'formik';
import * as yup from 'yup';

import FormikFileInput from '../../components/atoms/formik/FormikFileInput';
import FormikPhoneNumberInput from '../../components/atoms/formik/FormikPhoneNumberInput';
import FormikSingleChoice from '../../components/atoms/formik/FormikSingleChoice';
import FormikSlider from '../../components/atoms/formik/FormikSlider';
import FormikTextField from '../../components/molecules/formik/FormikTextField';
import FormikBusinessCategoryPicker from '../../components/molecules/FormikBusinessCategoryPicker';
import SalesPicker from '../../components/molecules/SalesPicker';
import FormikUserInfo, {
  getUserInfoInitialValues,
} from '../../components/organisms/formik/FormikUserInfo';
import PageAction from '../../components/organisms/PageAction';

import {useGetAuthUserQuery} from '../../redux/authApi';
import {useCreateBusinessB2BWebMutation} from '../../redux/businessesApi';

const objectSchema = {
  image: yup.string().required('Champs requis'),
  name: yup.string().required('Champs requis'),
  firstName: yup.string().requiredIf(form => !form.owner, 'Champs requis'),
  lastName: yup.string().requiredIf(form => !form.owner, 'Champs requis'),
  phoneNumber: yup.string().requiredIf(form => !form.owner, 'Champs requis'),
  owner: yup
    .string()
    .requiredIf(
      form => !form.firstName && !form.lastName && !form.phoneNumber,
      'Champs requis',
    ),
  category: yup.string().required('Champs requis'),
  address: yup.string().required('Champs requis'),
  location: yup
    .object()
    .locationRequired('Entrez ou réssayer une autre adresse'),
  radius: yup.number().radiusValid(),
};

const validationSchema = yup.object(objectSchema);

const rootValidationSchema = yup.object({
  ...objectSchema,
  assignee: yup.string().required('Champs requis'),
});

const CreateBusinessB2BWeb = () => {
  const navigate = useNavigate();
  const uploadFile = useUploadFile();
  const [createBusiness, mutation] = useCreateBusinessB2BWebMutation();
  const query = useGetAuthUserQuery();
  const user = query.data;
  const [loading, setLoading] = React.useState(false);

  const isRootOrHeadManager =
    user?.role === 'ROOT' || user?.role === 'HEAD_MANAGER';

  const handleCreateBusiness = async ({
    email,
    image,
    name,
    contactExt,
    contactCountry,
    contactPhoneNumber,
    phoneNumber,
    assignee,
    descriptionIntl,
    address,
    location,
    category,
    radius,
    displayB2B,
    displayB2C,
    withSections,
    owner,
    firstName,
    lastName,
    billableAccount,
  }: any) => {
    try {
      setLoading(true);
      const coverImage = await uploadFile(image);
      await createBusiness({
        email,
        phoneNumber,
        image: coverImage._id,
        name,
        category,
        address,
        assignee,
        descriptionIntl,
        location,
        radius,
        displayB2B,
        displayB2C,
        withSections,
        contactExt,
        contactCountry,
        contactPhoneNumber,
        owner,
        firstName,
        lastName,
        billableAccount,
      }).unwrap();
      NotificationManager.success('Commerce créé avec succès');
      navigate(-1);
    } catch (e: any) {
      if (e?.data?.error?.code === 'EMAIL_ALREADY_TAKEN') {
        NotificationManager.error('Email déjà utilisé');
      } else if (e?.data?.error?.code === 'PHONE_NUMBER_ALREADY_TAKEN') {
        NotificationManager.error('Numéro de téléphone déjà utilisé');
      } else {
        internalError(mutation.error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageAction title="Créer un nouveau commerce">
      <Formik
        validationSchema={
          user?.role === 'ROOT' ? rootValidationSchema : validationSchema
        }
        onSubmit={({
          email,
          image,
          ext,
          country,
          phoneNumber,
          contactExt,
          contactCountry,
          contactPhoneNumber,
          owner,
          name,
          assignee,
                     descriptionIntl,
          address,
          location,
          category,
          radius,
          displayB2B,
          displayB2C,
          withSections,
          billableAccount,
        }: any) =>
          handleCreateBusiness({
            email,
            ext,
            country,
            phoneNumber,
            contactExt,
            contactCountry,
            contactPhoneNumber,
            image,
            name,
            category,
            address,
            assignee,
            descriptionIntl,
            location,
            radius,
            displayB2B,
            displayB2C,
            withSections,
            owner,
            billableAccount,
          })
        }
        initialValues={{
          coverImage: '',
          name: '',
          descriptionIntl: {},
          category: '',
          assignee: '',
          address: '',
          location: '',
          radius: 10,
          B2B: false,
          B2C: false,
          withSections: false,
          contactPhoneNumber: '',
          contactExt: '',
          contactCountry: 'FR',
          contactEmail: '',
          billableAccount: true,
          ...getUserInfoInitialValues(),
        }}>
        {({handleSubmit}) => (
          <Stack direction="column" spacing={3}>
            <Stack spacing={2} direction="column">
              <Typography variant="h6">Informations de compte</Typography>
              <FormikUserInfo />
            </Stack>
            <Divider />
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Informations générales</Typography>
              <FormikFileInput name="image" maxSize={5} />
              <FormikTextField name="name" label="Nom du commerce" />
              <FormikTextField
                name="description"
                label="Description"
                enableIntl
                multiline
                rows={4}
              />
              <FormikBusinessCategoryPicker
                label="Domaine d'activité"
                name="category"
              />
              <FormikGeoLocationPicker
                locationName="location"
                addressName="address"
              />
              <FormikSlider
                name="radius"
                label="Diamètre"
                renderValue={(val: string) => `${val} Km`}
                step={1}
                min={0}
                max={100}
              />
            </Stack>
            <Divider />
            <Stack spacing={2} direction="column">
              <Typography variant="h6">Informations supplémentaires</Typography>
              <FormLabel>Tél de contact</FormLabel>
              <FormikPhoneNumberInput
                name="contactPhoneNumber"
                label="Tél de contact d'établissement"
                countryName="contactCountry"
                extName="contactExt"
              />
              <FormikTextField name="contactEmail" label="Email du contact" />
            </Stack>
            <Stack spacing={2} direction="column">
              <Typography variant="h6">Paramètres</Typography>
              {user?.role && user?.role !== 'SALES' && (
                <SalesPicker
                  name="assignee"
                  label={
                    user?.role === 'ROOT'
                      ? 'Agent commercial'
                      : 'Agent commercial (optionnel)'
                  }
                />
              )}
              {isRootOrHeadManager && (
                <FormControlLabel
                  control={<FormikSingleChoice name="billableAccount" />}
                  label="Facturable"
                />
              )}
              <FormControlLabel
                control={<FormikSingleChoice name="displayB2B" />}
                label="Afficher sur B2B"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="displayB2C" />}
                label="Afficher sur B2C"
              />
              <FormControlLabel
                control={<FormikSingleChoice name="withSections" />}
                label="Activer les rubriques"
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <LoadingButton
                onClick={loading ? undefined : () => handleSubmit()}
                loading={loading}
                variant="contained"
                color="primary">
                Terminer
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CreateBusinessB2BWeb;
