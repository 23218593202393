import React from 'react';
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const MenuActionItem = ({onClick, label, icon}: {
  onClick: () => void;
  label: React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText sx={{
        textWrap: 'wrap',
      }}>{label}</ListItemText>
    </ListItemButton>
  );
}

export default MenuActionItem;
